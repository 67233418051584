import { Image, Space, Typography } from 'antd';
import { useMemo } from 'react';
import { COLORS } from '../../../themes/colors';
import SwitchButton from '../../../components/buttons/switch/SwitchButton';
import { ReactComponent as PricingArrow } from '../../../assets/pricing-arrow.svg';
import { useWindowDimensions } from '../../Editor/Editor.page';

export interface ISubscriptionToggleProps {
  activeYearly: boolean;
  setActiveYearly: (value: boolean) => void;
  disabled?: boolean;
}

export const SubscriptionToggle = ({
  setActiveYearly,
  activeYearly,
  disabled = false
}: ISubscriptionToggleProps) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 1120;

  const styles = useMemo(() => {
    return {
      root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
        alignItems: 'center',
        marginTop: isMobile ? 8 : 25,
      },
      container: {
        display: 'flex',
        gap: 15,
        alignItems: 'center'
      },
      button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.GRAY_CONTROLS,
        width: 144,
        height: 40,
        borderRadius: 4,
        cursor: 'pointer',
        position: 'relative'
      },
      activeButton: {
        border: `1px solid ${COLORS.GRAY_DISABLED}`,
        color: COLORS.PRIMARY
      },
      buttonText: {
        fontSize: 18,
        fontWeight: 400,
        userSelect: 'none'
      },
      discount: {
        borderRadius: '4px',
        padding: '3px 13px',
        background: COLORS.YELLOW,
        position: 'absolute',
        bottom: '-26px',
        zIndex: 15,
      },
      discountText: {
        color: 'var(--text-base)',
        fontSize: '12px',
        fontWeight: 600,
      },
      billText: {
        fontSize: 14,
        fontWeight: 400,
        color: 'var(--text-base)',
      },
      saveText: {
        fontSize: 18,
        fontWeight: 700,
        color: 'var(--text-base)',
      },
      arrow: {
        position: 'absolute',
        right: -25,
        top: -12,
      }
    } as const;
  }, [isMobile]);

  return (
    <div style={styles.root}>
      <div style={styles.container}>
        {/* Leave for future usage */}

        {/* <div
          style={
            activeYearly ? styles.button : { ...styles.button, ...styles.activeButton }
          }
          onClick={() => setActiveYearly(false)}>
          <Typography style={styles.buttonText}>Monthly</Typography>
        </div>
        <div
          style={
            !activeYearly ? styles.button : { ...styles.button, ...styles.activeButton }
          }
          onClick={!disabled ? () => setActiveYearly(true) : () => null}>
          <Space style={styles.discount}>
            <Typography style={styles.discountText}>
              {YEARLY_DISCOUNT_VALUE}% off
            </Typography>
          </Space>
          <Typography style={styles.buttonText}>Yearly</Typography>
        </div> */}
        <Typography style={styles.billText}>Monthly</Typography>
        <SwitchButton
          value={activeYearly}
          onChange={(value: boolean) => setActiveYearly(value)}
        />
        <Typography style={styles.billText}>Annually</Typography>
      </div>
      <div style={{ display: 'flex', position: 'relative' }}>
        <Typography style={styles.saveText}>Save up to 57%</Typography>
        <div style={styles.arrow}><PricingArrow style={{ stroke: 'var(--text-base)' }} /></div>
      </div>
    </div>
  );
};

export default SubscriptionToggle;
