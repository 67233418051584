import { Input, message, Image, Typography, Spin, Progress, Skeleton, Row, Col } from 'antd';
import { COLORS } from '../../themes/colors';
import { useState, useMemo, useEffect, useContext, memo, Fragment, useCallback, useRef } from 'react';
import useAxios from 'axios-hooks';
import { IStream, IVideo } from '../../data/intefaces/stream.interface';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as IconTwitch } from '../../assets/icon-twitch-black.svg';
import { ReactComponent as IconUpload } from '../../assets/upload.svg';
import { ReactComponent as IconArrowDown } from '../../assets/arrow_down.svg';
import IconLightning from '../../assets/lightning-icon.svg';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { UserContext } from '../../data/userContext';

import './Videos.less';
import { StreamCard } from '../stream-card';
import { VideoPreview } from '../video-preview';
import { debounce } from 'lodash';
import { UploadFromComputerButton } from '../upload-from-computer-button';
import { ClipCard } from '../clip-card';
import { UploadModal } from '../upload-modal';
import { isYoutubeUrl, isTwitchUrl, isVimeoUrl, isGoogleDriveUrl, checkFileType, removeFileExtension, checkStreamIsAvailableToRender } from '../../utils';
import * as tus from 'tus-js-client';
import { SpikesGuide } from '../spikes-guide';
import { UploadSteps } from '../upload-steps';
import { UploadCard } from '../upload-card';
import { UploadRow } from '../upload-card/UploadRow';
import { useWindowDimensions } from '../../pages/Editor/Editor.page';
import { EStreamStatus } from '../../data/enums/stream-status.enum';
import { postAudit } from '../../services/audit.service';
import GameModal from '../phaser-game/GameComponent';
import Modal from 'react-modal';
import { IEditorTemplate } from '../../data/intefaces/streamer.interface';
import { YoutubeLimitModal } from '../youtube-limit-modal';
import { ErrorInfoModal } from '../error-info-modal';

const SAMPLE_UPLOAD_URL = 'https://drive.google.com/file/d/1j4lQs6jyHGVQACNtrb-jhD2ReYgmp5IQ/view?usp=sharing';


interface IUploadedVideoDetails {
  uploadedVideoUrl?: string | null;
  videoDuration?: number | null;
  videoTitle?: string | null;
  fileType?: 'video' | 'audio';
}

type UploadMode = 'transcribe' | 'highlights';

type Mode = 'personal' | 'youtube' | 'twitch' | 'vimeo' | 'google_drive';

const POLLING_INTERVAL = 10000;
const PAGINATION_INCREMENT = 10;
const DEBOUNCE_DELAY = 300;

function formatUserSelectEndTime(user_select_end_time: number, duration: number) {
  const roundedDuration = Math.floor(duration);

  if (user_select_end_time === roundedDuration) {
    return duration;
  }

  return user_select_end_time;
  /*
   formatUserSelectEndTime(57, 57.143); // Output: 57.143
   formatUserSelectEndTime(56, 57.143); // Output: 57
   formatUserSelectEndTime(57, 58.567); // Output: 57
  */
}

export const Videos = memo(({ tutorialOpen = false, handleCloseTour, containerRef }: { tutorialOpen?: boolean, handleCloseTour?: () => void, containerRef?: any }) => {
  const [pageSize, setPageSize] = useState<number>(PAGINATION_INCREMENT);
  const [{ data, error, loading }, refetch] = useAxios(
    {
      url: '/streamer/uploads',
      method: 'GET',
      params: {
        page_size: pageSize,
        page: 1
      },
    },
    { manual: true }
  );

  const [streams, setStreams] = useState<IStream[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  let debounceTimer: NodeJS.Timeout | null = null;

  const [messageApi, contextHolder] = message.useMessage();
  const [url, setUrl] = useState<string>('');
  const [urlForPreview, setUrlForPreview] = useState<string>('');
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false);
  const [disableMakeClips, setDisableMakeClips] = useState<boolean>(true);
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [uploadedVideoDetails, setUploadedVideoDetails] = useState<IUploadedVideoDetails | null>(null);
  const [youtubeVideoDetails, setYoutubeVideoDetails] = useState<any>(null);
  const [mode, setMode] = useState<Mode | null>(null);
  const [progress, setProgress] = useState<number>(100);
  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [showSpikesGuide, setShowSpikesGuide] = useState(false);
  const [showUploadStepsModal, setShowUploadStepsModal] = useState(false);
  const [selectedUploadCard, setSelectedUploadCard] = useState<null | string>(null);
  const [openedUploadingVideo, setOpenedUploadingVideo] = useState(false);
  const [openMaintenancePopup, setOpenMaintenancePopup] = useState(false);
  const [openYoutubeLimitModal, setOpenYoutubeLimitModal] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(true);
  const [showYTLimitationModal, setShowYTLimitationModal] = useState(false);
  const [showYTLimitationLabel, setShowYTLimitationLabel] = useState(false);
  const isDemoSeen = localStorage.getItem('videoPreviewDemoSeen');
  const isSpikesGuideSeen = localStorage.getItem('spikesGuideSeen');
  const redirectToProjectUrl = localStorage.getItem('redirectToProject');
  const { width } = useWindowDimensions();
  const isMobile = width < 1120;
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const isGoogleUser = !userContext?.user?.twitch_id;
  const usedMinutes = userContext?.user?.used_upload_minutes;
  const totalMinutes = userContext?.user?.total_upload_minutes;

  const transcribeVideoLimit = userContext?.user?.permissions?.video_transcribe_limit ? userContext?.user?.permissions?.video_transcribe_limit : 2;

  const regex = /^(https?:\/\/)?(www\.)?(clips\.)?twitch\.tv\/.*/;

  const debouncedSetUrlForPreview = debounce(setUrlForPreview, 500);

  const inputPlaceholder = 'Insert YouTube / Google Drive / Public URL link';
  // const inputPlaceholder = !isGoogleUser ? 'YouTube / Vimeo / Google Video URL' : 'YouTube Stream URL / Twitch Clip URL';

  useEffect(() => {
    if (redirectToProjectUrl) {
      localStorage.removeItem('redirectToProject');
      navigate(redirectToProjectUrl);
    }
  }, [redirectToProjectUrl])


  useEffect(() => {
    if (error) {
      if (error?.response?.status === 403) {
        localStorage.removeItem('user');
        window.location.href = '/';
      }
    }
  }, [error]);

  // useEffect(() => {
  //   setStreams(data as IStream[]);
  // }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      try {
        const response = await refetch();
        if (response.data) {
          setStreams(response.data as IStream[]);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setIsFetching(false);
      }
    };

    fetchData(); // Fetch on initial load

    const timer = setInterval(fetchData, POLLING_INTERVAL); // Set up polling
    return () => clearInterval(timer); // Cleanup on unmount
  }, [pageSize, refetch]);

  const handleScroll = useCallback(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer); // Clear the previous timer if still active
    }

    debounceTimer = setTimeout(() => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          if (isFetching) return;

          setPageSize((prevSize) => prevSize + PAGINATION_INCREMENT);
        }
      }
    }, DEBOUNCE_DELAY);
  }, [isFetching, refetch]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (!isSpikesGuideSeen) {
      setShowSpikesGuide(true);
    }
  }, [isSpikesGuideSeen]);

  useEffect(() => {
    const homepageVideoUrl = localStorage.getItem('homepage-video-url');

    if (homepageVideoUrl) {
      setUrl(homepageVideoUrl);
    }
    localStorage.removeItem('homepage-video-url');
  }, []);


  // Automatically open card if video was just uploaded
  useEffect(() => {
    if (streams && streams[0]) {
      if (streams[0].clips) {
        if (streams[0].clips.length === 0 && !selectedUploadCard && !openedUploadingVideo) {
          setSelectedUploadCard(streams[0].id);
          setOpenedUploadingVideo(true);
        }
      }
    }
  }, [streams]);

  useEffect(() => {
    const clipId = getLoadingClipId();

    if (clipId && streams) {
      const foundStream = streams.find(stream => stream.clips.find(clip => clip.id === clipId));
      if (foundStream) {
        setSelectedUploadCard(foundStream.id);
      }
    }
  }, []);

  const getLoadingClipId = () => {
    const storedData = localStorage.getItem('loadingClipId');
    return storedData ? JSON.parse(storedData) : null;
  };

  useEffect(() => {
    if (url && !isDemoSeen) {
      setDemoSeen();
    }
    debouncedSetUrlForPreview(url);
    if (!url) {
      setDisableMakeClips(true);
    } else if (isTwitchUrl(url)) {
      setDisableMakeClips(false);
      handleOpenStepsModal();
    } else if (isVimeoUrl(url)) {
      setDisableMakeClips(false);
      handleOpenStepsModal();
    } else if (isGoogleDriveUrl(url)) {
      setDisableMakeClips(false);
      handleOpenStepsModal();
    } else if (isYoutubeUrl(url)) {
      // setOpenMaintenancePopup(true);
      // clearUrlField();
      // return;
      handleOpenStepsModal();
    }
    if (url) {
      setLoadingPreview(true);
      handleShowUploadModal(true);
    }
  }, [url, isDemoSeen, youtubeVideoDetails]);

  const handleOpenStepsModal = () => {
    setShowUploadStepsModal(true);
  };

  const styles = useMemo(() => {
    return {
      resultsContainer: {
        maxWidth: 1384,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: isMobile ? 0 : 50
      },
      main: {
        background: "var(--base)",
        fontSize: 16,
        height: 60,
        padding: '0 15px',
        borderRadius: '8px 0 0 8px',
        width: '100%'
      },
      button: {
        letterSpacing: 'normal',
        left: 5,
        height: 34,
        width: 100,
        fontSize: 14,
        fontFamily: 'BrandonText',
        border: 2,
        boxShadow: 'rgb(0, 239, 248) 0px 0px 10px'
      },
      uploadButton: {
        letterSpacing: 'normal',
        left: 5,
        height: 34,
        width: 125,
        fontSize: 12,
        fontFamily: 'BrandonText',
        boxShadow: 'rgb(0, 239, 248) 0px 0px 10px'
      },
      searchButton: {
        letterSpacing: 'normal',
        left: 15,
        height: 34,
        width: 195,
        fontSize: 12,
        fontFamily: 'BrandonText',
        boxShadow: 'rgb(0, 239, 248) 0px 0px 10px'
      },
      makeClipsButtonWrapper: {
        height: 60,
        background: 'var(--base)',
        display: 'flex',
        alignItems: 'center',
        paddingRight: 7,
        borderRadius: '0 8px 8px 0'
      },
      makeClipsButtonStyle: {
        cursor: 'pointer',
        background: 'var(--primary-gradient)',
        borderRadius: 8,
        minHeight: 45,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px 12px',
        width: 'max-content'
      },
      makeClipsButtonDisabled: {
        backgroundColor: COLORS.DISABLED,
        cursor: 'not-allowed'
      },
      uploadFromComputer: {
        cursor: 'pointer',
        backgroundColor: '#404040',
        borderRadius: 6,
        height: 56,
        width: 280,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 14px',
      },
      makeClipsText: {
        fontSize: '17px',
        fontWeight: 600,
        color: 'var(--text-on-color)',
      },
      uploadFromComputerText: {
        fontSize: '20px',
        fontWeight: 400,
        color: '#D9D9D9',
      },
      horizontalDivider: {
        height: 1.5,
        width: 40,
        backgroundColor: COLORS.GRAY_CONTROLS,
        borderRadius: 2,
        margin: '20px auto',
      },
      secondaryButtonStyle: {
        cursor: 'pointer',
        backgroundColor: '#404040',
        borderRadius: 6,
        height: 56,
        width: 230,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 14px',
      },
      secondaryButtonText: {
        fontSize: '20px',
        fontWeight: 400,
        color: '#D9D9D9',
        marginLeft: '12px',
      },
      progressContainer: {
        width: 200,
        margin: '0 auto',
        marginBottom: 10
      },
      topItemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 562,
        margin: '0 auto',
        gap: 16,
        marginBottom: 16
      }
    } as const;
  }, [isMobile]);

  const setDemoSeen = () => {
    localStorage.setItem('videoPreviewDemoSeen', 'true');
  };

  const handleDeleteClip = async (clipId: string) => {
    const response = await axios.put('/streamer/update-favorite-clip', {
      clip: clipId,
      is_removed_from_favorite: true
    }).catch((error) => {
      if (error.response) {
        console.log(error.response);
      }
    });

    if (response?.status === 200) {
      refetch();
      messageApi.success('Clip removed!', 3);
    }
  };

  const handleClickMakeClipsButton = () => {
    const isValidUrlForPreview =
      isTwitchUrl(urlForPreview) ||
      isGoogleDriveUrl(urlForPreview) ||
      isVimeoUrl(urlForPreview) ||
      isYoutubeUrl(urlForPreview);

    if (!loadingPreview && (isValidUrlForPreview || uploadedVideoDetails)) {
      handleShowUploadModal(true);
      setShowUploadStepsModal(true);
    }
    else {
      messageApi && messageApi.info(`Please insert ${inputPlaceholder}`, 5);
    }
  };

  const handleMakeClips = async (userTemplate?: IEditorTemplate, autoZoomEnabled?: boolean, uploadedVideoUrl?: string | null, videoDuration?: number | null, videoTitle?: string | null, upload_mode?: UploadMode | null, contentMode?: Mode | null, clipLength?: number, stylePreset?: any, layoutType?: 'mobile' | 'desktop', fileType?: 'video' | 'audio', magicLookText?: string, enableFaceTracking?: boolean, language?: string | null, featureSliders?: any, croppedVideoRange?: [number, number]) => {
    if (disableMakeClips) return;
    if (usedMinutes && totalMinutes && (usedMinutes >= totalMinutes)) {
      navigate('/subscriptions', { state: { message: 'Uh-oh! You\'ve hit the maximum upload minutes limit.' } });
    } else {
      videoDuration = videoDuration == null ? 0 : videoDuration;
      videoTitle = videoTitle == null ? '' : videoTitle;
      const blurredBackgroundProp = userTemplate?.subtitles_settings?.blurred_background;
      stylePreset['blurred_background'] = typeof blurredBackgroundProp === 'boolean' ? blurredBackgroundProp : true;
      const subtitlesEnabledProp = userTemplate?.subtitles_settings?.subtitles_enabled;
      const subtitles_enabled = typeof subtitlesEnabledProp === 'boolean' ? subtitlesEnabledProp : true;
      const watermarkSliders = (featureSliders && featureSliders.length > 0) ? JSON.stringify(featureSliders.filter((slider: any) => slider.type === 'watermark')) : null;
      const payload: any = { platform_url: uploadedVideoUrl || url, platform: contentMode, duration: videoDuration, title: videoTitle, upload_mode: upload_mode, clip_length_request: clipLength || -1, style_properties: stylePreset, orientation: layoutType, upload_media_type: fileType, user_prompt: magicLookText || '', enable_face_tracking: Boolean(enableFaceTracking), user_language_choice: language, subtitles_enabled: subtitles_enabled, emojis_enabled: subtitles_enabled }

      if (watermarkSliders) {
        payload.features = watermarkSliders;
      }

      if (enableFaceTracking) {
        payload.is_auto_zoom = autoZoomEnabled;
      }

      if (croppedVideoRange && croppedVideoRange?.length > 0) {
        payload.user_select_start_time = croppedVideoRange[0];
        payload.user_select_end_time = formatUserSelectEndTime(croppedVideoRange[1], videoDuration);
      }

      const response = await axios.post('/streamer/upload', payload)
        .catch((error) => {
          if (error.response) {
            console.log('error', error.response);
            if (error.response?.data?.message && error.response?.data?.message === 'profanity_in_prompt') {
              messageApi.error('Profanity is not allowed in Magic Look', 5);
            } else {
              messageApi.error('Error uploading a video.', 5);
              postAudit({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                user_action: `Video upload failed. URL: ${uploadedVideoUrl}, Platform: ${contentMode}, Error: ${JSON.stringify(error)}`,
                user_id: userContext?.user?.id
              });
            }
          }

          if (contentMode === 'personal') {
            postAudit({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              user_action: `Error uploading a PERSONAL video. POST "/streamer/upload" failed. URL: ${uploadedVideoUrl}, Error: ${JSON.stringify(error)}`,
              user_id: userContext?.user?.id
            });
          } else {
            postAudit({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              user_action: `Error uploading a video. URL: ${uploadedVideoUrl}`,
              user_id: userContext?.user?.id
            });
          }
        });

      if (response && response?.data?.id) {
        messageApi.success('Clips uploading...', 5);
        userContext?.setUser(response?.data);
      } else {
        messageApi.error('Failed to upload a video.', 5);
      }
      setUrl('');
      setDisableMakeClips(true);
    }
    setOpenUploadModal(false);
    setMode(null);
    setUploadedVideoDetails(null);
    setYoutubeVideoDetails(null);
  };


  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
  };

  const getYoutubeVideoDuration = (videoDetails: any) => {
    if (videoDetails) {
      if (videoDetails.contentDetails.videoDuration) {
        return videoDetails.contentDetails.videoDuration;
      } else {
        const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
        const matches = videoDetails.contentDetails.duration.match(regex);
        if (matches) {
          const hours = parseInt(matches[1]) || 0;
          const minutes = parseInt(matches[2]) || 0;
          const seconds = parseInt(matches[3]) || 0;
          const totalSeconds = hours * 3600 + minutes * 60 + seconds / 100;
          return totalSeconds;
        }
      }
    } else {
      return null;
    }
  };




  const errorKey = 'uniqueErrorMessage'; // Unique key for the error message
  let isErrorVisible = false; // Flag to track if an error message is currently visible

  const handleShowUploadModal = (fromUrl = false) => {
    if (fromUrl && url) {
      const isYoutube = isYoutubeUrl(url);
      const isTwitch = isTwitchUrl(url);
      const isVimeo = isVimeoUrl(url);
      const isGoogleDrive = isGoogleDriveUrl(url);

      if (isTwitch) {
        setMode('twitch');
        setUploadedVideoDetails({
          uploadedVideoUrl: url,
          videoDuration: getYoutubeVideoDuration(youtubeVideoDetails),
          videoTitle: youtubeVideoDetails?.snippet?.title,
        });
      } else if (isYoutube) {
        setMode('youtube');
        setUploadedVideoDetails({
          uploadedVideoUrl: url,
          videoDuration: getYoutubeVideoDuration(youtubeVideoDetails),
          videoTitle: youtubeVideoDetails?.snippet?.title,
        });
      } else if (isVimeo) {
        setMode('vimeo');
        setUploadedVideoDetails({
          uploadedVideoUrl: url,
          videoDuration: getYoutubeVideoDuration(youtubeVideoDetails),
          videoTitle: youtubeVideoDetails?.snippet?.title,
        });
      } else if (isGoogleDrive) {
        setMode('google_drive');
        setUploadedVideoDetails({
          uploadedVideoUrl: url,
          videoDuration: getYoutubeVideoDuration(youtubeVideoDetails),
          videoTitle: youtubeVideoDetails?.snippet?.title,
        });
      } else {
        // Only show the error if no other error is currently visible
        if (!isErrorVisible) {
          isErrorVisible = true; // Set the flag to indicate an error is visible
          message.error({
            content: 'Invalid URL',
            duration: 5,
            key: errorKey,
            onClose: () => {
              isErrorVisible = false; // Reset the flag when the message closes
            },
          });
        }

        setMode(null);
        setLoadingPreview(false);
        setUploadedVideoDetails(null);
        setShowUploadStepsModal(false);
      }
    }
  };



  const handleUploadFromComputerSuccess = (uploadedVideoUrl?: string | null, videoDuration?: number | null, videoTitle?: string | null, fileType?: 'video' | 'audio') => {
    setMode('personal');
    setDisableMakeClips(false);
    setUploadedVideoDetails({ uploadedVideoUrl, videoDuration, videoTitle, fileType });
    handleShowUploadModal();
    setShowUploadStepsModal(true);
  };

  const handleTranscribe = useCallback((userTemplate: IEditorTemplate, autoZoomEnabled?: boolean, clipLength?: number, stylePreset?: any, layoutType?: 'mobile' | 'desktop', fileType?: 'video' | 'audio', magicLookText?: string, enableFaceTracking?: boolean, language?: string | null, featureSliders?: any, croppedVideoRange?: [number, number]) => {
    // if (mode === 'personal' || mode === 'youtube') {
    handleMakeClips(userTemplate, autoZoomEnabled, uploadedVideoDetails?.uploadedVideoUrl, uploadedVideoDetails?.videoDuration, uploadedVideoDetails?.videoTitle, 'transcribe', mode, clipLength, stylePreset, layoutType, uploadedVideoDetails?.fileType || fileType, magicLookText, enableFaceTracking, language, featureSliders, croppedVideoRange);
    // }
    return null;
  }, [uploadedVideoDetails?.uploadedVideoUrl, uploadedVideoDetails?.videoDuration, uploadedVideoDetails?.videoTitle, mode, disableMakeClips]);

  const handleExtractHighlights = useCallback((userTemplate: IEditorTemplate, autoZoomEnabled?: boolean, clipLength?: number, stylePreset?: any, layoutType?: 'mobile' | 'desktop', fileType?: 'video' | 'audio', magicLookText?: string, enableFaceTracking?: boolean, language?: string | null, featureSliders?: any, croppedVideoRange?: [number, number]) => {
    // if (mode === 'personal' || mode === 'youtube') {
    handleMakeClips(userTemplate, autoZoomEnabled, uploadedVideoDetails?.uploadedVideoUrl, uploadedVideoDetails?.videoDuration, uploadedVideoDetails?.videoTitle, 'highlights', mode, clipLength, stylePreset, layoutType, uploadedVideoDetails?.fileType || fileType, magicLookText, enableFaceTracking, language, featureSliders, croppedVideoRange);
    // }
    return null;
  }, [uploadedVideoDetails?.uploadedVideoUrl, uploadedVideoDetails?.videoDuration, uploadedVideoDetails?.videoTitle, mode, disableMakeClips]);

  const getVideoDuration = () => {
    // if (mode === 'personal') return uploadedVideoDetails?.videoDuration;
    return uploadedVideoDetails?.videoDuration;
  };

  const getVideoTitle = () => {
    return uploadedVideoDetails?.videoTitle;
  };

  const handleMyClipsClick = () => {
    navigate(`/spikes/search-twitch-clips?username=${userContext?.user?.username}`);
  };

  const handleUploadFromTwitchClick = () => {
    navigate('/spikes/search-twitch-clips');
  };

  const showProgress = progress !== 100;
  const dragOverClass = isDragOver ? 'drag-over' : '';

  const handleFileSelect = (e: any) => {
    postAudit({
      user_action: `TUS - STARTED: User initiated personal upload and selected the file from their computer.`,
      user_id: userContext?.user?.id
    });
    if (loadingFileUpload) {
      postAudit({
        user_action: `TUS - STOPPED: User attempted to upload a file while another file was still uploading.`,
        user_id: userContext?.user?.id
      });
      return null;
    }

    try {
      const file = e.target.files[0];
      if (!file) {
        postAudit({
          user_action: `TUS - FAILED: No file selected.`,
          user_id: userContext?.user?.id
        });
        return;
      }

      setLoadingFileUpload(true);
      postAudit({
        user_action: `TUS - IN PROGRESS: Personal upload to TUS server started. File name: ${file?.name || 'Not defined'}, File size: ${file?.size || 'Not defined'}, File type: ${file?.type || 'Not defined'}`,
        user_id: userContext?.user?.id
      });

      const videoTitle = removeFileExtension(file.name);
      const fileType = checkFileType(file.type);

      // Start the upload immediately
      const upload = new tus.Upload(file, {
        endpoint: process.env.REACT_APP_TUS_SERVER_URL,
        onBeforeRequest: function (req) {
          const xhr = req.getUnderlyingObject();
          xhr.withCredentials = true;
        },
        chunkSize: 5242880, // 5 MB chunks
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file.name,
          filetype: file.type
        },
        onError: function (error: any) {
          setLoadingFileUpload(false);
          postAudit({
            user_action: `TUS - FAILED: Upload error for File name: ${file?.name || 'Not defined'}, File size: ${file?.size || 'Not defined'}, File type: ${file?.type || 'Not defined'}. Error: ${JSON.stringify(error)}`,
            user_id: userContext?.user?.id
          });
          console.log('Upload failed:', error);
        },
        onProgress: function (bytesUploaded: number, bytesTotal: number) {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          setProgress(Number(percentage));
          postAudit({
            user_action: `TUS - IN PROGRESS: ${Number(percentage)}% uploaded.`,
            user_id: userContext?.user?.id
          });
        },
        onSuccess: function () {
          setLoadingFileUpload(false);
          if (upload.url) {
            // Retrieve media duration asynchronously
            getMediaDuration(file)
              .then((duration) => {
                handleUploadFromComputerSuccess(upload.url, duration, videoTitle, fileType);
                postAudit({
                  user_action: `TUS - SUCCESS: Upload finished for File name: ${file?.name || 'Not defined'}, File size: ${file?.size || 'Not defined'}, File type: ${file?.type || 'Not defined'}`,
                  user_id: userContext?.user?.id
                });
              })
              .catch((error) => {
                if (error.message === 'Unsupported media type') {
                  handleCloseStepsModal();
                  postAudit({
                    user_action: `TUS - FAILED: Unsupported media type`,
                    user_id: userContext?.user?.id
                  });
                  return;
                }
                // Handle cases where duration couldn't be retrieved
                handleUploadFromComputerSuccess(upload.url, null, videoTitle, fileType);
                postAudit({
                  user_action: `TUS - SUCCESS: Upload finished for file ${file.name} without duration.`,
                  user_id: userContext?.user?.id
                });
              });
          }
        }
      });

      // Start the upload
      upload.start();
    } catch (error) {
      setLoadingFileUpload(false);
      postAudit({
        user_action: `TUS - FAILED: Unexpected error: ${JSON.stringify(error)}`,
        user_id: userContext?.user?.id
      });
    } finally {
      setIsDragOver(false);
    }
  };

  const getMediaDuration = (file: File): Promise<number> => {
    return new Promise((resolve, reject) => {
      const isVideo = file.type.startsWith('video/');
      const isAudio = file.type.startsWith('audio/');
      const mediaElement = isVideo
        ? document.createElement('video')
        : isAudio
          ? document.createElement('audio')
          : null;

      if (!mediaElement) {
        messageApi.error('Unsupported media type', 5);
        handleCloseStepsModal();
        return reject(new Error('Unsupported media type'));
      }

      mediaElement.preload = 'metadata';

      mediaElement.onloadedmetadata = () => {
        const duration = mediaElement.duration;
        URL.revokeObjectURL(mediaElement.src);
        mediaElement.remove();
        resolve(duration);
      };

      mediaElement.onerror = () => {
        URL.revokeObjectURL(mediaElement.src);
        mediaElement.remove();
        reject(new Error('Failed to load media metadata'));
      };

      mediaElement.src = URL.createObjectURL(file);
    });
  };

  const handleFileDragOver = (event: any) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleFileDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleFileSelect({ target: { files: event.dataTransfer.files } });
    setIsDragOver(false);
  };

  const handleDragLeave = (event: any) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleCloseSpikesGuide = () => {
    localStorage.setItem('spikesGuideSeen', 'true');
    setShowSpikesGuide(false);
  };

  const handleCloseStepsModal = useCallback(() => {
    setShowUploadStepsModal(false);
    clearUrlField();
    setUploadedVideoDetails(null);
    setYoutubeVideoDetails(null);
  }, []);

  const handleVideoDurationLimit = useCallback((customMessage?: string) => {
    handleCloseStepsModal();
    clearUrlField();
    setUploadedVideoDetails(null);
    setYoutubeVideoDetails(null);
    messageApi.error(customMessage || `Duration limit for Add Subtitles is ${transcribeVideoLimit} minutes`, 5);
  }, []);

  const handleShowYoutubeLimit = useCallback(() => {
    handleCloseStepsModal();
    clearUrlField();
    setUploadedVideoDetails(null);
    setYoutubeVideoDetails(null);
    // setOpenYoutubeLimitModal(true);
    setShowYTLimitationLabel(true);
  }, []);

  const handleCloseYoutubeLimit = useCallback(() => {
    setShowYTLimitationLabel(false);
  }, []);

  const clearUrlField = useCallback(() => {
    setUrl('');
  }, []);

  const handleClickOnUploadCard = (uploadId: string) => {
    if (selectedUploadCard === uploadId) {
      setSelectedUploadCard(null); // Close
    } else {
      setSelectedUploadCard(uploadId);
    }
  };

  const toggleMoreOptions = () => {
    setShowMoreOptions(prev => !prev);
  }

  // Function to split the array into chunks
  const chunkArray = (array: any, size: number) => {
    if (!array || array.length < 1) return [];
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const getClipsPerRow = () => {
    if (width > 1000) return 3;
    else if (width > 720) return 2;
    else return 1;
  };

  const handleCollapseClips = () => {
    setSelectedUploadCard(null);
  };

  const handleCloseMaintenancePopup = () => {
    setOpenMaintenancePopup(false);
  }

  const handleCloseYoutubeLimitModal = () => {
    setOpenYoutubeLimitModal(false);
  }

  const handleSampleUpload = () => {
    setUrl(SAMPLE_UPLOAD_URL);
  }

  const hasLoadingProjects = streams && Boolean(streams.find(stream => !checkStreamIsAvailableToRender(stream)));
  const allProjectsLoaded = streams && streams.length === streams[0]?.total;

  return (
    <>
      <div style={styles.topItemsContainer}>
        <Modal
          isOpen={openMaintenancePopup}
          className="Modal"
          overlayClassName="Overlay"
          shouldCloseOnOverlayClick={true}
          onRequestClose={handleCloseMaintenancePopup}
        >
          <div style={{ width: 470 }} className="glass-modal">
            <span onClick={handleCloseMaintenancePopup} className="icon-close">X</span>
            <h1>🔨 Youtube maintenance</h1>
            <p>Youtube support is currently under maintenance. In the meantime, please try uploading your video directly from your computer or via Google Drive.</p>

            <div className="buttons center">
              <div style={{ minWidth: 170 }} onClick={handleCloseMaintenancePopup} className="confirm-btn">
                <span>Got it!</span>
              </div>
            </div>

          </div>
        </Modal>
        <YoutubeLimitModal open={openYoutubeLimitModal} onClose={handleCloseYoutubeLimitModal} />

        <div style={{ color: 'black', display: 'flex', justifyContent: 'center', width: '100%', borderRadius: 8, border: '1px solid var(--border-neutral-default)' }}>
          {contextHolder}
          {/* <UploadModal
            videoDuration={getVideoDuration()}
            videoTitle={getVideoTitle()}
            mode={mode} open={openUploadModal}
            onClose={handleCloseUploadModal}
            handleExtractHighlights={handleExtractHighlights}
            handleTranscribe={handleTranscribe}
          /> */}
          <Input id='main-input' placeholder={inputPlaceholder} value={url} style={styles.main} onChange={(e) => setUrl(e.target.value)} />
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <div style={styles.makeClipsButtonWrapper}>
            <div id='make-clips-button' className={`opacity-hover ${disableMakeClips ? 'disabled' : ''}`} onClick={handleClickMakeClipsButton} style={{ ...styles.makeClipsButtonStyle }}>
              {/* {loadingPreview ? <Spin indicator={<LoadingOutlined style={{ color: '#000', fontSize: 22 }} spin />} style={{ marginRight: 3, marginLeft: -10 }} /> : <Image src={IconLightning} preview={false} />} */}

              <Typography style={styles.makeClipsText}>{loadingPreview ? 'Loading...' : 'Make Clips'}</Typography>
            </div>
          </div>
        </div>

        <div
          onDragOver={handleFileDragOver}
          onDrop={handleFileDrop}
          className={`file-upload-wrapper ${dragOverClass}`}
          onDragLeave={handleDragLeave}
        >
          <input
            type="file"
            accept="audio/*,video/*"
            id="file-input"
            className="file-input"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
          />
          <label htmlFor="file-input" className="file-input-label">
            {loadingFileUpload ? `Uploading your file (${progress}%)` : 'Choose a file or drag it here'}
            <IconUpload />
          </label>
        </div>

        <div onClick={handleSampleUpload} className="sample-upload-link">Click here to try a sample upload</div>

        <ErrorInfoModal open={showYTLimitationModal} onClose={() => setShowYTLimitationModal(false)} platform='youtube' customTitle='How to download your YouTube videos' withoutDescription />



        {showUploadStepsModal &&
          <UploadSteps
            isOpen={showUploadStepsModal}
            url={urlForPreview}
            setLoading={setLoadingPreview}
            setDisableMakeClips={setDisableMakeClips}
            setYoutubeVideoDetails={setYoutubeVideoDetails}
            handleExtractHighlights={handleExtractHighlights}
            handleTranscribe={handleTranscribe}
            handleCloseModal={handleCloseStepsModal}
            uploadedVideoDetails={uploadedVideoDetails}
            clearUrlField={clearUrlField}
            handleVideoDurationLimit={handleVideoDurationLimit}
            handleShowYoutubeLimit={handleShowYoutubeLimit}
            handleCloseYoutubeLimit={handleCloseYoutubeLimit}
            numberOfProjects={streams?.length}
          />

        }

        {showSpikesGuide && !isMobile && <SpikesGuide handleClose={handleCloseSpikesGuide} />}
      </div>


      <div style={styles.resultsContainer}>
        {/* {hasLoadingProjects && <div className='game-card-wrapper'><GameModal /></div>} */}

        {streams && hasLoadingProjects &&
          <div className='loading-projects-grid'>
            {streams?.length > 0 && streams.map((stream: any, i: number) => !checkStreamIsAvailableToRender(stream) && (
              <>
                <UploadCard selectedUploadCard={selectedUploadCard} key={stream.id} stream={stream} index={i} refetch={refetch} isYoutubeCard loadingCard />
              </>
            ))}
          </div>
        }
        <div className='projects-container'>

          <div className='title'>Latest Projects</div>
          <Row gutter={[24, 24]}>
            {streams ?
              <>
                {streams?.length > 0 && streams?.map((stream: any, i) => checkStreamIsAvailableToRender(stream) && (
                  <Col key={stream.id} xs={24} md={12} lg={8}><UploadCard selectedUploadCard={selectedUploadCard} key={stream.id} stream={stream} index={i} refetch={refetch} isYoutubeCard /></Col>
                ))}
                {isFetching && !allProjectsLoaded &&
                  <>
                    <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                    <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                    <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                    <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                    <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                    <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                    <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                    <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                    <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                    <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                  </>
                }
              </>
              :
              <>
                <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
              </>
            }
          </Row>
        </div>

        {/* {chunkedStreams ?
            <>
              {chunkedStreams?.length > 0 &&
                chunkedStreams.map(streamsArray =>
                  streamsArray?.length > 0 &&
                  <UploadRow stream={streamsArray.find((stream: IStream) => stream.id === selectedUploadCard)} refetch={refetch} handleCollapseClips={handleCollapseClips} isTranscribePage={pageMode === 'transcribe'}>
                    {(streamsArray?.map((stream: IStream, i: number) => stream.upload_mode === pageMode && (
                      <Fragment key={stream.id}>
                        <div onClick={() => handleClickOnUploadCard(stream.id)}>
                          <UploadCard selectedUploadCard={selectedUploadCard} key={stream.id} stream={stream} index={i} refetch={refetch} isYoutubeCard />
                        </div>
                      </Fragment>
                    )))}
                  </UploadRow>
                )
              }
            </>
            :
            <>
              <Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} />
              <div style={styles.horizontalDivider} />
              <Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} />
            </>
          } */}
      </div>
    </>
  );
});

Videos.displayName = 'Videos';

export default Videos;