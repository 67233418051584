import { Col, Row, Space, Button, Typography } from 'antd';
import { useMemo } from 'react';
import { COLORS } from '../../themes/colors';
import Title from 'antd/lib/typography/Title';
import Modal from 'react-modal';
import './DeleteClipModal.less'; // Include a CSS/LESS file for custom styles

export const DeleteClipModal = ({ open, setOpen, onDeleteClip }: { open: boolean; setOpen: (isOpen: boolean) => void; onDeleteClip: () => void; }) => {

  const styles = useMemo(() => {
    return {
      modalStyle: {
        borderRadius: 24,
        overflow: 'hidden',
        border: '1px solid #FFF',
        borderColor: COLORS.PRIMARY,
      },
      modalBody: {
        background: '#001922',
        padding: 40,
      },
      checkboxStyle: {
        fontSize: 18,
        marginTop: 10,
        marginLeft: 0
      }
    } as const;
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      isOpen={open}
      className="Modal"
      overlayClassName="Overlay"
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
    >
      <div className="delete-clip-modal">
        <span onClick={onClose} className="icon-close">X</span>
        <h1>Remove the Clip</h1>
        <p>Are you sure you&apos;d like to remove the clip?</p>
        <div className='delete-clip-modal__buttons'>
          <div onClick={onClose} className="delete-clip-modal__button cancel">Keep Clip</div>
          <div onClick={onDeleteClip} className="delete-clip-modal__button delete">Remove Clip</div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteClipModal;
