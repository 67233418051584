import React from 'react';
import { SubtitleWord } from './intefaces/stream.interface';

interface IWordContext {
  selectedWord?: SubtitleWord | null | undefined;
  setSelectedWord: (word: any) => void;
  selectedSentenceIndex?: number | null | undefined;
  setSelectedSentenceIndex: (index: any) => void;
  isChanged: boolean;
  setIsChanged: (isChanged: any) => void;
}

export const WordContext = React.createContext<IWordContext>({
  selectedWord: null,
  setSelectedWord: () => null,
  selectedSentenceIndex: null,
  setSelectedSentenceIndex: () => null,
  isChanged: false,
  setIsChanged: () => null,
});
