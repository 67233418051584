import React, { useState, useMemo, useContext, useEffect } from 'react';
import './Templates.less';
import { UserContext } from '../../../data/userContext';
import { useNavigate } from 'react-router';
import { getEnabledAnimatedCaptionsForUser } from '../../../utils';
import { PricingModal } from '../../../components/pricing-modal/PricingModal';
import { TextSettings } from '../subtitles/subtitles-panel/SubtitlesPanel';
import { ReactComponent as BlockIcon } from '../../../assets/block-icon.svg';
import { ReactComponent as ColorWheelIcon } from '../../../assets/color-wheel.svg';
import { ReactComponent as DropdownIndicatorIcon } from '../../../assets/dropdown-indicator-small.svg';
import { SketchPicker } from 'react-color';
import { useDetectClickOutside } from 'react-detect-click-outside';
import Select, { DropdownIndicatorProps, components } from 'react-select';
import { IStreamer } from '../../../data/intefaces/streamer.interface';

type TextTransform = 'uppercase' | 'lowercase' | 'none' | 'capitalize' | 'initial' | 'inherit';

interface SubtitlesMultiselectButtonProps {
  handleChangeTemplate: (param: any) => void;
  handleSubtitlesChange: (name: keyof TextSettings, value: string | number) => void;
  handleChangeCaptionGrouping: (wordLevel: boolean) => void;
  subtitlesSettings: any;
  user?: IStreamer;
}

export const fontSizeOptions = {
  name: 'fontSize',
  options: [
    { value: 'Small', label: 'S' },
    { value: 'Medium', label: 'M' },
    { value: 'Big', label: 'L' },
    { value: 'Huge', label: 'XL' },
  ],
};

const Templates = ({ user, handleChangeTemplate, handleSubtitlesChange, subtitlesSettings, handleChangeCaptionGrouping }: SubtitlesMultiselectButtonProps) => {
  const boxData = [
    {
      name: 'Default',
      style: {
        background: '#000000',
        color: '#FFFFFF',
        fontFamily: 'Impact',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Heavy',
        fontSize: 'Normal',
      },
      isActive: true
    },
    {
      name: 'RedAlrt',
      style: {
        background: '#F44336',
        color: '#FFEB3B',
        fontFamily: 'Oswald',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Medium'
      },
      isActive: true
    },
    {
      name: 'Sneakers',
      style: {
        background: '#673AB7',
        color: '#FFEB3B',
        fontFamily: 'Bebas Neue',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Heavy',
        fontSize: 'Medium'
      },
      isActive: true
    },
    {
      name: 'Ruby',
      style: {
        background: '#000000',
        color: '#E0115F',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Heavy',
        fontSize: 'Big',
      },
      isActive: true
    },
    {
      name: 'Emerald',
      style: {
        background: '#284828',
        color: '#04F827',
        fontFamily: 'Georgia',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Huge',
      },
      isActive: true
    },
    {
      name: 'NghtVlt',
      style: {
        background: '#673AB7',
        color: '#FFFFFF',
        fontFamily: 'Kanit',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Medium'
      },
      isActive: true
    },
    {
      name: 'Silver',
      style: {
        background: '#C0C0C0',
        color: '#222222',
        fontFamily: 'Times New Roman',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Medium',
      },
      isActive: true
    },
    {
      name: 'Sunshin',
      style: {
        background: '#FFC107',
        color: '#2196F3',
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Medium'
      },
      isActive: true
    },
    {
      name: 'Mono',
      style: {
        background: '#FFFFFF',
        color: '#000000',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Medium'
      },
      isActive: true
    },
    {
      name: 'SkyHigh',
      style: {
        background: '#2196F3',
        color: '#FFFFFF',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Medium'
      },
      isActive: true
    },
    {
      name: 'Tangeri',
      style: {
        background: '#FF9800',
        color: '#000000',
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Medium'
      },
      isActive: true
    },
    {
      name: 'GrnGlow',
      style: {
        background: '#4CAF50',
        color: '#FFFFFF',
        fontFamily: 'Lato',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Huge'
      }
    },
    {
      name: 'SpcShad',
      style: {
        background: '#FF5722',
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Medium'
      },
      isActive: true
    },
    {
      name: 'BlBliss',
      style: {
        background: '#03A9F4',
        color: '#FFFFFF',
        fontFamily: 'Work Sans',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Medium'
      },
      isActive: true
    },
    {
      name: 'GldGlow',
      style: {
        background: '#FFC107',
        color: '#FFFFFF',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Medium'
      },
      isActive: true
    },
    {
      name: 'MidnightBlue',
      style: {
        background: '#003366',
        color: '#FFFFFF',
        fontFamily: 'Courier New',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Large',
      },
      isActive: true
    },
    {
      name: 'RoseGold',
      style: {
        background: '#B76E79',
        color: '#FFFFFF',
        fontFamily: 'Serif',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Light',
        fontSize: 'Small',
      },
      isActive: true
    },
    {
      name: 'OceanWave',
      style: {
        background: '#00BFFF',
        color: '#f0f8ff',
        fontFamily: 'Verdana',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'None',
        fontSize: 'Medium',
      },
      isActive: true
    },
    {
      name: 'LimeLight',
      style: {
        background: '#00FF00',
        color: '#000000',
        fontFamily: 'Helvetica',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Heavy',
        fontSize: 'Large',
      },
      isActive: true
    },
    {
      name: 'VintageCharm',
      style: {
        background: '#F5F5DC',
        color: '#8B4513',
        fontFamily: 'Garamond',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Small',
      },
      isActive: true
    },
    // {
    //   name: 'Winter',
    //   style: {
    //     background: '#E0FFFF',
    //     color: '#000080',
    //     fontFamily: 'Caprasimo',
    //     fontWeight: 'bold',
    //     textTransform: 'uppercase' as TextTransform,
    //     textShadow: 'Medium',
    //     fontSize: 'Medium',
    //   },
    //   isActive: true
    // },
    {
      name: 'NeonPink',
      style: {
        background: '#FF1493',
        color: '#FFFFFF',
        fontFamily: 'Impact',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Heavy',
        fontSize: 'Medium',
      },
      isActive: true
    },
    {
      name: 'DarkForest',
      style: {
        background: '#228B22',
        color: '#F0E68C',
        fontFamily: 'Arial Narrow',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Light',
        fontSize: 'Medium',
      },
      isActive: true
    },
    {
      name: 'SapphireGlow',
      style: {
        background: '#0F52BA',
        color: '#FFFFFF',
        fontFamily: 'Koulen',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'None',
        fontSize: 'Medium',
      },
      isActive: true
    },
    {
      name: 'GoldenSunset',
      style: {
        background: '#FFD700',
        color: '#8B0000',
        fontFamily: 'Times New Roman',
        fontWeight: 'bold',
        textTransform: 'uppercase' as TextTransform,
        textShadow: 'Medium',
        fontSize: 'Medium',
      },
      isActive: true
    }

    // Add other box objects with style property...
  ];

  const [activeBox, setActiveBox] = useState<string | null>(null);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const handleClick = (box: any) => {
    handleChangeTemplate(box.name);
    setActiveBox(box.name);
  };

  const styles = useMemo(() => {
    return {
      boxContainer: {
        display: 'flex',
        flexWrap: 'wrap' as const,
        justifyContent: 'center',
        padding: '20px',
        height: '100%',
        overflow: 'auto',
      },
      box: {
        color: 'var(--White, #FFF)',
        textAlign: 'center' as const,
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '97.846px',
        maxHeight: '98px',
        cursor: 'pointer',
        transition: 'transform 0.2s ease-in-out',
        border: '3px solid transparent',
        position: 'relative' as const,
      },
      focusedBox: {
        border: '1px solid #fff'
      },
      notFocused: {
        border: '1px solid transparent',
      },
      smallText: {
        position: 'absolute' as const,
        bottom: '5px',
        left: '0',
        right: '0',
        color: 'var(--White, #67FFCC)',
        fontFamily: 'Poppins',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '14.218px',
      },
      inactiveBox: {
        opacity: 0.5, // Make inactive boxes grayed out
        cursor: 'not-allowed', // Make inactive boxes not clickable
      },
      comingSoonText: {
        position: 'absolute' as const,
        bottom: '5px',
        left: '0',
        right: '0',
        color: 'orange',
        fontFamily: 'Poppins',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '14.218px',
      },

    };
  }, []);
  const [chosenColor, setChosenColor] = useState('');
  const [chosenStrokeColor, setChosenStrokeColor] = useState('');
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [openStrokeColorPicker, setOpenStrokeColorPicker] = useState(false);

  const colorPickerRef = useDetectClickOutside({ onTriggered: () => setOpenColorPicker(false) });
  const strokeColorPickerRef = useDetectClickOutside({ onTriggered: () => setOpenStrokeColorPicker(false) });


  const isBoldFont = subtitlesSettings['fontWeight'] === 'bold';
  const isUppercaseFont = subtitlesSettings['textTransform'] === 'uppercase';
  const isGroupLevel = subtitlesSettings['wordLevel'];

  const handleOpenColorPicker = () => {
    setOpenColorPicker(open => !open);
    setOpenStrokeColorPicker(false);
  };

  const handleOpenStrokeColorPicker = () => {
    setOpenStrokeColorPicker(open => !open);
    setOpenColorPicker(false);
  };

  const handleFontWeightChange = () => {
    handleSubtitlesChange('fontWeight', isBoldFont ? 'normal' : 'bold');
  };

  const handleTransformChange = () => {
    handleSubtitlesChange('textTransform', isUppercaseFont ? 'none' : 'uppercase')
  };

  const handleStrokeChange = (value: string) => {
    handleSubtitlesChange('textShadow', value);
  };

  const handleChooseColor = (color: any) => {
    setChosenColor(color);
    handleSubtitlesChange('color', color.hex);
  };

  const handleChooseStrokeColor = (color: any) => {
    setChosenStrokeColor(color);
    handleSubtitlesChange('background', color.hex);
  };

  const handleChooseAlignment = (position: string) => {
    handleSubtitlesChange('alignItems', position);
  };

  const handleChooseCaptionGrouping = (wordLevel: boolean) => {
    handleChangeCaptionGrouping(wordLevel);
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
      <components.DropdownIndicator {...props}>
        <DropdownIndicatorIcon />
      </components.DropdownIndicator>
    );
  };

  console.log('textShadow:', subtitlesSettings['textShadow']);

  return (
    <div className="editor-font-design scrollbar">
      <div className="editor-font-design__section">
        <div className="editor-font-design__section-content">
          <p className="editor-font-design__section-title">Font Styling</p>
          <div className="editor-font-design__buttons">
            <div onClick={handleFontWeightChange} className={`editor-font-design__button ${isBoldFont && 'active'}`} style={{ fontWeight: 700 }}>B</div>
            <div onClick={handleTransformChange} className={`editor-font-design__button ${isUppercaseFont && 'active'}`}>AA</div>
            <Select
              className="font-size-select"
              classNamePrefix="font-size-select-prefix"
              value={fontSizeOptions.options.find(el => el.value === subtitlesSettings['fontSize'])}
              onChange={({ value }: any) => handleSubtitlesChange('fontSize', value)}
              options={fontSizeOptions.options}
              components={{ DropdownIndicator }}
              placeholder='Fonts'
            />
            <div ref={colorPickerRef} className="editor-font-design__color-button">
              <div onClick={handleOpenColorPicker} className="editor-font-design__button">
                <ColorWheelIcon />
              </div>
              {openColorPicker &&
                <div className="editor-font-design__font-colorpicker"
                     onMouseDown={(e) => e.preventDefault()}>
                  <SketchPicker color={chosenColor} onChangeComplete={handleChooseColor} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="editor-font-design__section">
        <div className="editor-font-design__section-content">
          <p className="editor-font-design__section-title">Stroke</p>
          <div className="editor-font-design__buttons">
            <div onClick={() => handleStrokeChange('None')} className={`editor-font-design__button ${subtitlesSettings['textShadow'] === 'None' && 'active'}`}><BlockIcon /></div>
            <div onClick={() => handleStrokeChange('Medium')} className={`editor-font-design__button ${subtitlesSettings['textShadow'] === 'Medium' && 'active'}`}>M</div>
            <div onClick={() => handleStrokeChange('Heavy')} className={`editor-font-design__button ${subtitlesSettings['textShadow'] === 'Heavy' && 'active'}`}>L</div>
            <div ref={strokeColorPickerRef} className="editor-font-design__color-button">
              <div onClick={handleOpenStrokeColorPicker} className="editor-font-design__button">
                <ColorWheelIcon />
              </div>
              {openStrokeColorPicker &&
                <div className="editor-font-design__stroke-colorpicker"
                     onMouseDown={(e) => e.preventDefault()}>
                  <SketchPicker color={chosenStrokeColor} onChangeComplete={handleChooseStrokeColor} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="editor-font-design__section">
        <div className="editor-font-design__section-row">
          <div className="editor-font-design__section-column">
            <p className="editor-font-design__section-title">Caption Position</p>
            <div onClick={() => handleChooseAlignment('Top')} className={`editor-font-design__column-button editor-font-design__button ${subtitlesSettings['alignItems'] === 'Top' && 'active'}`}>Top</div>
            <div onClick={() => handleChooseAlignment('Middle')} className={`editor-font-design__column-button editor-font-design__button ${subtitlesSettings['alignItems'] === 'Middle' && 'active'}`}>Middle</div>
            <div onClick={() => handleChooseAlignment('Bottom')} className={`editor-font-design__column-button editor-font-design__button ${subtitlesSettings['alignItems'] === 'Bottom' && 'active'}`}>Bottom</div>
          </div>
          <div className="editor-font-design__section-column">
            <p className="editor-font-design__section-title">Caption Grouping</p>
            <div onClick={() => handleChooseCaptionGrouping(true)} style={{ width: 112 }} className={`editor-font-design__column-button editor-font-design__button ${isGroupLevel && 'active'}`}>Group Level</div>
            <div onClick={() => handleChooseCaptionGrouping(false)} style={{ width: 112 }} className={`editor-font-design__column-button editor-font-design__button ${!isGroupLevel && 'active'}`}>Word Level</div>
          </div>
        </div>
      </div>
      <div className="editor-font-design__section">
        <div className="editor-grid">
          {boxData.map((box, index) => (
            <div
              id={box.name}
              key={index}
              className='presetBox editor-grid__item'
              style={{
                ...styles.box,
                ...(activeBox === box.name ? styles.focusedBox : styles.notFocused),
                ...(box.isActive === false ? styles.inactiveBox : {}),
                ...box.style, // Apply box style
                textShadow: `0px 2.34375px 0.0046875px ${box.style.background}, 2.296875px 0.4921875px 0.0046875px ${box.style.background}, 0.984375px -2.1328125px 0.0046875px ${box.style.background}, -1.875px -1.40625px 0.0046875px ${box.style.background}, -1.78125px 1.5234375px 0.0046875px ${box.style.background}, 1.125px 2.0625px 0.0046875px ${box.style.background}, 2.25px -0.65625px 0.0046875px ${box.style.background}, -0.1640625px -2.34375px 0.0046875px ${box.style.background}, -2.3203125px -0.3515625px 0.0046875px ${box.style.background}`,
              }}
              onClick={() => handleClick(box)}
            >

              <span style={{ height: 'auto' }}>{box.name}</span>

              {box.isActive === false && (
                <div style={styles.comingSoonText}>Coming Soon</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Templates;
