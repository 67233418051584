import React, { useEffect, useMemo, useRef, useState } from 'react';
import { COLORS } from '../../themes/colors';
import { Rnd } from 'react-rnd';
import { makeFontSizeResponsive } from '../../utils';
import { ReactComponent as TiktokIcon } from '../../assets/tiktok_elements.svg';
import { ReactComponent as TwitterIcon } from '../../assets/twitter_elements.svg';
import { ReactComponent as TwitchIcon } from '../../assets/twitch_elements.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/youtube_elements.svg';
import { ReactComponent as InstagramIcon } from '../../assets/instagram_elements.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebook_elements.svg';
import { TEXT_FEATURE_PADDING_WITH_BACKGROUND } from '../../constants/content-constants';

export interface IContentFeedProps {
  defaultPosition?: { x: number, y: number, width: number, height: number };
  position?: { x: number, y: number };
  size?: { width: number, height: number };
  ratio: number;
  lockRatio: boolean,
  borderColor?: string;
  isRound?: boolean;
  showOutline: boolean,
  canvasRef: React.MutableRefObject<HTMLCanvasElement>
  onDragPositionChange?: ({ x, y }: { x: number, y: number, width: number, height: number }) => void,
  imageUrl?: string;
  videoUrl?: string;
  svgType?: string;
  svgText?: string;
  zIndex?: number;
  videoStartEndTime?: number[];
  isMainPlayerPlaying?: boolean;
  currentSeekedTimeOfMainPlayer?: number;
  textFeatureSlider?: any;
  playerWidth?: number;
  disableDragging?: boolean;
  isVerticalVideo: boolean;
}
type IconMap = {
  tiktok: React.ReactNode;
  twitter: React.ReactNode;
  twitch: React.ReactNode;
  youtube: React.ReactNode;
  instagram: React.ReactNode;
  facebook: React.ReactNode;
};
const iconMap: IconMap = {
  tiktok: <TiktokIcon />,
  twitter: <TwitterIcon />,
  twitch: <TwitchIcon />,
  youtube: <YoutubeIcon />,
  instagram: <InstagramIcon />,
  facebook: <FacebookIcon />,
};
export const ContentFeed = ({
  defaultPosition = { x: 0, y: 0, width: 0, height: 0 },
  position,
  size,
  ratio,
  borderColor = '#000',
  isRound,
  lockRatio,
  onDragPositionChange,
  canvasRef,
  showOutline = true,
  imageUrl,
  videoUrl,
  svgType,
  svgText,
  zIndex,
  videoStartEndTime,
  isMainPlayerPlaying,
  currentSeekedTimeOfMainPlayer,
  textFeatureSlider,
  playerWidth,
  isVerticalVideo,
  disableDragging = false
}: IContentFeedProps) => {
  const draggable = useRef(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [seekedTimeOfMainPlayer, setSeekedTimeOfMainPlayer] = useState(currentSeekedTimeOfMainPlayer);

  useEffect(() => {
    if (isMainPlayerPlaying) {
      playVideo();
    } else {
      pauseVideo();
    }

    if (currentSeekedTimeOfMainPlayer && currentSeekedTimeOfMainPlayer >= 0 && currentSeekedTimeOfMainPlayer !== seekedTimeOfMainPlayer) {
      if (videoStartEndTime && videoStartEndTime[0]) {
        const currentTime = currentSeekedTimeOfMainPlayer - videoStartEndTime[0];
        seekVideo(currentTime);
        setSeekedTimeOfMainPlayer(currentSeekedTimeOfMainPlayer);
      }
    }
  }, [currentSeekedTimeOfMainPlayer, isMainPlayerPlaying, videoStartEndTime]);


  function playVideo() {
    videoRef?.current?.play();
  }

  function pauseVideo() {
    videoRef?.current?.pause();
  }

  const seekVideo = (time: number) => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = time;
    }
  };
  function renderSvg() {
    const iconType = svgType || 'tiktok';
    const icon = iconMap[iconType as keyof typeof iconMap];
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="250" height="50">
        {icon}
        <text x="35" y="20" fontSize="18" fill="white">
          {svgText}
        </text>
      </svg>
    );
  }

  const styles = useMemo(() => {
    return {
      container: {
        position: 'absolute',
        top: 0,
        cursor: 'grab',
        // background: !isRound && showOutline ? `linear-gradient(to right, ${borderColor} 50%, transparent 0%) top/14px 3px repeat-x, linear-gradient(${borderColor} 50%, transparent 0%) right/3px 14px repeat-y, linear-gradient(to right, ${borderColor} 50%, transparent 0%) bottom/14px 3px repeat-x, linear-gradient(${borderColor} 50%, transparent 0%) left/3px 14px repeat-y` : 'unset',
        overflow: isRound ? 'hidden' : 'unset',
      },
      handle: {
        width: size?.width,
        height: size?.height,
        border: showOutline ? `2px solid ${borderColor}` : 'none',
        borderRadius: isRound && size?.width ? size?.width / 2 : 0,
        // background: 'rgba(255, 255, 255, 0.2)',
      },
      roundHandle: {
        width: 8,
        height: 8,
        border: `1px solid ${borderColor}`,
        position: 'absolute',
      },
      roundHandle1: {
        top: 0,
        left: 0,
      },
      roundHandle2: {
        top: 0,
        right: -2,
      },
      roundHandle3: {
        bottom: -2,
        left: 0,
      },
      roundHandle4: {
        bottom: -2,
        right: -2,
      },
      feedCanvas: {
        overflow: 'hidden',
        position: 'absolute',
        top: 1,
      },
      cameraCanvas: {
        overflow: 'hidden',
        position: 'absolute',
        top: 1,
      },
      cameraRoundCanvas: {
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: '50%',
      },
      imageStyles: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        userSelect: 'none'
      },
      videoStyles: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        userSelect: 'none',
        borderRadius: 0
      }
    } as const;
  }, [borderColor, size, isRound]);

  const textShadowPreset = {
    light: '0.0432em 0.0432em 0 var(--text-shadow-color), -0.0216em -0.0216em 0 var(--text-shadow-color),-0.0216em 0.0216em 0 var(--text-shadow-color), 0.0216em -0.0216em 0 var(--text-shadow-color), 0.0216em 0.0216em 0 var(--text-shadow-color)',
    medium: '0.0px 2.34375px 0.0046875px var(--text-shadow-color), 2.296875px 0.4921875px 0.0046875px var(--text-shadow-color), 0.984375px -2.1328125px 0.0046875px var(--text-shadow-color), -1.875px -1.40625px 0.0046875px var(--text-shadow-color), -1.78125px 1.5234375px 0.0046875px var(--text-shadow-color), 1.125px 2.0625px 0.0046875px var(--text-shadow-color), 2.25px -0.65625px 0.0046875px var(--text-shadow-color), -0.1640625px -2.34375px 0.0046875px var(--text-shadow-color), -2.3203125px -0.3515625px 0.0046875px var(--text-shadow-color)',
    heavy: '0.0px 5.0px 0.01px var(--text-shadow-color), 4.9px 1.05px 0.01px var(--text-shadow-color), 2.1px -4.55px 0.01px var(--text-shadow-color), -4.0px -3.0px 0.01px var(--text-shadow-color), -3.8px 3.25px 0.01px var(--text-shadow-color), 2.4px 4.4px 0.01px var(--text-shadow-color), 4.8px -1.4px 0.01px var(--text-shadow-color), -0.35px -5.0px 0.01px var(--text-shadow-color), -4.95px -0.75px 0.01px var(--text-shadow-color), -1.75px 4.7px 0.01px var(--text-shadow-color), 4.2px 2.7px 0.01px var(--text-shadow-color), 3.55px -3.5px 0.01px var(--text-shadow-color), -2.7px -4.2px 0.01px var(--text-shadow-color), -4.7px 1.75px 0.01px var(--text-shadow-color), 0.7px 4.95px 0.01px var(--text-shadow-color), 5.0px 0.4px 0.01px var(--text-shadow-color), 1.45px -4.8px 0.01px var(--text-shadow-color), -4.35px -2.4px 0.01px var(--text-shadow-color), -3.3px 3.75px 0.01px var(--text-shadow-color), 2.95px 4.0px 0.01px var(--text-shadow-color), 4.55px -2.05px 0.01px var(--text-shadow-color), -1.05px -4.9px 0.01px var(--text-shadow-color), -5.0px -0.05px 0.01px var(--text-shadow-color), -1.1px 4.9px 0.01px var(--text-shadow-color), 4.55px 2.1px 0.01px var(--text-shadow-color), 3.05px -4.0px 0.01px var(--text-shadow-color), -3.25px -3.8px 0.01px var(--text-shadow-color), -4.4px 2.35px 0.01px var(--text-shadow-color), 1.35px 4.8px 0.01px var(--text-shadow-color), 4.8px -1.0px 0.01px var(--text-shadow-color), 0.1px -5.0px 0.01px var(--text-shadow-color), -4.85px -1.15px 0.01px var(--text-shadow-color), -2.15px 4.5px 0.01px var(--text-shadow-color), 3.95px 3.05px 0.01px var(--text-shadow-color)',
  };

  const textFeatureSliderStyles = () => {
    if (textFeatureSlider) {
      return {
        fontSize: makeFontSizeResponsive(textFeatureSlider?.fontSize, playerWidth, isVerticalVideo),
        fontWeight: textFeatureSlider?.fontWeight,
        textShadow: textShadowPreset[textFeatureSlider?.textShadow as 'light' | 'medium' | 'heavy'],
        fontFamily: textFeatureSlider?.fontFamily,
        color: textFeatureSlider?.fontColor,
        textTransform: textFeatureSlider?.textTransform === 'uppercase' ? 'uppercase' : 'none',
        overflow: 'hidden',
        maxHeight: '100%',
        wordBreak: 'keep-all',
        '--text-shadow-color': textFeatureSlider.textShadowColor,
        backgroundColor: textFeatureSlider.backgroundColor === 'none' ? 'transparent' : textFeatureSlider.backgroundColor,
        borderRadius: textFeatureSlider.radius,
        padding: textFeatureSlider.backgroundColor !== 'none' ? TEXT_FEATURE_PADDING_WITH_BACKGROUND : '0',
        textAlign: 'center',
      };
    } else {
      return {};
    }
  };

  return (
    <>
      <Rnd
        noderef={draggable}
        handle=".handle"
        bounds="parent"
        default={defaultPosition}
        position={position}
        size={size}
        scale={1}
        style={{ zIndex: zIndex }}
        disableDragging={disableDragging}
        enableResizing={{
          bottom: !isRound,
          bottomLeft: true,
          bottomRight: true,
          left: !isRound,
          right: !isRound,
          top: !isRound,
          topLeft: true,
          topRight: true,
        }}
        minWidth={40}
        lockAspectRatio={lockRatio ? ratio : false}
        onDragStop={(e, draggableData) => onDragPositionChange && onDragPositionChange({
          x: draggableData.x,
          y: draggableData.y,
          width: defaultPosition.width,
          height: defaultPosition.height
        })}
        onResize={(e, direction, ref, delta, position) => onDragPositionChange && onDragPositionChange({
          ...position,
          width: ref.offsetWidth,
          height: ref.offsetHeight
        })}
      >
        {isRound && showOutline &&
          <>
            <div style={{ ...styles.roundHandle, ...styles.roundHandle1 }}></div>
            <div style={{ ...styles.roundHandle, ...styles.roundHandle2 }}></div>
            <div style={{ ...styles.roundHandle, ...styles.roundHandle3 }}></div>
            <div style={{ ...styles.roundHandle, ...styles.roundHandle4 }}></div>
          </>
        }
        {canvasRef
          ?
          <canvas ref={canvasRef} style={isRound ? styles.cameraRoundCanvas : styles.cameraCanvas} width={size?.width} height={size?.height}></canvas>
          :
          (
            <>
              {imageUrl && <img style={styles.imageStyles} src={imageUrl} draggable={false} alt='image' />}
              {videoUrl &&
                <video ref={videoRef} autoPlay muted loop style={styles.videoStyles}>
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              }
              {textFeatureSlider && textFeatureSlider?.text &&
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore 
                <div style={textFeatureSliderStyles()}>{textFeatureSlider.text}</div>
              }
              {svgType && renderSvg()}

            </>
          )
        }
        <div style={styles.container} ref={draggable}>
          <div style={styles.handle}>

          </div>
        </div>


      </Rnd>
    </>
  );
};

export default ContentFeed;