import {
  Carousel,
  Image,
  Slider,
  Spin,
  Switch,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { COLORS } from '../../themes/colors';
import { debounce } from 'lodash';

import axios from 'axios';
import {
  ITwitchVideo,
  IYoutubeVideo,
  IVimeoVideo,
  IGoogleDriveVideo,
} from '../../data/intefaces/video.interface';
import { UserContext } from '../../data/userContext';
import {
  isTwitchUrl,
  isYoutubeUrl,
  isVimeoUrl,
  isGoogleDriveUrl,
  roundWithTwoDecimals,
  useTraceUpdate,
  checkFileType,
  completeOnboardingStep,
  getPricingRedirectUrl,
  showGoProButton,
  truncateText,
  showAlternativeUiByUser,
} from '../../utils';
import {
  demoVideoPreview,
  supportedLanguages,
} from '../../constants/content-constants';
import { ReactComponent as IconDesktop } from '../../assets/desktop-icon.svg';
import { ReactComponent as IconMobile } from '../../assets/mobile-icon.svg';
import { ReactComponent as IconStar } from '../../assets/blurred-star-no-color.svg';
import { ReactComponent as IconStarNoColor } from '../../assets/icon-star-no-color.svg';
import { ReactComponent as PricingCard } from '../../assets/pricing-card.svg';
import { ReactComponent as IconMagicWand } from '../../assets/magic_wand.svg';
import { ReactComponent as IconInfoCircle } from '../../assets/info-circle.svg';
import { ReactComponent as IconInfo } from '../../assets/info.svg';

import { ReactComponent as IconClose } from '../../assets/modal-close.svg';
import IconCloseCircle from '../../assets/close-circle.svg';
import Blog3 from '../../assets/blog-3.png';
import LayoutDesktop from '../../assets/layout-desktop.png';
import LayoutMobile from '../../assets/layout-mobile.png';
import UploadFromComputerImage from '../../assets/upload-from-computer.png';
import PricingModalImage from '../../assets/pricing-modal-image.png';
import StyleOneGif from '../../assets/style-one.gif';
import StyleTwoGif from '../../assets/style-two.gif';
import StyleThreeGif from '../../assets/style-three.gif';
import StyleFourGif from '../../assets/style-four.gif';
import { ReactComponent as RightArrow } from '../../assets/right-arrow.svg';
import { ReactComponent as DropdownIndicatorIcon } from '../../assets/dropdown-indicator.svg';
import Modal from 'react-modal';
import './UploadSteps.less';
import { LoadingOutlined } from '@ant-design/icons';
import { SubscriptionCard } from '../../pages/Subscriptions/subscription-card';
import Select, { DropdownIndicatorProps, components } from 'react-select';
import { useWindowDimensions } from '../../pages/Editor/Editor.page';
import { postAudit } from '../../services/audit.service';
import { StyleStep } from './StyleStep';
import { AIGeneratorTextArea } from '../../pages/Editor/BRollTab/BRollTabInput';
import { PricingModal } from '../pricing-modal/PricingModal';
import { OnboardingContext } from '../../data/onboardingContext';
import { IEditorTemplate } from '../../data/intefaces/streamer.interface';

Modal.setAppElement('#root');

const convertTotalDurationToSeconds = (input: string | number | null | undefined): number => {
  if (input === null || input === undefined) return 0;

  if (typeof input === 'number') {
    input = parseFloat(input?.toFixed(2));
  }

  // Convert input to string and split by the decimal point
  const [minutesStr, secondsStr] = input.toString().split(".");

  // Parse minutes and ensure seconds are two digits
  const minutes = parseInt(minutesStr, 10);
  const seconds = parseInt(secondsStr?.padEnd(2, "0") || "0", 10); // Pad to two digits if needed

  // Convert total duration to seconds
  return minutes * 60 + seconds;
};

const convertSecondsToMinutesFormat = (totalSeconds: number): number => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  // Combine minutes and seconds into the desired format
  return parseFloat(`${minutes}.${seconds.toString().padStart(2, '0')}`);
};

export interface IUploadStepsProps {
  url: string;
  setLoading: (loading: boolean) => void;
  setDisableMakeClips: (disabled: boolean) => void;
  setYoutubeVideoDetails: (details: any) => void;
  demoMode?: boolean;
  isOpen: boolean;
  handleTranscribe: (
    userTemplate: IEditorTemplate,
    autoZoomEnabled?: boolean,
    clipLength?: number,
    stylePreset?: any,
    layoutType?: 'mobile' | 'desktop',
    fileType?: 'video' | 'audio',
    magicLookText?: string,
    enableFaceTracking?: boolean,
    language?: string | null,
    featureSliders?: any,
    croppedVideoRange?: [number, number]
  ) => void;
  handleExtractHighlights: (
    userTemplate: IEditorTemplate,
    autoZoomEnabled?: boolean,
    clipLength?: number,
    stylePreset?: any,
    layoutType?: 'mobile' | 'desktop',
    fileType?: 'video' | 'audio',
    magicLookText?: string,
    enableFaceTracking?: boolean,
    language?: string | null,
    featureSliders?: any,
    croppedVideoRange?: [number, number]
  ) => void;
  handleCloseModal: () => void;
  clearUrlField: () => void;
  handleShowYoutubeLimit: () => void;
  handleCloseYoutubeLimit: () => void;
  handleVideoDurationLimit: (custom?: string) => void;
  uploadedVideoDetails: any;
  numberOfProjects: number;
}

const highlightSteps = [
  {
    id: 'video',
    name: 'Video',
  },
  // {
  //   id: 'layout',
  //   name: 'Layout',
  // },
  {
    id: 'style',
    name: 'Style',
  },
  {
    id: 'finalTune',
    name: 'Final Tune',
  },
];

const transcribeSteps = [
  {
    id: 'video',
    name: 'Video',
  },
  // {
  //   id: 'layout',
  //   name: 'Layout',
  // },
  {
    id: 'style',
    name: 'Style',
  },
  {
    id: 'finalTune',
    name: 'Final Tune',
  },
];

const stylePresets = [
  {
    id: 2,
    label: 'Captions Style',
    name: 'Sentence',
    availableToAll: true,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Medium',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'none',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 1,
    label: 'Captions Style',
    name: 'Word by Word',
    availableToAll: true,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'true',
      captions_animation_type: 'none',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
];

{
  /* REMOVE THIS OBJECT BELOW AND UNCOMMENT ABOVE: ANIMATED CAPTIONS IN DROPDOWN */
}
// const stylePresets = [
//   {
//     id: 1,
//     label: 'Captions Style',
//     name: 'Word by Word',
//     availableToAll: true,
//     style: {
//       background: '#000000',
//       color: '#FFFFFF',
//       textShadow: 'Heavy',
//       fontWeight: 'normal',
//       textTransform: 'uppercase',
//       fontSize: 'Big',
//       isHighlighted: false,
//       alignItems: 'Bottom',
//       fontFamily: 'Impact',
//       wordLevel: 'true',
//       captions_animation_type: 'none'
//     },
//     styleForFrontend: {
//       color: '#FFFFFF',
//     }
//   },
//   {
//     id: 2,
//     label: 'Captions Style',
//     name: 'Sentence',
//     availableToAll: true,
//     style: {
//       background: '#000000',
//       color: '#FFFFFF',
//       textShadow: 'Heavy',
//       fontWeight: 'normal',
//       textTransform: 'uppercase',
//       fontSize: 'Big',
//       isHighlighted: false,
//       alignItems: 'Bottom',
//       fontFamily: 'Impact',
//       wordLevel: 'false',
//       captions_animation_type: 'none'
//     },
//     styleForFrontend: {
//       color: '#FFFFFF',
//     }
//   },
//   {
//     id: 3,
//     label: 'Captions Style',
//     name: 'Background Pop',
//     style: {
//       background: '#000000',
//       color: '#FFFFFF',
//       textShadow: 'Heavy',
//       fontWeight: 'normal',
//       textTransform: 'uppercase',
//       fontSize: 'Big',
//       isHighlighted: false,
//       alignItems: 'Bottom',
//       fontFamily: 'Impact',
//       wordLevel: 'true',
//       captions_animation_type: 'wword'
//     },
//     styleForFrontend: {
//       color: '#FFFFFF',
//     }
//   },
//   {
//     id: 4,
//     label: 'Captions Style',
//     name: 'Bounce',
//     style: {
//       background: '#000000',
//       color: '#FFFFFF',
//       textShadow: 'Heavy',
//       fontWeight: 'normal',
//       textTransform: 'uppercase',
//       fontSize: 'Big',
//       isHighlighted: false,
//       alignItems: 'Bottom',
//       fontFamily: 'Impact',
//       wordLevel: 'false',
//       captions_animation_type: 'bounce'
//     },
//     styleForFrontend: {
//       color: '#FFFFFF',
//     }
//   },
// ];

const additionalStyles = [
  {
    id: 4,
    label: 'Bounce',
    name: 'Bounce',
    animationName: 'bounce-infinite',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'bounce',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 5,
    label: 'Karaoke',
    name: 'Karaoke',
    animationName: 'karaoke-infinite',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'karaoke',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 3,
    label: 'Background Pop',
    name: 'Background Pop',
    animationName: 'wword-infinite',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'true',
      captions_animation_type: 'wword',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 6,
    label: 'Blossom',
    name: 'Blossom',
    animationName: 'blossom',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'blossom',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },

  {
    id: 7,
    label: 'Sunshine',
    name: 'Sunshine',
    animationName: 'sunshine',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'sunshine',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 8,
    label: 'Emerald',
    name: 'Emerald',
    animationName: 'emerald',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'emerald',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 8,
    label: 'Slide-Left',
    name: 'Slide-Left',
    animationName: 'slide-left-infinite',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'slide-left',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
];

const lengthOptions = [
  { value: -1, label: 'Auto' },
  { value: 45, label: '40 - 50 sec' },
  { value: 55, label: '50 - 60 sec' },
  { value: 65, label: '60 - 70 sec' },
  { value: 75, label: '70 - 80 sec' },
  { value: 85, label: '80 - 90 sec' },
];

const VIDEO_MODES = {
  AI_HIGHLIGHTS: 'AI_HIGHLIGHTS',
  ADD_CAPTIONS: 'ADD_CAPTIONS',
  MAGIC_EDIT: 'MAGIC_EDIT',
  REFRAME: 'REFRAME',
  TRIM_VIDEO: 'TRIM_VIDEO',
}

export const UploadSteps = memo(
  ({
    isOpen,
    url,
    setLoading,
    handleShowYoutubeLimit,
    setDisableMakeClips,
    handleCloseYoutubeLimit,
    setYoutubeVideoDetails,
    demoMode = false,
    handleTranscribe,
    handleExtractHighlights,
    handleCloseModal,
    uploadedVideoDetails,
    clearUrlField,
    handleVideoDurationLimit,
    numberOfProjects

  }: IUploadStepsProps) => {
    const [mode, setMode] = useState<'highlights' | 'transcribe'>('highlights');
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [videoDetails, setVideoDetails] = useState<IYoutubeVideo | null>(
      null,
    );
    const [invalidGoogleDriveLink, setinValidGoogleDriveLink] = useState(false);
    const [height, setHeight] = useState(0);
    const [selectedLayoutType, setSelectedLayoutType] = useState<
      'desktop' | 'mobile'
    >('desktop');
    const [selectedStylePreset, setSelectedStylePreset] = useState(
      stylePresets[0],
    );
    const [selectedStyleInDropdown, setSelectedStyleInDropdown] = useState(
      additionalStyles[4],
    );
    const [selectedLength, setSelectedLength] = useState(-1); // Auto
    const [showPricingModal, setShowPricingModal] = useState(false);
    const [loadingVideoDetails, setLoadingVideoDetails] = useState(false);
    const [currentFileType, setCurrentFileType] = useState<'video' | 'audio'>(
      'video',
    );
    const [magicLookText, setMagicLookText] = useState('');
    const [faceTrackingEnabled, setFaceTrackingEnabled] = useState(true);
    const [autoZoomEnabled, setAutoZoomEnabled] = useState(true);
    const [selectedLanguage, setSelectedLanguage] = useState(
      supportedLanguages[0],
    );
    const [croppedVideoRange, setCroppedVideoRange] = useState<[number, number]>([0, 0]);
    const [selectedVideoMode, setSelectedVideoMode] = useState(VIDEO_MODES.AI_HIGHLIGHTS);

    const { width } = useWindowDimensions();
    const isMobile = width < 920;
    const localStorageFaceTrackingEnabled = localStorage.getItem(
      'faceTrackingEnabledPreference',
    );
    const localStorageAutoZoomEnabled = localStorage.getItem(
      'autoZoomEnabledPreference',
    );
    const onboardingContext = useContext(OnboardingContext);

    const [messageApi, contextHolder] = message.useMessage();
    const userContext = useContext(UserContext);
    const usedMinutes = userContext?.user?.used_upload_minutes;
    const totalMinutes = userContext?.user?.total_upload_minutes;
    const hasSubscription = !showGoProButton(userContext?.user);
    const ref = useRef(null);
    const navigate = useNavigate();
    const SecondsToISO8601Duration = (seconds: number) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `PT${minutes}M${remainingSeconds}S`;
    };
    const editorTemplate = userContext?.user?.editor_template;
    const showTemplateStep = Boolean(
      editorTemplate && editorTemplate?.subtitles_settings?.alignItems,
    );

    const isGoogleUser = !userContext?.user?.twitch_id;

    const isTemplateAvailable = Boolean(
      editorTemplate && editorTemplate?.subtitles_settings?.alignItems,
    );

    const isFaceTrackingDisabledByPermissions = Boolean(
      !userContext?.user?.permissions ||
      !userContext?.user.permissions?.hd_enabled,
    );

    const steps = mode === 'highlights' ? highlightSteps : transcribeSteps;

    useEffect(() => {
      if (isOpen) {
        postAudit({
          user_action: `MODAL - PROGRESS: Upload modal opened. Video URL: ${url}`,
          user_id: userContext?.user?.id
        });
      }
    }, [isOpen])

    useEffect(() => {
      switch (selectedVideoMode) {
        case VIDEO_MODES.AI_HIGHLIGHTS:
          setMode('highlights');
          handleSubtitlesEnable(true);
          if (hasSubscription) {
            setFaceTrackingEnabled(true);
          }
          break;
        case VIDEO_MODES.ADD_CAPTIONS:
          setMode('transcribe');
          handleSubtitlesEnable(true);
          setFaceTrackingEnabled(false);
          break;
        case VIDEO_MODES.MAGIC_EDIT:
          setMode('transcribe');
          handleSubtitlesEnable(true);
          if (hasSubscription) {
            setFaceTrackingEnabled(true);
          }
          break;
        case VIDEO_MODES.REFRAME:
          setMode('transcribe');
          handleSubtitlesEnable(false);
          if (hasSubscription) {
            setFaceTrackingEnabled(true);
          }
          break;
        case VIDEO_MODES.TRIM_VIDEO:
          setMode('transcribe');
          handleSubtitlesEnable(false);
          setFaceTrackingEnabled(false);
          break;

        default:
          setMode('highlights');
          handleSubtitlesEnable(true);
          if (hasSubscription) {
            setFaceTrackingEnabled(true);
          }
          break;
      }
    }, [selectedVideoMode])

    useEffect(() => {
      if (isTemplateAvailable) {
        handleSelectStylePreset(
          {
            id: 10,
            label: 'Template Styles',
            name: 'template',
            animationName: '',
            availableToAll: false,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style: editorTemplate?.subtitles_settings,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            styleForFrontend: {},
          },
          true,
        );
      }
    }, []);

    useEffect(() => {
      if (localStorageFaceTrackingEnabled) {
        const enabled = JSON.parse(localStorageFaceTrackingEnabled);
        setFaceTrackingEnabled(Boolean(enabled));
      }
    }, []);
    useEffect(() => {
      if (localStorageAutoZoomEnabled) {
        const enabled = JSON.parse(localStorageAutoZoomEnabled);
        setAutoZoomEnabled(Boolean(enabled));
      }
    }, []);

    useEffect(() => {
      completeOnboardingStep('upload_video', userContext?.user);
      onboardingContext?.setRerender(onboardingContext?.rerender + 1);
    }, []);

    useEffect(() => {
      if (isFaceTrackingDisabledByPermissions) {
        setFaceTrackingEnabled(false);
      }
    }, [isFaceTrackingDisabledByPermissions]);

    useEffect(() => {
      setLoadingVideoDetails(true);

      setTimeout(() => {
        setLoadingVideoDetails(false);
      }, 2000);
    }, []);

    const transcribeVideoLimitSeconds = userContext?.user?.permissions
      ?.video_transcribe_limit
      ? userContext?.user?.permissions?.video_transcribe_limit * 60
      : 120;

    useEffect(() => {
      if (mode === 'transcribe') {
        // Show limit only to FREE users
        if (showGoProButton(userContext?.user)) {
          if (uploadedVideoDetails?.videoDuration > transcribeVideoLimitSeconds) {
            handleVideoDurationLimit();

            postAudit({
              user_action: `MODAL - CLOSED: Video reached duration limit. Duration: ${uploadedVideoDetails?.videoDuration}`,
              user_id: userContext?.user?.id
            });
          }
        }
      }
    }, [uploadedVideoDetails]);

    const fetchVideoPreviewDetails = async () => {
      setLoadingVideoDetails(true);

      try {
        if (url) {
          const validYoutubeUrl = isYoutubeUrl(url);
          const validTwitchUrl = isTwitchUrl(url);
          const validVimeoUrl = isVimeoUrl(url);
          const validGoogleDriveUrl = isGoogleDriveUrl(url); // Add a function to check if it's a valid Google Drive URL

          if (validTwitchUrl) {
            setLoading(true);
            setLoadingVideoDetails(true);
            setVideoDetails(null);
            try {
              const response = await axios.get(
                `/streamer/twitch-clip?url=${url}`,
              );
              const video: ITwitchVideo = response?.data;
              if (video) {
                const details = {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  contentDetails: {
                    duration: SecondsToISO8601Duration(video.duration),
                    videoDuration: video.duration,
                  },
                  snippet: {
                    thumbnails: {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      maxres: {
                        url: video.thumbnail_url,
                      },
                    },
                    channelTitle: video.creator_name,
                    title: video.title,
                  },
                };
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setVideoDetails(details);
                setYoutubeVideoDetails(details);
                setDisableMakeClips(false);
              } else {
                // messageApi.error('Video is not valid.', 5);
                // setDisableMakeClips(true);
                setDisableMakeClips(false);
              }
            } catch (error) {
              console.log('Error fetching video preview', error);
              // setDisableMakeClips(true);
              setDisableMakeClips(false);
            }
            setLoading(false);
            setLoadingVideoDetails(false);
          } else if (validYoutubeUrl) {
            setLoading(true);
            setLoadingVideoDetails(true);
            setVideoDetails(null);
            try {
              const response = await axios.get(
                `/streamer/youtube-video?url=${url}`,
              );
              const video: IYoutubeVideo = response?.data;
              if (video && video.contentDetails) {
                setVideoDetails(video);
                setYoutubeVideoDetails(video);
                setDisableMakeClips(false);
              } else {
                // messageApi.error('Video is not valid.', 5);
                // setDisableMakeClips(true);
                setDisableMakeClips(false);
              }
            } catch (error) {
              console.log('Error fetching video preview', error);
              postAudit({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                user_action: `Error fetching youtube video details while uploading. URL: ${url}`,
                user_id: userContext?.user?.id,
              });
              // setDisableMakeClips(true);
              setDisableMakeClips(false);
            }
            setLoading(false);
            setLoadingVideoDetails(false);
          } else if (validVimeoUrl) {
            setLoading(true);
            setLoadingVideoDetails(true);
            setVideoDetails(null);
            try {
              const response = await axios.get(
                `/streamer/vimeo-video?url=${url}`,
              );
              const video: IVimeoVideo = response?.data;
              if (video) {
                const details = {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  contentDetails: {
                    duration: video.duration
                      ? SecondsToISO8601Duration(video.duration)
                      : ' ',
                    videoDuration: video.duration || ' ',
                  },
                  snippet: {
                    thumbnails: {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      maxres: {
                        url: video.pictures?.base_link || ' ',
                      },
                    },
                    channelTitle: video.app?.name || ' ',
                    title: video.user?.name || ' ',
                  },
                };
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setVideoDetails(details); // Set the Vimeo video details here
                setYoutubeVideoDetails(details); // Set the Vimeo video details here
                setDisableMakeClips(false);
              }
            } catch (error) {
              console.log('Error fetching Vimeo video preview', error);
              setDisableMakeClips(false);
            }
            setLoading(false);
            setLoadingVideoDetails(false);
          } else if (validGoogleDriveUrl) {
            setLoading(true);
            setLoadingVideoDetails(true);
            setVideoDetails(null);
            try {
              const response = await axios.get(
                `/streamer/google-drive-video?url=${url}`,
              );
              const video: IGoogleDriveVideo = response?.data;
              const fileExtension = video?.fileExtension;

              const fileType = checkFileType(fileExtension);
              setCurrentFileType(fileType);
              if (video) {
                const details = {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  contentDetails: {
                    duration: video?.videoMediaMetadata.durationMillis
                      ? SecondsToISO8601Duration(
                        parseInt(
                          video.videoMediaMetadata.durationMillis,
                          10,
                        ) / 1000,
                      )
                      : ' ',
                    videoDuration: Math.floor(
                      parseInt(video.videoMediaMetadata.durationMillis, 10) /
                      1000,
                    ),
                  },
                  snippet: {
                    thumbnails: {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      maxres: {
                        url: video?.thumbnailLink,
                      },
                    },
                    channelTitle: video?.title,
                    title: video?.title,
                  },
                };
                // Update with setting Google Drive video details
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setVideoDetails(details);
                setYoutubeVideoDetails(details); // Set the Vimeo video details here
                setDisableMakeClips(false);
              }
            } catch (error) {
              setinValidGoogleDriveLink(true);
              console.log('Error fetching Google Drive video preview', error);
            }
            setLoading(false);
            setLoadingVideoDetails(false);
          } else {
            messageApi.error(
              'Only YouTube, Twitch, Vimeo, and Google Drive links are supported.',
              5,
            );
            setDisableMakeClips(true);
          }
        } else {
          setDisableMakeClips(true);
          postAudit({
            user_action: `MODAL - BLOCKED: No URL provided for video upload`,
            user_id: userContext?.user?.id
          });
        }
      } catch (error) {
        console.error('Error loading preview', error);
        postAudit({
          user_action: `MODAL - FAILED: Error loading preview for video upload`,
          user_id: userContext?.user?.id
        });
        setLoading(false);
        setLoadingVideoDetails(false);
      }
    };

    // const debouncedFetchVideoPreviewDetails = debounce(fetchVideoPreviewDetails, 500);

    useEffect(() => {
      if (
        isYoutubeUrl(url) ||
        isVimeoUrl(url) ||
        isGoogleDriveUrl(url) ||
        isTwitchUrl(url)
      ) {
        const current: any = ref.current;
        if (current) {
          setHeight(current?.clientHeight);
        }
      }
    });

    useEffect(() => {
      setVideoDetails(null);
    }, []);

    useEffect(() => {
      if (
        url &&
        (isYoutubeUrl(url) ||
          isVimeoUrl(url) ||
          isGoogleDriveUrl(url) ||
          isTwitchUrl(url)) &&
        !demoMode
      ) {
        if (url) {
          fetchVideoPreviewDetails();
        } else {
          setVideoDetails(null);
        }
      }
    }, [url, isOpen]);

    const getVideoDuration = () => {
      if (videoDetails) {
        const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
        const matches = videoDetails.contentDetails.duration.match(regex);
        if (matches) {
          const hours = parseInt(matches[1]) || 0;
          const minutes = parseInt(matches[2]) || 0;
          const seconds = parseInt(matches[3]) || 0;
          const totalMinutes = hours * 60 + minutes + seconds / 100;

          return totalMinutes;
        }
      } else if (uploadedVideoDetails?.videoDuration) {
        return Number((uploadedVideoDetails?.videoDuration / 60).toFixed(2)); // Computer video
      } else return null;
    };


    const getChosenDuration = () => {
      return convertSecondsToMinutesFormat(croppedVideoRange[1] - croppedVideoRange[0]);
    }

    const getRemainingMinutes = () => {
      if (totalMinutes && usedMinutes) {
        return totalMinutes - usedMinutes;
      } else if (totalMinutes && usedMinutes === 0) {
        return totalMinutes;
      }
      return null;
    };

    const getCreditsAfter = () => {
      return Number((getRemainingMinutes()! - getChosenDuration()!).toFixed(2));
    };

    const checkIfGenerateDisabled = () => {
      if (getCreditsAfter() < 0) return true;
      if (showGoProButton(userContext?.user)) {
        if (mode === 'transcribe' && fullDurationInSeconds > maxTranscribeDuration) {
          return true;
        }
      }
      return false;
    }

    const checkIfCreditsAreInsufficient = () => {
      const remainingMinutes = getRemainingMinutes();
      const videoDuration = getVideoDuration();
      if (remainingMinutes && videoDuration) {
        if (remainingMinutes < videoDuration) {
          return true;
        } else return false;
      } else {
        return false;
      }
    };

    const roundEstimatedDuration = (durationMinutes: number) => {
      const rounded = Math.ceil(durationMinutes / 3);
      if (rounded === 0) return 1;
      return rounded;
    };

    const gifUrls = [StyleOneGif, StyleTwoGif, StyleThreeGif, StyleFourGif];
    const styles = useMemo(() => {
      return {
        container: {
          padding: 20,
          display: 'flex',
          backgroundColor: COLORS.GRAY_BACKGROUND,
          borderRadius: '0px 0px 8px 8px',
          gap: '26px',
          maxWidth: 1070,
          width: 'fit-content',
          margin: '0 auto',
          marginBottom: 28,
          marginTop: -10,
        },
        contentWrapper: {
          display: 'flex',
          flexDirection: 'column',
          gap: '13px',
          minWidth: 425,
          maxWidth: 425,
          height: 'fit-content',
        },
        channelName: {
          fontSize: 20,
          fontWeight: 400,
          color: COLORS.SUBTEXT_GRAY_LIGHT,
          lineHeight: '29px',
        },
        item: {
          display: 'flex',
        },
        propertyName: {
          fontSize: 16,
          fontWeight: 400,
          color: COLORS.SUBTEXT_GRAY_LIGHT,
          lineHeight: '28px',
          minWidth: 185,
        },
        propertyValue: {
          fontSize: 16,
          fontWeight: 400,
          color: COLORS.GRAY_DISABLED,
          lineHeight: '28px',
        },
        chipPropertyValue: {
          fontSize: 18,
          fontWeight: 400,
          color: COLORS.WHITE,
          backgroundColor: COLORS.CHIP_BACKGROUND,
          padding: '3px 6px',
          lineHeight: '28px',
          borderRadius: 5,
        },
        creditsText: {
          fontSize: 14,
          fontWeight: 400,
          color: COLORS.SUBTEXT_GRAY_LIGHT,
          lineHeight: '28px',
          width: 185,
        },
        invalidGoogleLink: {
          fontSize: 16,
          fontWeight: 'bold',
          color: '#ffd700',
          textDecoration: 'underline',
        },
      } as const;
    }, []);

    const renderImage = () => {
      return (
        videoDetails?.snippet?.thumbnails?.maxres?.url ||
        videoDetails?.snippet?.thumbnails?.standard?.url ||
        videoDetails?.snippet?.thumbnails?.default?.url
      );
    };

    const renderChannel = () => {
      if (demoMode) return demoVideoPreview.channelName;
      else return videoDetails?.snippet?.channelTitle;
    };

    const renderVideoName = () => {
      if (demoMode) return demoVideoPreview.videoName;
      else {
        return videoDetails?.snippet?.title;
      }
    };

    const renderViews = () => {
      if (demoMode) return demoVideoPreview.viewsCount;
      else return Number(videoDetails?.statistics?.viewCount).toLocaleString();
    };

    const renderDuration = () => {
      if (demoMode) return demoVideoPreview.duration;
      if (isYoutubeUrl(url)) {
        if (getVideoDuration()) return getVideoDuration();
        else return (uploadedVideoDetails?.videoDuration / 60).toFixed(2);
      } else {
        if (uploadedVideoDetails?.videoDuration) {
          const mins = Math.floor(uploadedVideoDetails?.videoDuration / 60);
          const secs = Math.floor(uploadedVideoDetails?.videoDuration % 60);
          return `${mins}.${secs.toString().padStart(2, '0')}`;
        }
        else if (getVideoDuration()) return getVideoDuration();
        else return 0;
      }
    };

    const fullDurationInSeconds = convertTotalDurationToSeconds(renderDuration());
    const maxTranscribeDuration = showGoProButton(userContext?.user) ? 120 : 300;

    useEffect(() => {
      if (mode === 'highlights') {
        setCroppedVideoRange([0, fullDurationInSeconds]);
      } else {
        if (fullDurationInSeconds <= maxTranscribeDuration) {
          setCroppedVideoRange([0, fullDurationInSeconds]);
        } else {
          if (showGoProButton(userContext?.user)) {
            setCroppedVideoRange([0, maxTranscribeDuration]);
          } else {
            setCroppedVideoRange([0, maxTranscribeDuration]);
          }
        }
      }
    }, [fullDurationInSeconds, mode])

    const renderViralClipsEstimation = () => {
      if (demoMode) return demoVideoPreview.viralClipsEstimation;
      else {
        const duration = getVideoDuration();
        if (typeof duration === 'number') {
          const roundedDuration = roundEstimatedDuration(duration);
          return `${roundedDuration} - ${roundedDuration * 3} Viral Clips`;
        }
      }
    };

    const handlePrevStep = () => {
      setCurrentStepIndex((step) => step - 1);
    };

    const handleNextStep = () => {
      // Template step
      if (currentStepIndex === 1) {
        // if (showTemplateStep) {
        saveUpdatedTemplate();
        // }
      }
      setCurrentStepIndex((step) => step + 1);
    };

    const saveUpdatedTemplate = () => {
      const data = {
        editor_template: editorTemplate,
      };
      axios
        .put('/streamer/update-user-editor-template', data)
        .catch((error: { response: any }) => {
          if (error.response) {
            console.log(error.response);
          }
        });
    };

    const resetAllChosenValues = () => {
      setCurrentStepIndex(0);
      setSelectedLayoutType('desktop');
      setSelectedLength(-1);
      setSelectedStylePreset(stylePresets[0]);
      setCroppedVideoRange([0, 0]);
    };

    const handleGenerateClips = () => {
      if (showGoProButton(userContext?.user)) {
        if (mode === 'transcribe' && fullDurationInSeconds > maxTranscribeDuration) {
          message.error('This feature only supports processing videos up to 5 minutes in length. To select a time range and increase the limit to 5 minutes, please upgrade to a Pro account.', 8);
          return null;
        }
      }
      if (getCreditsAfter() < 0) {
        messageApi.error('You do not have enough credits.', 5);
        return null;
      }

      if (numberOfProjects <= 1) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event_name: '1st_upload',
          event: 'fireEvent',
          first_name: userContext?.user?.firstname,
          last_name: userContext?.user?.lastname,
          email: userContext?.user?.email,
          country: userContext?.user?.country,
        });
      }


      if (!isFaceTrackingDisabledByPermissions) {
        localStorage.setItem(
          'faceTrackingEnabledPreference',
          JSON.stringify(faceTrackingEnabled),
        );
        localStorage.setItem(
          'autoZoomEnabledPreference',
          JSON.stringify(autoZoomEnabled),
        );
      }

      // ---- Save Template ----
      const editorTemplate = userContext?.user?.editor_template;
      const data = {
        editor_template: editorTemplate,
      };

      axios
        .put('/streamer/update-user-editor-template', data)
        .catch((error: { response: any }) => {
          if (error.response) {
            console.log(error.response);
          }
        });
      // ---------
      completeOnboardingStep('generate_clips', userContext?.user);
      onboardingContext?.setRerender(onboardingContext?.rerender + 1);
      if (mode === 'highlights') {
        handleExtractHighlights(
          editorTemplate!,
          autoZoomEnabled,
          selectedLength,
          selectedStylePreset.style,
          selectedLayoutType,
          currentFileType,
          magicLookText,
          faceTrackingEnabled,
          selectedLanguage.value,
          userContext?.user?.editor_template?.feature_sliders,
          croppedVideoRange
        ); // TODO: pass other new values (e.g. Desktop/Mobile, Style preset)
      } else {
        handleTranscribe(
          editorTemplate!,
          autoZoomEnabled,
          selectedLength,
          selectedStylePreset.style,
          selectedLayoutType,
          currentFileType,
          magicLookText,
          faceTrackingEnabled,
          selectedLanguage.value,
          userContext?.user?.editor_template?.feature_sliders,
          croppedVideoRange
        ); // TODO: pass other new values (e.g. Desktop/Mobile, Style preset)
      }
      handleCloseModal();
      setTimeout(() => {
        resetAllChosenValues();
      }, 2000);
    };

    const handleGetMinutes = () => {
      localStorage.setItem('cta_source', 'go-pro-get-minutes')
      navigate(getPricingRedirectUrl(userContext?.user));
    };

    const handleChooseLength = (value: number) => {
      const userPermissions = userContext?.user?.permissions;

      if (userPermissions && userPermissions.clip_length_request_enabled) {
        setSelectedLength(value);
      } else if (hasSubscription) {
        setSelectedLength(value);
      } else {
        setShowPricingModal(true);
        // navigate('/subscriptions');
      }
    };

    const handleGoProClick = () => {
      if (hasSubscription) {
        navigate('/dashboard');
      } else {
        setShowPricingModal(true);
      }
    };

    const handleCloseMainModal = () => {
      setVideoDetails(null);
      handleCloseModal();
      resetAllChosenValues();
      clearUrlField();
    };

    const handleClosePricingModal = () => {
      setShowPricingModal(false);
    };

    const checkProBlockedStylePreset = (preset: typeof stylePresets[0]) => {
      if (preset.name === 'Word by Word' || preset.name === 'Sentence')
        return false;
      if (
        userContext?.user?.permissions?.animated_captions &&
        userContext?.user?.permissions?.animated_captions[0] === 'ALL'
      ) {
        return false;
      } else {
        return true;
      }
    };

    const handleSelectStylePreset = (
      preset: typeof stylePresets[0],
      allowForAllUsers?: boolean,
    ) => {
      setSelectedStylePreset(preset);
    };

    const handleMagicLookInputChange = (text: string) => {
      setMagicLookText(text);
    };

    const handleShowPricingModal = () => {
      if (showGoProButton(userContext?.user)) {
        setShowPricingModal(true);
      } else {
        navigate('/dashboard');
      }
    };

    const updateSelectedLanguage = (language: any) => {
      setSelectedLanguage(language);
    };

    const handleClickAutoZoomSwitch = () => {
      if (isFaceTrackingDisabledByPermissions) {
        setShowPricingModal(true);
      } else {
        setAutoZoomEnabled(!autoZoomEnabled);
      }
    };

    const toggleMode = () => {
      if (mode === 'highlights') {
        setMode('transcribe');
      } else {
        setMode('highlights');
      }
    }


    const handleTemplateFieldChange = (fieldName: string, value: string | boolean | any[], addToRoot?: boolean) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const templateCopy: IEditorTemplate = { ...editorTemplate };
      const subtitlesSettingsCopy = templateCopy?.subtitles_settings;
      if (subtitlesSettingsCopy) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        subtitlesSettingsCopy[fieldName] = value;
        templateCopy.subtitles_settings = subtitlesSettingsCopy;

        if (addToRoot) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          templateCopy[fieldName] = value;
        }
        const user = userContext!.user;
        user!.editor_template = templateCopy;

        userContext?.setUser(user!);
        // setForceRender(prevState => !prevState);
      }

    }

    const handleSubtitlesEnable = (value: boolean) => {
      handleTemplateFieldChange('subtitles_enabled', value);
    };

    const handleChangeVideoMode = (mode: string) => {
      setSelectedVideoMode(mode);
    }

    const renderedVideoDuration = renderDuration();

    const LayoutStep = () => (
      <div className="block-wrapper" style={{ marginBottom: 20 }}>
        <div className="title-wrapper">
          <div className="section-title">Select your layout</div>
          <div className="section-subtitle">
            Choose on the right the format you would like your video to be
            edited
          </div>
        </div>
        <div className="layout-section">
          <div className="layout-ratios">
            <div>
              <Image width={284} src={LayoutDesktop} preview={false} />
              <div className="layout-subtext">Desktop version 16:9</div>
            </div>
            <div>
              <Image
                wrapperStyle={{ marginBottom: -7 }}
                width={141}
                src={LayoutMobile}
                preview={false}
              />
              <div className="layout-subtext">Mobile version 9:16</div>
            </div>
          </div>
          <div className="mobile-layout-description">
            Your videos will be auto-edited with the format you choose below.
          </div>
          <div className="layout-choose-ratio">
            <div className="layout-options-wrapper">
              <div
                className={`layout-option ${selectedLayoutType === 'desktop' && 'active'
                  }`}
                onClick={() => setSelectedLayoutType('desktop')}
              >
                <IconDesktop
                  style={{ height: 153, width: 164 }}
                  className="layout-option-icon"
                />
                <div className="layout-option-button">Desktop</div>
              </div>
              <div
                className={`layout-option ${selectedLayoutType === 'mobile' && 'active'
                  }`}
                onClick={() => setSelectedLayoutType('mobile')}
              >
                <IconMobile
                  style={{ height: 153, width: 89 }}
                  className="layout-option-icon"
                />
                <div className="layout-option-button">Mobile</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (showPricingModal)
      return (
        <Modal isOpen={isOpen} className="Modal" overlayClassName="Overlay">
          <div className="modal-pricing">
            <Image
              onClick={handleClosePricingModal}
              wrapperStyle={{
                position: 'absolute',
                right: '20px',
                top: '15px',
                cursor: 'pointer',
                zIndex: 4,
              }}
              src={IconCloseCircle}
              preview={false}
              width={32}
            />
            <div className="modal-pricing__left">
              <div className="left-title">
                Try now the best{' '}
                <span style={{ color: 'var(--bg-brand-primary)' }}>PRO features</span>
              </div>
              <PricingCard
                style={{ maxWidth: '100%' }}
                className="cursor-pointer"
                onClick={() => navigate('/subscriptions')}
              />
              <div
                className="comparisons-and-faq"
                onClick={() => navigate('/subscriptions')}
              >
                Comparisions & FAQ
              </div>
            </div>
            <div className="modal-pricing__right">
              <Image src={PricingModalImage} preview={false} />
              <div className="right-text-container">
                <div className="right-text-container__title">
                  AI-Generated B-Roll
                </div>
                <div className="right-text-container__description">
                  Enhance your videos with AI-powered B-roll for better
                  storytelling and visual appeal, featuring customizable
                  background footage.
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );

    return (
      <Modal isOpen={isOpen} className="Modal modal-content-upload-steps" overlayClassName="Overlay overlay-upload-steps">
        <div className="modal-main">
          {contextHolder}
          <IconClose onClick={handleCloseMainModal} style={{
            position: 'absolute',
            right: '7px',
            top: '1px',
            cursor: 'pointer',
            width: '12px'
          }} />
          {/* Different indexes for Transcribe flow (miss Style step) */}
          {steps[currentStepIndex].id === 'video' && (
            <VideoStep
              mode={mode}
              autoZoomEnabled={autoZoomEnabled}
              setAutoZoomEnabled={setAutoZoomEnabled}
              selectedLanguage={selectedLanguage}
              updateSelectedLanguage={updateSelectedLanguage}
              faceTrackingEnabled={faceTrackingEnabled}
              setFaceTrackingEnabled={setFaceTrackingEnabled}
              loadingVideoDetails={loadingVideoDetails}
              renderImage={renderImage}
              renderVideoName={renderVideoName}
              renderChannel={renderChannel}
              renderViews={renderViews}
              renderedVideoDuration={renderedVideoDuration}
              uploadedVideoDetails={uploadedVideoDetails}
              videoDetails={videoDetails}
              handleMagicLookInputChange={handleMagicLookInputChange}
              handleShowPricingModal={handleShowPricingModal}
              hideGetMinutesButton={false}
              getRemainingMinutes={getRemainingMinutes}
              handleGetMinutes={handleGetMinutes}
              getCreditsAfter={getCreditsAfter}
              renderedDuration={renderDuration()}
              croppedVideoRange={croppedVideoRange}
              setCroppedVideoRange={setCroppedVideoRange}
              handleNextStep={handleNextStep}
              toggleMode={toggleMode}
              handleChangeVideoMode={handleChangeVideoMode}
              selectedVideoMode={selectedVideoMode}
            />
          )}
          {steps[currentStepIndex].id === 'layout' && <LayoutStep />}
          {steps[currentStepIndex].id === 'style' && (
            <StyleStep
              videoDuration={uploadedVideoDetails?.videoDuration}
              handleSelectStylePreset={handleSelectStylePreset}
              selectedStylePreset={selectedStylePreset}
              faceTrackingEnabled={faceTrackingEnabled}
              autoZoomEnabled={autoZoomEnabled}
              handleClickAutoZoomSwitch={handleClickAutoZoomSwitch}
              handleChooseLength={handleChooseLength}
              selectedLength={selectedLength}
              handleBack={handlePrevStep}
              mode={mode}
            />
          )}
          {steps[currentStepIndex].id === 'finalTune' && (
            <FinalStep
              getCreditsAfter={getCreditsAfter}
              handleChooseLength={handleChooseLength}
              selectedLength={selectedLength}
              handleMagicLookInputChange={handleMagicLookInputChange}
              handleShowPricingModal={handleShowPricingModal}
            />
          )}
          {/* {currentStepIndex === 1 && <LayoutStep />}
        {currentStepIndex === 2 && <StyleStep />}
        {currentStepIndex === 3 && <FinalStep />} */}

          <button onClick={handleGenerateClips} className={`generate-button ${checkIfGenerateDisabled() && 'disabled'}`}>
            Generate
          </button>
          {/* <div className="bottom-navigation">
            {currentStepIndex !== 0 ? (
              <div onClick={handlePrevStep} className="back-button">
                Back
              </div>
            ) : (
              <div />
            )}
            {currentStepIndex !== steps.length - 1 ? (
              <div onClick={handleNextStep} className="next-button">
                Next
              </div>
            ) : (
              <div
                style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
              >
                {getCreditsAfter() < 0 && (
                  <div
                    className="cursor-pointer go-pro-button"
                    onClick={handleGoProClick}
                  >
                    <span>{hasSubscription ? 'Get minutes' : 'Go Pro'}</span>
                    <RightArrow />
                  </div>
                )}
                <div
                  onClick={handleGenerateClips}
                  className={`generate-button ${getCreditsAfter() < 0 && 'disabled'
                    }`}
                >
                  Generate Clips
                </div>
              </div>
            )}
          </div> */}
        </div>
      </Modal>
    );
  },
);

UploadSteps.displayName = 'UploadSteps';

export default UploadSteps;

interface IAvailableCreditsBoxProps {
  hideGetMinutesButton?: boolean;
  getRemainingMinutes: () => any;
  handleGetMinutes: () => any;
  getCreditsAfter: () => any;
}

const AvailableCreditsBox = ({
  hideGetMinutesButton,
  getRemainingMinutes,
  handleGetMinutes,
  getCreditsAfter,
}: IAvailableCreditsBoxProps) => (
  <div className="available-credits-box">
    <div className="available-credits-text">
      Available credits: {getRemainingMinutes()?.toFixed(2)} minutes
    </div>
    <div className="available-credits-text">
      Available credits after:{' '}
      <span className={getCreditsAfter() >= 0 ? 'green' : 'red'}>
        {getCreditsAfter()} minutes
      </span>
    </div>
    {getCreditsAfter() < 0 && !hideGetMinutesButton && (
      <div onClick={handleGetMinutes} className="get-minutes">
        Get minutes
      </div>
    )}
  </div>
);

interface IVideoStepProps {
  loadingVideoDetails: boolean;
  faceTrackingEnabled: boolean;
  autoZoomEnabled: boolean;
  selectedLanguage: { label: string; value: string | null };
  updateSelectedLanguage: (lang: any) => void;
  setFaceTrackingEnabled: (value: boolean) => void;
  setAutoZoomEnabled: (value: boolean) => void;
  renderImage: () => any;
  renderVideoName: () => any;
  renderChannel: () => any;
  renderViews: () => any;
  hideGetMinutesButton: boolean;
  renderedVideoDuration: any;
  uploadedVideoDetails: any;
  videoDetails: any;
  handleMagicLookInputChange: (text: string) => void;
  handleShowPricingModal: () => void;
  getRemainingMinutes: () => any;
  handleGetMinutes: () => any;
  getCreditsAfter: () => any;
  mode: 'highlights' | 'transcribe';
  toggleMode: () => void;
  renderedDuration: number | string | null | undefined;
  croppedVideoRange?: [number, number];
  setCroppedVideoRange: (range: [number, number]) => void;
  handleNextStep: () => void;
  handleChangeVideoMode: (mode: string) => void;
  selectedVideoMode: string;
}

const VideoStep = ({
  loadingVideoDetails,
  autoZoomEnabled,
  selectedLanguage,
  faceTrackingEnabled,
  mode,
  toggleMode,
  setAutoZoomEnabled,
  updateSelectedLanguage,
  setFaceTrackingEnabled,
  renderImage,
  handleShowPricingModal,
  handleMagicLookInputChange,
  renderVideoName,
  renderChannel,
  handleGetMinutes,
  renderViews,
  getRemainingMinutes,
  getCreditsAfter,
  hideGetMinutesButton,
  renderedVideoDuration,
  videoDetails,
  uploadedVideoDetails,
  renderedDuration,
  croppedVideoRange,
  setCroppedVideoRange,
  handleNextStep,
  handleChangeVideoMode,
  selectedVideoMode
}: IVideoStepProps) => {

  const [showMagicLookInput, setShowMagicLookInput] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);

  const userContext = useContext(UserContext);
  const isFreeUser = showGoProButton(userContext?.user);

  const fullDurationInSeconds = convertTotalDurationToSeconds(renderedDuration);

  const formatTime = (seconds: number): string => {
    const flooredSeconds = Math.floor(seconds);
    const mins = Math.floor(flooredSeconds / 60).toString().padStart(2, '0');
    const secs = (flooredSeconds % 60).toString().padStart(2, '0');
    return `${mins}:${secs}`;
  };

  // Update range state and formatted text
  const handleSliderChange = (value: [number, number]) => {
    const [start, end] = value;
    const maxDuration = mode === 'transcribe' ? isFreeUser ? 120 : 300 : fullDurationInSeconds;
    const currentDuration = end - start;

    // If the selected range is within allowed bounds
    if (currentDuration <= maxDuration && currentDuration >= 10) {
      setCroppedVideoRange([start, end]);
    }
    // If the user tries to extend beyond maxDuration, shift the range to the right
    else if (currentDuration > maxDuration) {
      setCroppedVideoRange([end - maxDuration, end]);
    }
    // If maximum duration is hit and user tries to extend the end handle, shift the range
    else if (currentDuration === maxDuration && end > start + maxDuration) {
      const newEnd = Math.min(end, fullDurationInSeconds);
      const newStart = newEnd - maxDuration;
      setCroppedVideoRange([newStart, newEnd]);
    }
    // Ensure the range meets the minimum duration
    else if (currentDuration < 10) {
      setCroppedVideoRange([start, start + 10]);
    }
  };

  const userPermissions = userContext?.user?.permissions;
  const isFaceTrackingDisabledByPermissions = Boolean(
    !userContext?.user?.permissions ||
    !userContext?.user.permissions?.hd_enabled,
  );
  const { width } = useWindowDimensions();
  const isMobile = width < 920;
  const navigate = useNavigate();

  const handleMagicLookButtonClick = () => {
    if (userPermissions && userPermissions.clip_length_request_enabled) {
      setShowMagicLookInput(true);
    } else {
      handleShowPricingModal();
    }
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <DropdownIndicatorIcon />
      </components.DropdownIndicator>
    );
  };

  const handleClickFaceTrackingSwitch = () => {
    localStorage.setItem('cta_source', 'go-pro-face-tracking');
    if (isFaceTrackingDisabledByPermissions) {
      setShowPricingModal(true);
    } else {
      setFaceTrackingEnabled(!faceTrackingEnabled);
    }
  };

  const handleClickAutoZoomSwitch = () => {
    if (isFaceTrackingDisabledByPermissions) {
      setShowPricingModal(true);
    } else {
      setAutoZoomEnabled(!autoZoomEnabled);
    }
  };

  const handleClosePricingModal = () => {
    setShowPricingModal(false);
  };

  const handleSearchVideoClick = () => {
    localStorage.setItem('cta_source', 'go-pro-search-moment');
    if (showGoProButton(userContext?.user)) {
      handleShowPricingModal();
    } else {
      setShowMagicLookInput(true);
    }
  }

  const showAlternativeUiByUser = (user: any) => {
    return false; // TEMPORARY TO REVERT ALTERNATIVE UI
  }

  // const disableTranscribeModeForLongVideos = useMemo(() => {
  //   if (isFreeUser && fullDurationInSeconds > 120) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }, [fullDurationInSeconds, isFreeUser]);
  const disableTranscribeModeForLongVideos = false;

  return (
    <div className='main-screen-container'>
      <PricingModal
        isOpen={showPricingModal}
        handleClosePricingModal={handleClosePricingModal}
      />
      <div>
        {loadingVideoDetails ? (
          <div
            style={{ minWidth: 300, marginBottom: 70, marginTop: 20, minHeight: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            className="video-preview-wrapper"
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 60, color: COLORS.PRIMARY }}
                  spin
                />
              }
            />
          </div>
        ) : (
          <div className="video-section">
            <div className='thumbnail-container'>
              <img
                style={{ borderRadius: '8px', maxHeight: 375, width: 'auto', height: '100%', margin: '0 auto', maxWidth: '100%' }}
                src={renderImage() || UploadFromComputerImage}
              />
            </div>

            <div>
              <div className="video-section__header">
                <div className="video-section__title">{renderVideoName() || uploadedVideoDetails?.videoTitle}</div>
                <div className="video-section__meta">
                  {renderChannel() && (
                    <>
                      <div className="video-section__meta-item">
                        <span className="video-section__meta-title">Channel: </span>
                        <span className="video-section__meta-text">{isMobile ? truncateText(renderChannel(), 35) : renderChannel()}</span>
                      </div>
                      <div className="video-section__meta-divider" />
                    </>
                  )}
                  <div className="video-section__meta-item">
                    <span className="video-section__meta-title">Duration: </span>
                    <span className="video-section__meta-text">
                      {typeof renderedVideoDuration === 'number' &&
                        renderedVideoDuration
                        ? renderedVideoDuration?.toFixed(2)
                        : renderedVideoDuration}
                      min
                    </span>
                  </div>

                  {videoDetails?.statistics?.viewCount && (
                    <>
                      <div className="video-section__meta-divider" />
                      <div className="video-section__meta-item">
                        <span className="video-section__meta-title">Views: </span>
                        <span className="video-section__meta-text">{renderViews()}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="video-section__subheader">
                <div className="language-dropdown__container">
                  <Select
                    className="language-select"
                    classNamePrefix="language-select-prefix"
                    value={selectedLanguage}
                    onChange={(result) => updateSelectedLanguage(result)}
                    options={supportedLanguages}
                    components={{ DropdownIndicator }}
                  />
                </div>

                <button onClick={handleNextStep} className="video-section__style-button">{isMobile ? 'Settings' : 'Style & Settings'}</button>
              </div>
            </div>

            {showAlternativeUiByUser(userContext?.user) && (
              <div className='video-section__video-modes'>
                <Tooltip overlayClassName="tooltip one-line face-tracking__tooltip" title="Turn long video into social-ready clips.">
                  <button onClick={() => handleChangeVideoMode(VIDEO_MODES.AI_HIGHLIGHTS)} className={`video-section__video-mode-button ${selectedVideoMode === VIDEO_MODES.AI_HIGHLIGHTS && 'active'}`}>AI Highlights</button>
                </Tooltip>
                <Tooltip overlayClassName="tooltip one-line face-tracking__tooltip" title="Enhance your video with captions.">
                  <button onClick={() => handleChangeVideoMode(VIDEO_MODES.ADD_CAPTIONS)} className={`video-section__video-mode-button ${selectedVideoMode === VIDEO_MODES.ADD_CAPTIONS && 'active'}`}>Add Captions</button>
                </Tooltip>
                <Tooltip overlayClassName="tooltip one-line face-tracking__tooltip" title="Add captions and reframe your video.">
                  <button onClick={() => handleChangeVideoMode(VIDEO_MODES.MAGIC_EDIT)} className={`video-section__video-mode-button ${selectedVideoMode === VIDEO_MODES.MAGIC_EDIT && 'active'}`}>Magic Edit</button>
                </Tooltip>
                <Tooltip overlayClassName="tooltip one-line face-tracking__tooltip" title="Adjust your video to fit any aspect ratio.">
                  <button onClick={() => handleChangeVideoMode(VIDEO_MODES.REFRAME)} className={`video-section__video-mode-button ${selectedVideoMode === VIDEO_MODES.REFRAME && 'active'}`}>Reframe</button>
                </Tooltip>
                <Tooltip overlayClassName="tooltip one-line face-tracking__tooltip" title="Cut your video - start, middle, or end.">
                  <button onClick={() => handleChangeVideoMode(VIDEO_MODES.TRIM_VIDEO)} className={`video-section__video-mode-button ${selectedVideoMode === VIDEO_MODES.TRIM_VIDEO && 'active'}`}>Trim Video</button>
                </Tooltip>
              </div>
            )}

            <div>
              <div className="video-section__slider-box">
                {fullDurationInSeconds && croppedVideoRange &&
                  <>
                    <div className="video-section__slider-time">
                      <div className='selected-time-range'>
                        <div className='selected-time-range-text'>
                          Selected time range: <span className='bold'>{formatTime(croppedVideoRange[0])} - {formatTime(croppedVideoRange[1])}</span> {mode === 'transcribe' && <span style={{ color: 'var(--text-subdued)' }}> (max {isFreeUser ? 2 : 5} minutes)</span>}
                        </div>
                        {showGoProButton(userContext?.user) &&
                          <Tooltip overlayClassName="tooltip face-tracking__tooltip" title="Time selection is a Pro feature. Upgrade to unlock this functionality.">
                            <div onClick={() => (localStorage.setItem('cta_source', 'selected-time-ranges'), handleShowPricingModal())} className='upgrade-button-tag'>PRO</div>
                          </Tooltip>
                        }
                      </div>
                    </div>
                    <div className='video-cut-slider-wrapper'>
                      <div
                        onClick={showGoProButton(userContext?.user) ? () => (localStorage.setItem('cta_source', 'selected-time-ranges'), handleShowPricingModal()) : () => null}
                        id='get-minutes-slider'>
                        <Slider
                          min={0}
                          max={fullDurationInSeconds}
                          step={1}
                          value={croppedVideoRange}
                          range={{ draggableTrack: true }}
                          defaultValue={croppedVideoRange}
                          disabled={showGoProButton(userContext?.user)}
                          tooltip={{
                            formatter: (value) => formatTime(value || 0),
                          }}
                          onChange={handleSliderChange}
                        />
                      </div>
                    </div>
                  </>
                }
                <div className="video-section__slider-available-after">
                  <div className="available-credits-text">
                    Available credits after:{' '}
                    <span className={getCreditsAfter() >= 0 ? 'green' : 'red'}>
                      {getCreditsAfter()} minutes
                    </span>
                  </div>
                  {getCreditsAfter() < 0 && <div onClick={() => {
                    localStorage.setItem('cta_source', 'go-pro-get-minutes');
                    handleShowPricingModal();
                  }} className='upgrade-button-tag'>Get Minutes</div>}
                </div>
              </div>

              <div className="video-section__footer">
                {!showAlternativeUiByUser(userContext?.user) &&
                  <div className="video-section__footer-box">

                    <div className={`face-switch no-face ${disableTranscribeModeForLongVideos && 'disabled'}`}>
                      <div className="check">
                        <input
                          disabled={disableTranscribeModeForLongVideos}
                          checked={mode === 'highlights'}
                          onChange={toggleMode}
                          id="auto-zoom-switch-check"
                          type="checkbox"
                        />
                        <label htmlFor="auto-zoom-switch-check"></label>
                      </div>
                    </div>

                    <span>AI Highlights</span>

                    <Tooltip
                      overlayClassName="tooltip face-tracking__tooltip"
                      title={disableTranscribeModeForLongVideos ? "When AI highlights are turned off, free plan videos are limited to 2 minutes, while paid plans allow up to 5 minutes" : "Automatically create social-media-ready highlights from your long video"}
                    >
                      <IconInfo className='info-tooltip' />
                    </Tooltip>
                  </div>
                }

                {isMobile ? null :
                  <div onClick={handleSearchVideoClick} style={{ maxWidth: 205 }} className={`video-section__footer-box box-button ${mode === 'transcribe' && 'disabled'}`}>
                    <span>Search Moments</span>

                    {showGoProButton(userContext?.user) ?
                      <div className='upgrade-button-tag'>PRO</div>
                      :
                      <Tooltip
                        overlayClassName="tooltip face-tracking__tooltip"
                        title="Quickly search for topics or emotions within the video using a simple prompt."
                      >
                        <IconInfo className='info-tooltip' />
                      </Tooltip>
                    }
                  </div>
                }

                <div style={showAlternativeUiByUser(userContext?.user) ? { flex: 'unset' } : {}} className="video-section__footer-box">

                  <div className="face-switch">
                    <div className="check">
                      <input
                        checked={faceTrackingEnabled}
                        onChange={handleClickFaceTrackingSwitch}
                        id="face-switch-check"
                        type="checkbox"
                      />
                      <label htmlFor="face-switch-check"></label>
                    </div>
                  </div>

                  <span>Face Tracking</span>

                  {showGoProButton(userContext?.user) ?
                    <div className='upgrade-button-tag'>PRO</div>
                    :
                    <Tooltip
                      overlayClassName="tooltip face-tracking__tooltip"
                      title="This toggle allows you to control whether to activate Face Tracking for your video. Not every video will benefit from it. Have fun!"
                    >
                      <IconInfo className='info-tooltip' />
                    </Tooltip>
                  }
                </div>
              </div>

              {mode === 'highlights' && showMagicLookInput && (
                <>
                  <div className="magic-look-input-wrapper">
                    <AIGeneratorTextArea
                      handleAiInputChange={handleMagicLookInputChange}
                      placeholder="Insert a prompt to look for something specific in the video"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface IFinalStepProps {
  getCreditsAfter: () => any;
  handleChooseLength: (value: any) => void;
  selectedLength: any;
  handleMagicLookInputChange: (text: string) => void;
  handleShowPricingModal: () => void;
}

const FinalStep = ({
  getCreditsAfter,
  handleChooseLength,
  selectedLength,
  handleMagicLookInputChange,
  handleShowPricingModal,
}: IFinalStepProps) => {
  return (
    <div className="block-wrapper" style={{ marginBottom: 30 }}>
      <div
        className={`title-wrapper ${getCreditsAfter() < 0 && 'final-available-credits-wrapper'
          }`}
      >
        <div className="section-title">Last step</div>
        {/* {getCreditsAfter() < 0 &&
          <AvailableCreditsBox hideGetMinutesButton />
        } */}
      </div>
      <div className="final-section">
        <div className="final-description">
          <div className="pro-feature-text">
            Pro Feature <IconStar />
          </div>
          <div className="preferred-length-text">
            Choose your preferred clip length
          </div>
        </div>
        <div className="length-options">
          {lengthOptions.map((option) => (
            <div
              key={option.value}
              onClick={() => (localStorage.setItem('cta_source', 'go-pro-clip-time'), handleChooseLength(option.value))}
              className={`length-pill ${option.value === selectedLength && 'active'
                }`}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
