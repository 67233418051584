import { Checkbox, Image, Radio, Spin, Switch, Typography, message } from 'antd';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { COLORS } from '../../themes/colors';
import Select from 'react-select';

import axios from 'axios';
import { UserContext } from '../../data/userContext';
import { ReactComponent as IconCollapse } from '../../assets/collapse-share.svg';
import { ReactComponent as IconLinkedin } from '../../assets/linkedin.svg';
import { ReactComponent as IconFacebook } from '../../assets/social_facebook.svg';
import { ReactComponent as IconInstagram } from '../../assets/instagram.svg';
import { ReactComponent as IconTiktok } from '../../assets/tiktok.svg';
import { ReactComponent as IconYoutubeShorts } from '../../assets/youtube-shorts.svg';
import { ReactComponent as IconPinterest } from '../../assets/pinterest.svg';
import { ReactComponent as IconReddit } from '../../assets/reddit.svg';
import { ReactComponent as IconDisconnect } from '../../assets/disconnect.svg';
import { ReactComponent as IconConnect } from '../../assets/connect.svg';
import { ReactComponent as IconSchedule } from '../../assets/schedule.svg';
import { ReactComponent as IconInfoCircle } from '../../assets/info-circle.svg';
import { ReactComponent as IconInfo } from '../../assets/info-green.svg';
import { ReactComponent as IconCopySVG } from '../../assets/icon-copy.svg';

import IconShareTwitter from '../../assets/icon-twitter-waitinglist.svg';
import IconShareTelegram from '../../assets/icon-telegram-waitinglist.svg';
import IconShareFacebook from '../../assets/icon-facebook-waitinglist.svg';
import IconShareGmail from '../../assets/icon-gmail-waitinglist.svg';
import IconShareWhatsapp from '../../assets/icon-whatsapp-waitinglist.svg';
import IconCopy from '../../assets/icon-copy.svg';


import IconClose from '../../assets/modal-close.svg';
import Modal from 'react-modal';
import './ShareModal.less';
import { Collapse } from 'react-collapse';
import TextArea from 'antd/lib/input/TextArea';
import { DtPicker } from 'react-calendar-datetime-picker';
import 'react-calendar-datetime-picker/dist/style.css';
import { postAudit } from '../../services/audit.service';
import { IClip, IClipEdit, IVideoClip } from '../../data/intefaces/stream.interface';
import { EmailShareButton, FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { LoadingOutlined } from '@ant-design/icons';
import { ShareTab } from './ShareTab';
import { compressData } from '../../utils';
import { PricingModal } from '../pricing-modal/PricingModal';


Modal.setAppElement('#root');

export type SocialType = 'youtube' | 'tiktok' | 'instagram' | 'linkedin' | 'facebook' | 'pinterest' | 'reddit';

const tiktokViewOptions = [
  {
    label: 'Public',
    value: 'public'
  },
  {
    label: 'Mutual follow friends',
    value: 'mutual_follow_friends'
  },
  {
    label: 'Followers only',
    value: 'followers_only'
  },
  {
    label: 'Private',
    value: 'private'
  },
];

const tiktokAllowUsersOptions = [
  {
    value: 'comment',
    label: 'Comment'
  },
  {
    value: 'duet',
    label: 'Duet'
  },
  {
    value: 'stitch',
    label: 'Stitch'
  },
];

export interface IShareModalProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  videoUrl?: string | null | undefined;
  handleSuccess: (isSchedule?: boolean) => void;
  clip: IClip & Partial<IClipEdit> & IVideoClip;
  customScheduleDate?: string | null;
  tiktokToken?: string | null;
}

export const ShareModal = memo(({ isOpen, handleCloseModal, videoUrl, handleSuccess, clip, customScheduleDate, tiktokToken }: IShareModalProps) => {
  const updatedUrl = videoUrl?.includes('d2xqoz7p2muvu5.cloudfront.net') ? videoUrl?.replace('d2xqoz7p2muvu5.cloudfront.net', 'files.ibetcha.gg') : videoUrl as string;
  const [openedTab, setOpenedTab] = useState<null | SocialType>(null);
  const [connectedSocialPlatformsList, setConnectedSocialPlatformsList] = useState<string[]>([]);
  const [loadingSocialExternalUrl, setLoadingSocialExternalUrl] = useState(false);
  const [loadingPosting, setLoadingPosting] = useState(false);
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const allowDirectShare = userContext?.user?.permissions?.direct_share;

  useEffect(() => {
    const getConnectedPlatformsList = async () => {
      const response = await axios.get('/streamer/social/platforms');
      if (response?.data && response?.data?.length > 0) {
        setConnectedSocialPlatformsList(response?.data);
      } else {
        setConnectedSocialPlatformsList([]);
      }
    }

    getConnectedPlatformsList().catch(() => {
      messageApi.error('Failed to get your connected socials', 5);
    })
  }, []);

  const redirectToSocialExternalUrl = async () => {
    if (!allowDirectShare) {
      navigate('/subscriptions');
      return null;
    }
    setLoadingSocialExternalUrl(true);
    try {
      localStorage.setItem('cilpForDirectShare', JSON.stringify(clip));
      const response = await axios.post('/streamer/social/url', {
        redirect: `${window.location.href}${customScheduleDate ? '?' : '&'}clipId=${clip.id}&videoUrl=${videoUrl}${customScheduleDate ? `&customScheduleDate=${customScheduleDate}` : ''}`,
      });
      if (response?.data && typeof response?.data === 'string') {
        if (response?.data.includes('http')) {
          window.open(response?.data, '_blank');
        }
      } else {
        messageApi.error('Failed to start a connection procedure. Please try again later', 5);
      }
      setLoadingSocialExternalUrl(false);
    } catch (error) {
      console.log('Failed to start a connection procedure', error);
      messageApi.error('Failed to start a connection procedure. Please try again later', 5);
      setLoadingSocialExternalUrl(false);
    }
  }

  const formatSocialOptionsForPayload = (data: any, type: SocialType) => {
    if (type === 'youtube') {
      return { name: 'youTubeOptions', data: data }
    } else if (type === 'tiktok') {
      return { name: 'tikTokOptions', data: data }
    } else if (type === 'instagram') {
      return { name: 'instagramOptions', data: data }
    } else if (type === 'facebook') {
      return { name: 'faceBookOptions', data: data }
    } else if (type === 'linkedin') {
      return { name: 'linkedInOptions', data: data }
    } else if (type === 'pinterest') {
      return { name: 'pinterestOptions', data: data }
    } else if (type === 'reddit') {
      return { name: 'redditOptions', data: data }
    } else {
      return null;
    }
  }

  const handlePostNow = async (description: string, data: any, type: SocialType) => {
    const socialOptions = formatSocialOptionsForPayload(data, type)
    if (socialOptions) {
      const payload = {
        post: description,
        platforms: [type],
        mediaUrls: [videoUrl],
        clip_id: clip.id,
        [socialOptions.name]: socialOptions.data
      }

      if (data?.publishAt) {
        payload.scheduleDate = data?.publishAt;
      }

      setLoadingPosting(true);
      try {
        const response = await axios.post('/streamer/social/post', payload);

        if (response?.data.is_success && response?.data?.data) {
          handleSuccess(Boolean(data?.publishAt));
        } else {
          messageApi.error(response?.data?.data || 'Failed to post a clip', 5);
        }
        setLoadingPosting(false);
      } catch (error) {
        messageApi.error('Failed to post a clip', 5);
        setLoadingPosting(false);
      }

    }
  }
  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  }

  const handleOpenPricingModal = () => {
    setIsPricingModalOpened(true);
  }

  const styles = useMemo(() => {
    return {
      container: {
        padding: 20,
        display: 'flex',
        backgroundColor: COLORS.GRAY_BACKGROUND,
        borderRadius: '0px 0px 8px 8px',
        gap: '26px',
        maxWidth: 1070,
        width: 'fit-content',
        margin: '0 auto',
        marginBottom: 28,
        marginTop: -10
      },
      imageWrapper: {

      },
      contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '13px',
        minWidth: 425,
        maxWidth: 425,
        height: 'fit-content'
      },
      channelName: {
        fontSize: 20,
        fontWeight: 400,
        color: COLORS.SUBTEXT_GRAY_LIGHT,
        lineHeight: '29px'
      },
      item: {
        display: 'flex',
      },
      propertyName: {
        fontSize: 16,
        fontWeight: 400,
        color: COLORS.SUBTEXT_GRAY_LIGHT,
        lineHeight: '28px',
        minWidth: 185
      },
      propertyValue: {
        fontSize: 16,
        fontWeight: 400,
        color: COLORS.GRAY_DISABLED,
        lineHeight: '28px'
      },
      chipPropertyValue: {
        fontSize: 18,
        fontWeight: 400,
        color: COLORS.WHITE,
        backgroundColor: COLORS.CHIP_BACKGROUND,
        padding: '3px 6px',
        lineHeight: '28px',
        borderRadius: 5
      },
      creditsText: {
        fontSize: 14,
        fontWeight: 400,
        color: COLORS.SUBTEXT_GRAY_LIGHT,
        lineHeight: '28px',
        width: 185
      },
      invalidGoogleLink: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#ffd700',
        textDecoration: 'underline'
      },
    } as const;
  }, []);

  const handleTabClick = (type: SocialType) => {
    if (openedTab === type) {
      setOpenedTab(null); // Close tab
    } else {
      setOpenedTab(type);
    }
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(updatedUrl);
    message.success({
      content: 'Video URL copied!',
      style: {
        color: COLORS.BLACK,
      },
    });
  };

  const checkIfSocialIsConnected = (type: string) => {
    return connectedSocialPlatformsList.includes(type);
  }

  return (
    <Modal
      isOpen={isOpen}
      className="Share-Modal"
      overlayClassName="Share-Overlay"
    >
      <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />
      <div className="modal-main-share">
        {(loadingSocialExternalUrl || loadingPosting) &&
          <div className='share-loading-overlay'>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 42, color: '#fff' }} spin />} />
          </div>
        }

        <Image onClick={handleCloseModal} wrapperStyle={{ position: 'absolute', right: '20px', top: '15px', cursor: 'pointer' }} src={IconClose} preview={false} width={16} />
        <div className="title-and-subtitle">
          <div className="title">Connect your socials</div>
          <div className="sub-title">Automatically schedule or post to your favorite social media platform.</div>
        </div>

        <div className="tabs-list">
          {contextHolder}

          {allowDirectShare
            ?
            <ShareTab onTabClick={handleTabClick} type='tiktok' isOpened={openedTab === 'tiktok'} icon={<IconTiktok className='social-logo' />} name='TikTok' isConnected={checkIfSocialIsConnected('tiktok')} videoUrl={updatedUrl} handlePostNow={handlePostNow} handleCloseModal={handleCloseModal} clip={clip} redirectToSocialExternalUrl={redirectToSocialExternalUrl} customScheduleDate={customScheduleDate} />
            :
            <TiktokShareTab tiktokToken={tiktokToken} onTabClick={handleTabClick} type='tiktok' isOpened={openedTab === 'tiktok'} icon={<IconTiktok />} name='TikTok' isConnected={Boolean(tiktokToken)} videoUrl={updatedUrl} handleSuccess={handleSuccess} handleCloseModal={handleCloseModal} clip={clip} redirectToSocialExternalUrl={redirectToSocialExternalUrl} customScheduleDate={customScheduleDate} />
          }
          <ShareTab onTabClick={handleTabClick} type='youtube' isOpened={openedTab === 'youtube'} icon={<IconYoutubeShorts className='social-logo' />} name='YouTube' isConnected={checkIfSocialIsConnected('youtube')} videoUrl={updatedUrl} handlePostNow={handlePostNow} handleCloseModal={handleCloseModal} clip={clip} redirectToSocialExternalUrl={redirectToSocialExternalUrl} customScheduleDate={customScheduleDate} />
          <ShareTab onTabClick={handleTabClick} type='instagram' isOpened={openedTab === 'instagram'} icon={<IconInstagram className='social-logo' />} name='Instagram' isConnected={checkIfSocialIsConnected('instagram')} videoUrl={updatedUrl} handlePostNow={handlePostNow} handleCloseModal={handleCloseModal} clip={clip} redirectToSocialExternalUrl={redirectToSocialExternalUrl} customScheduleDate={customScheduleDate} />
          <ShareTab onTabClick={handleTabClick} type='facebook' isOpened={openedTab === 'facebook'} icon={<IconFacebook className='social-logo' />} name='Facebook' isConnected={checkIfSocialIsConnected('facebook')} videoUrl={updatedUrl} handlePostNow={handlePostNow} handleCloseModal={handleCloseModal} clip={clip} redirectToSocialExternalUrl={redirectToSocialExternalUrl} customScheduleDate={customScheduleDate} />
          <ShareTab onTabClick={handleTabClick} type='linkedin' isOpened={openedTab === 'linkedin'} icon={<IconLinkedin className='social-logo' />} name='LinkedIn' isConnected={checkIfSocialIsConnected('linkedin')} videoUrl={updatedUrl} handlePostNow={handlePostNow} handleCloseModal={handleCloseModal} clip={clip} redirectToSocialExternalUrl={redirectToSocialExternalUrl} customScheduleDate={customScheduleDate} />
          <ShareTab onTabClick={handleTabClick} type='pinterest' isOpened={openedTab === 'pinterest'} icon={<IconPinterest className='social-logo' />} name='Pinterest' isConnected={checkIfSocialIsConnected('pinterest')} videoUrl={updatedUrl} handlePostNow={handlePostNow} handleCloseModal={handleCloseModal} clip={clip} redirectToSocialExternalUrl={redirectToSocialExternalUrl} customScheduleDate={customScheduleDate} />
          {/* <ShareTab onTabClick={handleTabClick} type='reddit' isOpened={openedTab === 'reddit'} icon={<IconReddit className='social-logo' />} name='Reddit' isConnected={checkIfSocialIsConnected('reddit')} videoUrl={updatedUrl} handlePostNow={handlePostNow} handleCloseModal={handleCloseModal} clip={clip} redirectToSocialExternalUrl={redirectToSocialExternalUrl} customScheduleDate={customScheduleDate} /> */}
        </div>
        <div className='share-options'>
          <TwitterShareButton
            url={updatedUrl}
          >
            <Image
              src={IconShareTwitter}
              preview={false}
              width={28}
              className="cursor-pointer"
            />
          </TwitterShareButton>
          <EmailShareButton url={updatedUrl}>
            <Image
              src={IconShareGmail}
              preview={false}
              width={28}
              className="cursor-pointer"
            />
          </EmailShareButton>

          <FacebookShareButton url={updatedUrl}>
            <Image
              src={IconShareFacebook}
              preview={false}
              width={28}
              className="cursor-pointer"
            />
          </FacebookShareButton>

          <TelegramShareButton url={updatedUrl}>
            <Image
              src={IconShareTelegram}
              preview={false}
              width={28}
              className="cursor-pointer"
            />
          </TelegramShareButton>

          <WhatsappShareButton url={updatedUrl}>
            <Image
              src={IconShareWhatsapp}
              preview={false}
              width={28}
              className="cursor-pointer"
            />
          </WhatsappShareButton>
          <Image src={IconCopy} preview={false} className="cursor-pointer" width={24} onClick={copyUrl} />
        </div>
      </div>
    </Modal>
  );
});

interface ITiktokShareTabProps {
  tiktokToken?: string | null;
  type: SocialType;
  isOpened: boolean;
  onTabClick: (type: SocialType) => void;
  icon: any;
  name: string;
  videoUrl?: string | null | undefined;
  handleSuccess: () => void;
  // handlePostNow: (description: string, data: any, type: SocialType) => void;
  handleCloseModal: () => void;
  redirectToSocialExternalUrl: () => void;
  clip?: IClip & Partial<IClipEdit> & IVideoClip;
  disabled?: boolean;
  isConnected?: boolean;
  customScheduleDate?: string | null;
}


const TiktokShareTab = ({ tiktokToken, type, isOpened, onTabClick, icon, name, videoUrl, handleSuccess, handleCloseModal, clip, redirectToSocialExternalUrl }: ITiktokShareTabProps) => {
  const [selectedViewOption, setSelectedViewOption] = useState(tiktokViewOptions[0]);
  const [selectedCaption, setSelectedCaption] = useState('');
  const [selectedAllowOptions, setSelectedAllowOptions] = useState<string[]>(["comment"]);

  const [isDisclosureSelected, setIsDisclosureSelected] = useState(false);
  const [yourBrandSelected, setYourBrandSelected] = useState(true);
  const [brandedContentSelectd, setBrandedContentSelectd] = useState(false);

  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const allowDirectShare = userContext?.user?.permissions?.direct_share;

  const styles = useMemo(() => {
    return {
      smallDivider: {
        height: 15,
        width: 1,
        backgroundColor: '#fff'
      },
      hashtagContainer: {
        marginTop: 10,
        gap: 10,
        maxWidth: '624px'
      },
      hashtagsText: {
        color: '#787878',
        display: 'inline'
      },
      hashtagsHeading: {
        color: COLORS.PRIMARY,
        display: 'inline'
      },
      loaderContainer: {
        background: COLORS.BLACK,
        overflow: 'hidden',
        border: '1px solid rgba(0, 239, 248, 0.1)',
        position: 'relative',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      },
    } as const;
  }, []);

  useEffect(() => {
    if (tiktokToken) {
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  }, [tiktokToken]);


  const handleHeaderLeftClick = () => {
    if (isConnected) {
      onTabClick(type);
    } else {
      handleClickConnect();
    }
  };

  const handleClickConnect = () => {
    videoUrl && localStorage.setItem('tiktok-share-video-url', videoUrl);
    videoUrl && localStorage.setItem('direct-share-clip', JSON.stringify(clip));
    const queryParams = window.location.search;
    videoUrl && localStorage.setItem('url-after-tiktok-auth', `${window.location.pathname}${queryParams ? queryParams + '&' : '?'}clipId=${clip?.id}&videoUrl=${encodeURIComponent(videoUrl)}`);
    localStorage.setItem('cilpForDirectShare', JSON.stringify(clip));

    const redirectUri = `${window.location.origin}/authorization/tiktok`;
    const authUrl = `https://www.tiktok.com/v2/auth/authorize/?client_key=awy4u56biya7v4d4&scope=user.info.basic,video.publish&response_type=code&redirect_uri=${redirectUri}&state=vhkzep`;
    window.location.href = authUrl;
  };

  const handleClickDisconnect = () => {
    localStorage.removeItem('tiktokToken');
    setIsConnected(false);
  };

  const handleChangeCaption = (text: string) => {
    if (text.length <= 100) {
      setSelectedCaption(text);
    } else {
      messageApi.info('The limit is 100 symbols', 3);
    }
  };

  const handlePostNow = async () => {
    if (isLoading) return null;
    setIsLoading(true);
    const payload = prepareDataForAPI();
    try {
      const response = await axios.post('/streamer/tiktok-post', payload);
      postAudit({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user_action: 'TikTok direct share',
        user_id: userContext?.user?.id,
        clip_url: videoUrl
      });
      if (response?.status === 200) {
        handleSuccess();
      } else {
        messageApi.error('Failed to post a video');
        handleCloseModal();
        handleClickDisconnect();
        postAudit({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          user_action: 'TikTok direct share failed',
          user_id: userContext?.user?.id,
          clip_url: videoUrl
        });
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || 'Failed to post a video';
      messageApi.error(errorMessage);
      postAudit({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user_action: 'TikTok direct share failed',
        user_id: userContext?.user?.id,
        clip_url: videoUrl
      });
      // handleCloseModal();
      handleClickDisconnect();
    } finally {
      setIsLoading(false);
    }
    handleClickDisconnect();
  };

  const handleSchedule = () => {
    localStorage.setItem('cta_source', 'go-pro-free-schedule');
    handleOpenPricingModal();
  };

  const handleGoPro = () => {
    localStorage.setItem('cta_source', 'go-pro-tiktok-description-field');
    handleOpenPricingModal();
  }

  const renderDate = (date: any) => {
    const dateString = `${date.day}/${date.month}/${date.year} ${renderHourOrMinute(date.hour)}:${renderHourOrMinute(date.minute)}`;
    return dateString;
  };

  const renderHourOrMinute = (value: number) => {
    if (value < 10) {
      return `0${value}`;
    } else {
      return value;
    }
  };

  const prepareDataForAPI = () => {
    const payload = {
      code: tiktokToken,
      clip_id: clip?.id,
      video_url: videoUrl,
      caption: `${selectedCaption} Made using Spikes Studio #SpikesStudio`,
      privacy: selectedViewOption.value, // {'public', 'mutual_follow_friends', 'followers_only', 'private'}
      allow_comment: selectedAllowOptions.indexOf('comment') >= 0,
      allow_duet: selectedAllowOptions.indexOf('duet') >= 0,
      allow_stitch: selectedAllowOptions.indexOf('stitch') >= 0
    };
    return payload;
  };

  const orientation = clip?.mobile_download_url === videoUrl ? 'mobile' : 'desktop';

  const getHashtags = () => {
    if (clip?.tags) {
      try {
        const validJsonString = clip?.tags.replace(/'/g, '"');
        const array = JSON.parse(validJsonString);
        return array;
      } catch (error) {
        console.error('Error parsing hashtags:', error);
        return null;
      }
    } else {
      return null;
    }
  };

  const copyHashtags = () => {
    if (getHashtags()) {
      navigator.clipboard.writeText(getHashtags().join(' '));
      message.success({
        content: 'Hashtags copied!',
        style: {
          color: COLORS.BLACK,
        },
      });
    }
  };

  const copyDescription = () => {
    if (clip?.description) {
      navigator.clipboard.writeText(clip?.description);
      message.success({
        content: 'Description copied!',
        style: {
          color: COLORS.BLACK,
        },
      });
    }
  };

  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  }

  const handleOpenPricingModal = () => {
    setIsPricingModalOpened(true);
  }

  return (
    <div className={`share-tab tiktok-share-tab ${isOpened && 'active'}`}>
      {contextHolder}
      <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />
      <div className="tab-header">
        <div onClick={handleHeaderLeftClick} className="header-left">
          <IconCollapse />
          {icon}
          <span>{name}</span>
        </div>
        <div onClick={isConnected ? handleClickDisconnect : handleClickConnect} className="header-right">
          {isConnected ?
            <>
              <IconDisconnect />
              <span>Disconnect</span>
            </>
            :
            <>
              <IconConnect />
              <span>Connect</span>
            </>
          }

        </div>
      </div>
      <Collapse isOpened={isConnected && isOpened}>
        <div className="tiktok-tab-content">
          <div className="input-with-heading">
            <div className="limit-info"><IconInfoCircle />TikTok allows to post up to 15 clips in a 24-hour window</div>
            {/* CLIP PREVIEW - UNCOMMENT WHEN READY */}

            {/* {clip &&
              <div className={`share__clip-preview ${orientation === 'mobile' ? 'mobile' : 'desktop'}`}>
                <img src={orientation === 'mobile' ? clip?.mobile_thumbnail_url : clip?.thumbnail_url} alt="Thumbnail" />
                <div className="share__clip-preview-description">
                  {
                    (getHashtags() || clip?.description) &&
                    <div className='hashtags-container'>
                      {clip?.description && <div style={{ display: 'flex', alignItems: 'center', marginTop: 7 }}>
                        <Typography style={styles.hashtagsHeading}>Description:&nbsp;<Typography style={styles.hashtagsText}>{clip?.description}</Typography><IconCopySVG onClick={copyDescription} className="cursor-pointer" width={16} height={16} style={{ marginLeft: 8 }} /></Typography>
                      </div>
                      }
                      {getHashtags() &&
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography style={styles.hashtagsHeading}>Hashtags:&nbsp;<Typography style={styles.hashtagsText}>{getHashtags().join(' ')}</Typography> <IconCopySVG onClick={copyHashtags} className="cursor-pointer" width={16} height={16} style={{ marginLeft: 8 }} /></Typography>
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>
            } */}

            <div className="input-heading">
              <span>Title</span>
              <span className="counter">({selectedCaption.length}/100)</span>
              {clip?.headline && !clip?.headline.includes('default_clip') &&
                <button
                  className="paste-button no-margin"
                  onClick={() => {
                    if (clip?.headline) {
                      handleChangeCaption(clip.headline);
                      messageApi.success('Title pasted successfully.', 3); // Success message
                    } else {
                      messageApi.error('No headline available to paste.', 5); // Error message
                    }
                  }}
                >
                  Paste Title
                </button>
              }
            </div>
            <TextArea
              className="share-textarea"
              value={selectedCaption}
              onChange={(e) => handleChangeCaption(e.target.value)}
              placeholder="Title (mandatory field)"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </div>
          <div className="input-with-heading">
            <div className="input-heading">
              <span>Description</span>
              <button
                className="paste-button no-margin gradient"
                onClick={handleGoPro}
              >
                PRO
              </button>
            </div>
            <TextArea
              className="share-textarea"
              disabled
              value={'Made using Spikes Studio #SpikesStudio'}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </div>
          <div className="input-with-heading">
            <div className="input-heading">Who can view this video</div>
            <Select
              className="privacy-select"
              classNamePrefix="privacy-select-prefix"
              value={selectedViewOption}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={(value) => setSelectedViewOption(value)}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              options={tiktokViewOptions}
            />
          </div>

          <div className="input-with-heading">
            <div className="input-heading">Allow users to</div>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Checkbox.Group options={tiktokAllowUsersOptions} value={selectedAllowOptions} onChange={setSelectedAllowOptions} />
          </div>

          <div className="disclosure">
            <div>Disclose the video content</div>
            <Switch checked={isDisclosureSelected} onChange={setIsDisclosureSelected} />
          </div>
          <div className="disclosure-info-box">
            <IconInfo />
            <div>
              Your video will be labeled “Promotional content”. This Cannot be changed once your video is posted
            </div>
          </div>
          <div>
            <div className='disclosure-description'>Turn on to disclose that this video promotes good or services in exchange for something of value. Your video could promote yourself, a third party, or both.</div>

            <Collapse isOpened={isDisclosureSelected}>
              <div className="disclosure-content">
                <div className="disclosure-item">
                  <div className="discosure-text">
                    <div className="heading">Your brand</div>
                    <div className="description">You are promoting yourself or your own business. This video will be classified as Brand Organic.</div>
                  </div>
                  <Checkbox checked={yourBrandSelected} onChange={(e) => setYourBrandSelected(e.target.checked)} />
                </div>

                <div className="disclosure-item">
                  <div className="discosure-text">
                    <div className="heading">Branded content</div>
                    <div className="description">You are promoting another brand or a third party. This video will be classified as Branded Content.</div>
                  </div>
                  <Checkbox checked={brandedContentSelectd} onChange={(e) => setBrandedContentSelectd(e.target.checked)} />
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className="agree-text">
          By posting, you agree to TikTok&apos;s {brandedContentSelectd && <><a href="google.com" target="_blank">Branded Content Policy</a> and</>} <a href="https://support.tiktok.com/en/business-and-creator/creator-and-business-accounts/commercial-use-of-music-on-tiktok" target="_blank" rel="noopener noreferrer">Music Usage Confirmation</a>.

        </div>

        <div style={{ marginTop: 20 }} className='action-buttons'>
          <div>
            <div onClick={handleSchedule} className='schedule-button pro-tag'>
              <IconSchedule />
              {/* <DtPicker inputClass='tiktok-date__input' onChange={setDate} withTime /> */}

              <span>{date ? renderDate(date) : 'Schedule post'}</span>
            </div>
          </div>
          <div onClick={handlePostNow} className='post-button'>{isLoading ? 'Loading...' : 'Post now'}</div>
        </div>
      </Collapse>
    </div>
  );
};

ShareModal.displayName = 'ShareModal';


export default ShareModal;
