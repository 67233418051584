import { useContext, useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../themes/colors';
import { UserContext } from '../../data/userContext';
import { useNavigate } from 'react-router';
import { ReactComponent as IconSiteSearch } from '../../assets/site_search.svg';
import { ReactComponent as IconChevron } from '../../assets/chevron_right.svg';
import { ReactComponent as IconPage } from '../../assets/icon_page.svg';
import { ReactComponent as IconHashtag } from '../../assets/hashtag.svg';
import { ReactComponent as IconYoutubeChannel } from '../../assets/youtube_channel.svg';
import { ReactComponent as IconVideoLibrary } from '../../assets/video_library.svg';
import { ReactComponent as IconCamera } from '../../assets/camera.svg';
import './SiteSearchModal.less';
import Modal from 'react-modal';
import axios from 'axios';
import { ISocialPost, IStream, IVideo } from '../../data/intefaces/stream.interface';
import { checkStreamIsAvailableToRender, isISOAfterCurrentDate, showGoProButton } from '../../utils';
import { PricingModal } from '../pricing-modal/PricingModal';

const PAGES_LIST = [
  {
    label: 'Create Clips',
    route: '/spikes/videos',
  },
  {
    label: 'Live Streams AI',
    route: '/spikes/streams',
    onlyTwitch: true,
  },
  // {
  //   label: 'Social Analytics', // Add later with logic
  //   route: '/spikes/social'
  // },
  {
    label: 'Dashboard',
    route: '/dashboard',
  },
  {
    label: 'Tutorials',
    route: '/tutorial',
  },
  {
    label: 'API Dashboard',
    route: '/api',
  },
  {
    label: 'Loyalty Program',
    route: '/spikes/loyalty-program',
  },
  {
    label: 'Invite a Friend',
    route: '/affiliate-program',
  },
  {
    label: 'About',
    route: '/about',
  },
];

const highlightText = (
  text: string,
  highlight: string,
  isChannelName?: boolean,
) => {
  if (!highlight) return text;
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);
  return parts.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      <span
        key={index}
        className={`site-search__highlighted-symbol ${isChannelName && 'channel'
          }`}
      >
        {part}
      </span>
    ) : (
      part
    ),
  );
};

const checkProjectNotExpired = (project: IStream) => {
  return !(project?.expiration_date && Date.now() >= project?.expiration_date);
}

export interface ISiteSearchModalProps {
  open: boolean;
  onClose: () => void;
  socialMode?: boolean;
  onClipSelect?: (clip: any) => void;
}

export const SiteSearchModal = ({
  open,
  onClose,
  socialMode,
  onClipSelect,
}: ISiteSearchModalProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [pagesList, setPagesList] = useState(PAGES_LIST);
  const [rerender, setRerender] = useState(1);
  const [allProjects, setAllProjects] = useState<null | IVideo[]>(null);
  const [allClips, setAllClips] = useState<null | any[]>(null);
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const isGoogleUser = !userContext?.user?.twitch_id;

  useEffect(() => {
    if (isGoogleUser) {
      const pages = pagesList.filter((page) => !page?.onlyTwitch);
      setPagesList(pages);
    }
  }, [isGoogleUser]);

  useEffect(() => {
    const getProjects = async () => {
      try {
        const response = await axios.get('/streamer/uploads');
        if (response?.data) {
          const availableProjects = response?.data.filter((project: IStream) =>
            checkStreamIsAvailableToRender(project) && checkProjectNotExpired(project),
          );
          if (availableProjects) {
            setAllProjects(availableProjects as IVideo[]);
            if (socialMode) {
              // Extract clips from projects
              const clips = [];
              for (const project of availableProjects) {
                if (project.clips && project.clips.length > 0) {
                  project.clips.forEach((clip: any) => (clip.stream_title = project.title));
                  clips.push(...project.clips);
                }
              }
              setAllClips(clips);
            }
          } else {
            setAllProjects([]);
          }
        } else {
          setRerender((prev) => prev + 1);
        }
      } catch (error) {
        setRerender((prev) => prev + 1);
      }
    };

    getProjects();
  }, [rerender, socialMode]);

  const handleCloseModal = () => {
    onClose();
  };

  const filteredPages = searchQuery
    ? pagesList.filter((page) =>
      page.label.toLowerCase().includes(searchQuery.toLowerCase()),
    )
    : pagesList.slice(0, 3);

  const filteredProjects = useMemo(() => {
    if (allProjects && allProjects.length && !socialMode) {
      if (!searchQuery) {
        return allProjects.slice(0, 3);
      } else {
        const filtered = allProjects.filter(
          (video) =>
            video.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            video.channel_name
              .toLowerCase()
              .includes(searchQuery.toLowerCase()),
        );
        return filtered;
      }
    } else {
      return [];
    }
  }, [searchQuery, allProjects, socialMode]);

  const filteredClips = useMemo(() => {
    if (allClips && allClips.length && socialMode) {
      if (!searchQuery) {
        return allClips;
      } else {
        const filtered = allClips.filter(
          (clip) =>
            (clip.headline &&
              clip.headline
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (clip.description &&
              clip.description
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (clip.stream_title &&
              clip.stream_title
                .toLowerCase()
                .includes(searchQuery.toLowerCase())),
        );
        return filtered;
      }
    } else {
      return [];
    }
  }, [searchQuery, allClips, socialMode]);

  return (
    <>
      <Modal
        isOpen={open}
        className="Modal"
        overlayClassName="Overlay Site-Search-Overlay"
        shouldCloseOnEsc
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseModal}
      >
        <div className="glass-modal site-search">
          <div className="site-search__search-bar">
            <IconSiteSearch className="site-search__search-icon" />
            <input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="site-search__input"
              placeholder="Search website"
              autoFocus
            />
            <div onClick={handleCloseModal} className="site-search__escape-tag">
              ESC
            </div>
          </div>
          <div className="site-search__content scrollbar">
            <div className="site-search__sections-wrapper">
              {socialMode ? (
                <>
                  {filteredClips && filteredClips.length > 0 ? (
                    <div className="site-search__section-with-title">
                      <div className="site-search__section-title">
                        <span>Clips</span>
                        <div className="site-search__section-title-separator" />
                      </div>
                      <div className="site-search__section">
                        {filteredClips.map((clip) => (
                          <ClipItem
                            key={clip.id}
                            clip={clip}
                            searchQuery={searchQuery}
                            onClipSelect={onClipSelect}
                            handleCloseModal={handleCloseModal}
                            socialMode={socialMode}
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="site-search__no-results">
                      <span>
                        No results for &quot;
                        <span
                          style={{ color: 'var(--text-base)', fontWeight: 500 }}
                        >
                          {searchQuery}
                        </span>
                        &quot;
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {filteredPages && filteredPages.length > 0 && (
                    <div className="site-search__section-with-title">
                      <div className="site-search__section-title">
                        <span>Navigation</span>
                        <div className="site-search__section-title-separator" />
                      </div>

                      <div className="site-search__section">
                        {filteredPages.map((page) => (
                          <NavigationItem
                            key={page.label}
                            handleCloseModal={handleCloseModal}
                            type="page"
                            label={page.label}
                            route={page.route}
                            searchQuery={searchQuery}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {filteredProjects && filteredProjects.length > 0 && (
                    <div className="site-search__section-with-title">
                      <div className="site-search__section-title">
                        <span>Projects</span>
                        <div className="site-search__section-title-separator" />
                      </div>
                      <div className="site-search__section">
                        {filteredProjects.map((project) => (
                          <ProjectItem
                            key={project.id}
                            handleCloseModal={handleCloseModal}
                            type="project"
                            project={project}
                            searchQuery={searchQuery}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {(!filteredPages || filteredPages?.length === 0) &&
                    (!filteredProjects || filteredProjects?.length === 0) && (
                      <div className="site-search__no-results">
                        <span>
                          No results for &quot;
                          <span
                            style={{
                              color: 'var(--text-base)',
                              fontWeight: 500,
                            }}
                          >
                            {searchQuery}
                          </span>
                          &quot;
                        </span>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

interface INavigationItemProps {
  type: 'page';
  label: string;
  route: string;
  searchQuery: string;
  handleCloseModal: () => void;
}
const NavigationItem = ({
  type,
  label,
  route,
  searchQuery,
  handleCloseModal,
}: INavigationItemProps) => {
  const navigate = useNavigate();
  const renderIcon = () => {
    switch (type) {
      case 'page':
        return <IconHashtag />;
      default:
        return <IconHashtag />;
    }
  };

  const handleClick = () => {
    navigate(route);
    handleCloseModal();
  };

  return (
    <div onClick={handleClick} className="site-search__navigation-item">
      <div className="site-search__item-left">
        <div className="site-search__item-type-tag">{renderIcon()}</div>
        <span>{highlightText(label, searchQuery)}</span>
      </div>
      <IconChevron className="site-search__chevron" />
    </div>
  );
};

interface IProjectItemProps {
  type: 'project';
  project: IVideo;
  searchQuery: string;
  handleCloseModal: () => void;
}
const ProjectItem = ({
  type,
  project,
  searchQuery,
  handleCloseModal,
}: IProjectItemProps) => {
  const navigate = useNavigate();

  const renderIcon = () => {
    switch (type) {
      case 'project':
        return <IconCamera className="site-search__icon-project" />;
      default:
        return <IconCamera className="site-search__icon-project" />;
    }
  };

  const handleOpenProject = () => {
    navigate(`/spikes/project?source=upload&id=${project.id}`);
  };

  const handleClick = () => {
    handleOpenProject();
    handleCloseModal();
  };

  return (
    <div
      onClick={handleClick}
      className="site-search__navigation-item site-search__project-item"
    >
      <div className="site-search__item-left">
        <div className="site-search__item-type-tag">{renderIcon()}</div>
        <div className="site-search__title-and-channel">
          {project?.title && (
            <span className="site-search__video-name">
              {highlightText(project?.title, searchQuery)}
            </span>
          )}
          {project?.channel_name && (
            <span className="site-search__channel">
              <IconYoutubeChannel />
              <span>
                {highlightText(project?.channel_name, searchQuery, true)}
              </span>
            </span>
          )}
        </div>
      </div>

      <div className="site-search__item-image">
        <img src={project?.thumbnail_url} alt="thumbnail" />
      </div>
    </div>
  );
};

interface IClipItemProps {
  clip: any;
  searchQuery: string;
  onClipSelect?: (clip: any) => void;
  handleCloseModal: () => void;
  socialMode?: boolean;
}

const ClipItem = ({
  clip,
  searchQuery,
  onClipSelect,
  handleCloseModal,
  socialMode,
}: IClipItemProps) => {
  const [showPricingModal, setShowPricingModal] = useState(false);
  const userContext = useContext(UserContext);

  const handleClick = () => {
    if (onClipSelect) {
      onClipSelect(clip);
    }
    handleCloseModal();
  };

  const renderIcon = () => {
    return <IconVideoLibrary />;
  };

  const getClipName = () => {
    if (
      clip.headline === null ||
      (clip?.headline?.includes('default_clip') &&
        clip?.name?.includes('default_clip'))
    ) {
      return clip?.stream_title;
    } else {
      return clip?.headline;
    }
  };

  const renderTagsFromSocialPosts = (posts: ISocialPost[]) => {
    const postInstatlyPosted = posts.find(
      (post) => !isISOAfterCurrentDate(post.post?.scheduleDate),
    );
    const scheduledPost = posts.find((post) =>
      isISOAfterCurrentDate(post.post?.scheduleDate),
    );

    return (
      <>
        {postInstatlyPosted && (
          <div className="new-video-pill clip-card-edited-pill small">Posted</div>
        )}
        {scheduledPost && (
          <div className="clip-card-edited-pill new-video-pill schedule small">
            <span>Scheduled</span>
          </div>
        )}
      </>
    );
  };

  if (!clip?.mobile_thumbnail_url || clip?.status !== 'ready') return null;

  return (
    <>
      <PricingModal
        isOpen={showPricingModal}
        handleClosePricingModal={() => setShowPricingModal(false)}
      />
      <div
        onClick={handleClick}
        style={{ padding: '3px 20px' }}
        className="site-search__navigation-item site-search__clip-item"
      >
        <div className="site-search__item-left">
          <div className="site-search__item-type-tag">{renderIcon()}</div>
          <div className="site-search__title-and-channel">
            <div className="site-search__title-and-tag">
              {clip.social_posts &&
                clip.social_posts.length > 0 &&
                renderTagsFromSocialPosts(clip.social_posts)}
              {clip?.headline && (
                <span className="site-search__video-name">
                  {highlightText(getClipName(), searchQuery)}
                </span>
              )}
            </div>
            {clip?.description && (
              <span className="site-search__channel">
                <span>{highlightText(clip?.description, searchQuery)}</span>
              </span>
            )}
          </div>
        </div>

        <div className="site-search__item-image">
          <img
            src={clip.mobile_thumbnail_url || clip.thumbnail_url}
            alt="thumbnail"
          />
        </div>
      </div>
    </>
  );
};
