import { Router } from './pages/Router';
import './App.less';
import { UserContext } from './data/userContext';
import { useEffect, useMemo, useState } from 'react';
import { IStreamer } from './data/intefaces/streamer.interface';
import axios from 'axios';
import TagManager from 'react-gtm-module';
import { GoogleOAuthProvider } from '@react-oauth/google';
import OnboardingPopup from './components/onboarding-popup/OnboardingPopup';
import { OnboardingContext } from './data/onboardingContext';
import { SidebarContext } from './data/sidebarContext';
import { WordContext } from './data/wordContext';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_BASE_API;
axios.defaults.headers.common['Content-Type'] = 'application/json';

// axios.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response.status === 401 || error.response.status === 403) {
//       localStorage.removeItem('user');
//       window.location.href = '/';
//     }
//   });

const App = () => {
  const [user, setUser] = useState<IStreamer | null>(null);
  const [rerender, setRerender] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedWord, setSelectedWord] = useState(null);
  const [selectedSentenceIndex, setSelectedSentenceIndex] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_KEY || ''
  };

  TagManager.initialize(tagManagerArgs);

  const currentUser = useMemo(
    () => {

      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        const user = localStorage.getItem('user');
        if (user) {
          return ({ user: JSON.parse(user), setUser });
        }
      }

      return ({ user, setUser });
    },
    [user]
  );

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <UserContext.Provider value={currentUser}>
        <OnboardingContext.Provider value={{ rerender, setRerender }}>
          <SidebarContext.Provider value={{ isSidebarOpen, setIsSidebarOpen }}>
            <WordContext.Provider value={{ selectedWord, setSelectedWord, selectedSentenceIndex, setSelectedSentenceIndex, isChanged, setIsChanged }}>
              <Router />
              {/* <OnboardingPopup /> */}
            </WordContext.Provider>
          </SidebarContext.Provider>
        </OnboardingContext.Provider>
      </UserContext.Provider>
    </GoogleOAuthProvider>
  );
};

export default App;
