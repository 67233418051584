import React, { useState, useEffect, useContext } from 'react';
import { MainLayout } from '../../layouts';
import { Footer as AppFooter } from '../../components/footer';
import { message, Input, Spin, Tooltip } from 'antd';
import axios from 'axios';
import * as tus from 'tus-js-client';
import { ReactComponent as IconUpload } from '../../assets/upload.svg';
import { ReactComponent as IconEdit } from '../../assets/edit-clip-icon.svg';
import { postAudit } from '../../services/audit.service';
import { UserContext } from '../../data/userContext';
import DeleteAssetModal from './delete-asset-modal/DeleteAssetModal';
import { useNavigate } from 'react-router-dom';
import './AssetManagement.less';

// Asset Interface
interface Asset {
  id: string;
  name: string;
  url: string;
  type: string; // 'Image', 'Video', 'Watermark'
  createdAt: number;
  updatedAt: number;
  is_default: boolean;
  streamer: string;
  thumbnail?: string; // Optional field for video thumbnails
}

export interface AssetManagementPageProps {
  defaultTabIndex?: number;
}

const AssetManagementPage = (props: AssetManagementPageProps) => {
  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [allAssets, setAllAssets] = useState<Asset[]>([]);
  const [filteredAssets, setFilteredAssets] = useState<Asset[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>(['All']);
  const [editingAssetId, setEditingAssetId] = useState<string | null>(null);
  const [newAssetName, setNewAssetName] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const userContext = useContext(UserContext);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [assetToDelete, setAssetToDelete] = useState<{ id: string; name: string } | null>(null);
  const navigate = useNavigate();

  // const supportedFileTypes = ['video/mp4', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'];
  const supportedFileTypes = ['video/mp4', 'image/jpeg', 'image/png', 'image/webp'];


  const openDeleteModal = (asset: { id: string; name: string }) => {
    setAssetToDelete(asset);
    setModalOpen(true);
  };

  const handleDeleteAsset = async () => {
    if (assetToDelete) {
      try {
        await axios.delete('/streamer/asset', { data: { id: assetToDelete.id } });
        messageApi.success('Asset deleted successfully!');
        setAllAssets((prevAssets) =>
          prevAssets.filter((asset) => asset.id !== assetToDelete.id)
        );
      } catch (error) {
        messageApi.error('Failed to delete asset. Please try again.');
        console.error(error);
      } finally {
        setModalOpen(false);
        setAssetToDelete(null);
      }
    }
  };

  // Fetch assets and generate thumbnails if necessary
  const fetchAllAssets = async () => {
    try {
      const response = await axios.get('/streamer/assets');
      if (response?.data) {
        const assetsWithThumbnails = await generateThumbnails(response.data);
        setAllAssets(assetsWithThumbnails);
        setFilteredAssets(assetsWithThumbnails);
      }
    } catch (error) {
      messageApi.error('Failed to fetch assets. Please try again.');
    }
  };

  useEffect(() => {
    fetchAllAssets();
  }, []);

  useEffect(() => {
    applyFilter();
  }, [selectedFilters, allAssets]);

  const applyFilter = () => {
    if (selectedFilters.includes('All')) {
      setFilteredAssets(allAssets);
    } else {
      setFilteredAssets(
        allAssets.filter((asset) =>
          selectedFilters.some((filter) => asset.type.toLowerCase() === filter.toLowerCase())
        )
      );
    }
  };

  const toggleFilter = (filter: string) => {
    if (filter === 'All') {
      // If "All" is clicked, deselect all other filters and select only "All"
      setSelectedFilters(['All']);
    } else {
      const isSelected = selectedFilters.includes(filter);
  
      if (isSelected) {
        // Remove the selected filter
        const updatedFilters = selectedFilters.filter((f) => f !== filter);
        setSelectedFilters(updatedFilters.length > 0 ? updatedFilters : ['All']);
      } else {
        // Add the selected filter and deselect "All" if necessary
        const updatedFilters = selectedFilters.filter((f) => f !== 'All');
        setSelectedFilters([...updatedFilters, filter]);
      }
    }
  };
  
  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!supportedFileTypes.includes(file.type)) {
      // messageApi.error('Unsupported file type. Please upload an MP4, Image or GIF');
      messageApi.error('Unsupported file type. Please upload an MP4 or Image');

      return;
    }

    if (file.type.includes('video')) {
      checkVideoDuration(file);
    } else {
      uploadFile(file);
    }
  };

  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files?.[0];
    if (!file) return;

    if (!supportedFileTypes.includes(file.type)) {
      //messageApi.error('Unsupported file type. Please upload an MP4, Image or GIF');
      messageApi.error('Unsupported file type. Please upload an MP4 or Image');

      return;
    }

    if (file.type.includes('video')) {
      checkVideoDuration(file);
    } else {
      uploadFile(file);
    }
  };

  const uploadFile = (file: File) => {
    setLoadingFileUpload(true);

    const upload = new tus.Upload(file, {
      endpoint: process.env.REACT_APP_TUS_SERVER_URL, // No fallback
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onBeforeRequest: function (req) {
        const xhr = req.getUnderlyingObject();
        xhr.withCredentials = true;
      },
      retryDelays: [0, 3000, 5000, 10000, 20000],
      onError: function (error) {
        setLoadingFileUpload(false);
        postAudit({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
          user_action: `FAILED: Upload to TUS server. File name: ${file.name}, Error: ${JSON.stringify(
            error
          )}, File Details: ${JSON.stringify(file)}`,
          user_id: userContext?.user?.id,
        });
        console.error('Upload failed:', error);
        messageApi.error('Failed to upload the file. Please try again.');
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        const percentage = Math.round((bytesUploaded / bytesTotal) * 100);
        setProgress(percentage);
      },
      onSuccess: async function () {
        setLoadingFileUpload(false);
        const assetUrl = upload.url;
        if (assetUrl) {
          try {
            await saveAssetMetadata(assetUrl, file);
            messageApi.success('File uploaded and metadata saved successfully!');
          } catch (error) {
            messageApi.error('Failed to save asset metadata. Please try again.');
          }
        }
      },
    });

    upload.findPreviousUploads().then(function (previousUploads: any) {
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }
      upload.start();
    });
  };

  const saveAssetMetadata = async (assetUrl: string, file: File) => {
    const payload = {
      url: assetUrl,
      type: file.type.includes('gif') ? 'GIF' : file.type.includes('image') ? 'Image' : file.type.includes('video') ? 'Video' : 'Unknown',
      name: file.name.split('.').slice(0, -1).join('.'),
      is_default: true,
    };
  
    try {
      const response = await axios.post('/streamer/asset', payload);
      if (response.status === 200) {
        fetchAllAssets();
      }
    } catch (error) {
      console.error('Error saving metadata:', error);
      throw error;
    }
  };

  const generateThumbnails = async (assets: Asset[]) => {
    return Promise.all(
      assets.map((asset) => {
        return new Promise<Asset>((resolve) => {
          if (asset.type === 'Video' && !asset.thumbnail) {
            generateThumbnail(asset.url, (thumbnail) => {
              resolve({ ...asset, thumbnail });
            });
          } else {
            resolve(asset);
          }
        });
      })
    );
  };
  
  
  const generateThumbnail = (videoUrl: string, callback: (thumbnailUrl: string) => void): void => {
    const video = document.createElement('video');
    video.src = `${videoUrl}?_=${new Date().getTime()}`; // Cache busting
    video.crossOrigin = 'anonymous';
    video.style.display = 'none';
    document.body.appendChild(video);
  
    const timeoutId: NodeJS.Timeout = setTimeout(() => {
      console.warn('Thumbnail generation timed out.');
      callback('path/to/placeholder-image.jpg');
      cleanup();
    }, 5000); // Timeout after 5 seconds
  
    const cleanup = () => {
      clearTimeout(timeoutId);
      video.remove();
    };
  
    video.addEventListener('loadeddata', () => {
      video.currentTime = 0.5; // Seek to avoid black frames
    });
  
    video.addEventListener('seeked', () => {
      clearTimeout(timeoutId); // Clear timeout since the seeked event succeeded
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth * 0.5;
      canvas.height = video.videoHeight * 0.5;
  
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(
          (blob) => {
            const thumbnailUrl = blob ? URL.createObjectURL(blob) : 'path/to/placeholder-image.jpg';
            callback(thumbnailUrl);
            cleanup();
          },
          'image/jpeg',
          0.8
        );
      } else {
        callback('path/to/placeholder-image.jpg');
        cleanup();
      }
    });
  
    video.addEventListener('error', () => {
      callback('path/to/placeholder-image.jpg');
      cleanup();
    });
  
    video.load();
  };
  
  

  const checkVideoDuration = (file: File) => {
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
  
    video.onloadedmetadata = () => {
      const isDurationValid = video.duration <= 30; // Maximum duration: 30 seconds
      const isResolutionValid =
        (video.videoWidth <= 1920 && video.videoHeight <= 1080) || // Standard 1080p landscape
        (video.videoWidth <= 1080 && video.videoHeight <= 1920);   // Mobile 1080p portrait
  
      if (!isDurationValid) {
        messageApi.error('The video duration exceeds 30 seconds. Please upload a shorter video.');
      } else if (!isResolutionValid) {
        messageApi.error('The video resolution exceeds the allowed limit. Please upload a 1080p or lower-resolution video.');
      } else {
        uploadFile(file); // Proceed with the upload if both checks pass
      }
  
      URL.revokeObjectURL(video.src); // Clean up object URL
    };
  
    video.onerror = () => {
      messageApi.error('Could not validate the video. Please try another file.');
    };
  };
  
  

  const handleEditName = (assetId: string, currentName: string) => {
    setEditingAssetId(assetId);
    setNewAssetName(currentName);
  };

  const handleSaveName = async (assetId: string) => {
    if (
      !newAssetName.trim() ||
      newAssetName.trim() === allAssets.find((a) => a.id === assetId)?.name
    ) {
      setEditingAssetId(null);
      return;
    }

    setIsSaving(true);
    try {
      await axios.put('/streamer/asset', {
        asset_id: assetId,
        name: newAssetName,
      });
      messageApi.success('Asset name updated successfully!');
      fetchAllAssets();
    } catch (error) {
      messageApi.error('Failed to update asset name. Please try again.');
    } finally {
      setIsSaving(false);
      setEditingAssetId(null);
    }
  };

  // const filterOptions = ['All', 'Watermark', 'Image', 'GIF', 'Video'];
  const filterOptions = ['All', 'Watermark', 'Image','Video'];


  return (
    <MainLayout header={null} footer={<AppFooter shadow={false} />} marginTop="26px">
      {contextHolder}
      <div className="asset-management-page project__wrapper">
        <div className="project__hero">
          <div className="project__hero-description">
            <div className="project__hero-breadcrumb">
              <span onClick={() => navigate('/spikes/videos')}>Home</span>
              <span> / </span>
              <span>Asset Management</span>
            </div>
            <h2 className="project__hero-title">Asset Management</h2>
          </div>
        </div>

        <div className="input-container">
          <div
            className="file-upload-wrapper"
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleFileDrop}
          >
            <input
              type="file"
              accept={supportedFileTypes.join(',')}
              id="file-input"
              className="file-input"
              onChange={handleFileSelect}
              style={{ display: 'none' }}
            />
            <label htmlFor="file-input" className="file-input-label">
              {loadingFileUpload ? `Uploading (${progress}%)` : 'Choose a file or drag it here'}
              <IconUpload />
            </label>
          </div>
        </div>

        <div className="filter-bar">
          {filterOptions.map((filter) => (
            <button
              key={filter}
              className={`filter-button ${selectedFilters.includes(filter) ? 'active' : ''}`}
              onClick={() => toggleFilter(filter)}
            >
              {filter}
            </button>
          ))}
        </div>

        <div className="asset-grid-container">
          {filteredAssets.map((asset) => (
            <div key={asset.id} className="asset-card">
              <div
                className="delete-icon-container"
                onClick={() => openDeleteModal({ id: asset.id, name: asset.name })}
                title="Delete Asset"
              >
                X
              </div>
              <div className="asset-image-container">
                {asset.type === 'Video' && asset.thumbnail ? (
                  <img src={asset.thumbnail} alt={asset.name} className="asset-image" />
                ) : (
                  <img src={asset.url} alt={asset.name} className="asset-image" />
                )}
              </div>
              <div className="asset-info">
                <div className="asset-type">{asset.type}</div>
                {editingAssetId === asset.id ? (
                  <div className="asset-edit-container">
                    <Input
                      value={newAssetName}
                      onChange={(e) => setNewAssetName(e.target.value)}
                      onBlur={() => handleSaveName(asset.id)}
                      onPressEnter={() => handleSaveName(asset.id)}
                      autoFocus
                      className = 'edit-asset-name'
                    />
                    {isSaving && <Spin size="small" />}
                  </div>
                ) : (
                  <div className="asset-name-container">
                    <Tooltip className='asset-name' overlayClassName="tooltip" title={asset.name}> <span className="">{asset.name}</span>   </Tooltip>
                    <IconEdit
                      onClick={() => handleEditName(asset.id, asset.name)}
                      className="edit-icon"
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <DeleteAssetModal
        open={modalOpen}
        setOpen={setModalOpen}
        assetName={assetToDelete?.name || ''}
        onDeleteAsset={handleDeleteAsset}
      />
    </MainLayout>
  );
};

export default AssetManagementPage;
