import React, { useContext, useEffect, useState } from 'react';
import { SocialType } from './ShareModal';
import { IClip, IClipEdit, IVideoClip } from '../../data/intefaces/stream.interface';
import { useNavigate } from 'react-router';
import { UserContext } from '../../data/userContext';

import { ReactComponent as IconCollapse } from '../../assets/collapse-share.svg';
import { ReactComponent as IconDisconnect } from '../../assets/disconnect.svg';
import { ReactComponent as IconConnect } from '../../assets/connect.svg';
import { ReactComponent as IconSchedule } from '../../assets/schedule.svg';
import './ShareModal.less';
import { Collapse } from 'react-collapse';
import TextArea from 'antd/lib/input/TextArea';
import { DtPicker } from 'react-calendar-datetime-picker';
import 'react-calendar-datetime-picker/dist/style.css';
import { postAudit } from '../../services/audit.service';
import { Radio, message } from 'antd';
import { convertToISO, renderScheduleDate } from '../../utils';
import { PricingModal } from '../pricing-modal/PricingModal';

function convertDateStringToObject(dateString: string): {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
} {
  const date = new Date(dateString);

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1, // Months are zero-based
    day: date.getDate(),
    hour: date.getHours(), // Hour from the date string
    minute: date.getMinutes(), // Minute from the date string
  };
}



const timeSuggestions: Record<'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Sunday', string | string[] | null> = {
  Monday: '11:00',
  Tuesday: '10:00',
  Wednesday: '12:00',
  Thursday: '13:00',
  Friday: '10:30',
  Sunday: null,
};

const getSuggestedDates = (customDate: Date) => {
  const suggestions = [];
  const currentTimeInMinutes = customDate.getHours() * 60 + customDate.getMinutes(); // Current time in minutes

  for (let i = 0; suggestions.length < 3; i++) {
    const nextDate = new Date(customDate);
    nextDate.setDate(customDate.getDate() + i);

    const day = nextDate.toLocaleDateString('en-US', { weekday: 'long' }) as keyof typeof timeSuggestions;
    const time = timeSuggestions[day];

    if (time) {
      const [suggestedHours, suggestedMinutes] = Array.isArray(time)
        ? time[0].split(':').map(Number)
        : time.split(':').map(Number);

      const suggestedTimeInMinutes = suggestedHours * 60 + suggestedMinutes;

      if (i === 0 && (currentTimeInMinutes <= suggestedTimeInMinutes || customDate > new Date())) {
        suggestions.push({
          date: new Date(nextDate),
          time: Array.isArray(time) ? time[0] : time,
        });
      }
      else if (i > 0) {
        suggestions.push({
          date: new Date(nextDate),
          time: Array.isArray(time) ? time[0] : time,
        });
      }
    }
  }

  return suggestions.slice(0, 3); // Ensure only 3 suggestions are returned
};





interface IShareTabProps {
  type: SocialType;
  isOpened: boolean;
  onTabClick: (type: SocialType) => void;
  icon: any;
  name: string;
  videoUrl?: string | null | undefined;
  handlePostNow: (description: string, data: any, type: SocialType) => void;
  handleCloseModal: () => void;
  redirectToSocialExternalUrl: () => void;
  clip?: IClip & Partial<IClipEdit> & IVideoClip;
  disabled?: boolean;
  isConnected?: boolean;
  customScheduleDate?: string | null;
}

export const ShareTab = ({
  type,
  isOpened,
  onTabClick,
  icon,
  name,
  customScheduleDate,
  videoUrl,
  handlePostNow,
  handleCloseModal,
  clip,
  disabled,
  redirectToSocialExternalUrl,
  isConnected,
}: IShareTabProps) => {
  const [selectedYoutubePrivacy, setSelectedYoutubePrivacy] = useState('public');
  const [selectedInstagramPostType, setSelectedInstagramPostType] = useState('post');
  const [selectedFacebookPostType, setSelectedFacebookPostType] = useState('post');
  const [selectedYoutubePostType, setSelectedYoutubePostType] = useState('video');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');
  const [selectedSubreddit, setSelectedSubreddit] = useState('');
  const [scheduleDate, setScheduleDate] = useState<any>(null);
  const [suggestedTimes, setSuggestedTimes] = useState<any[]>([]);
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const allowDirectShare = userContext?.user?.permissions?.direct_share;

  const isDefaultClip = !clip ||
    clip?.headline === null ||
    clip?.headline?.includes('default_clip') ||
    clip?.name?.includes('default_clip');

  const showPasteButtons = !isDefaultClip;

  useEffect(() => {
    const getTodayDateString = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const hours = String(today.getHours()).padStart(2, '0');
      const minutes = String(today.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}:00`;
    };


    if (customScheduleDate) {
      const initialDate = new Date(customScheduleDate);
      initialDate.setHours(new Date().getHours(), new Date().getMinutes(), 0, 0); // Set current time
      const suggestions = getSuggestedDates(initialDate);
      setSuggestedTimes(suggestions);
      const convertedDate = convertDateStringToObject(initialDate.toISOString());
      setScheduleDate(convertedDate);
    } else {
      const todayDate = getTodayDateString();
      const initialDate = new Date(todayDate);
      initialDate.setHours(new Date().getHours(), new Date().getMinutes(), 0, 0); // Set current time
      const suggestions = getSuggestedDates(initialDate);
      setSuggestedTimes(suggestions);
      const convertedDate = convertDateStringToObject(initialDate.toISOString());
      setScheduleDate(convertedDate);
    }
  }, [customScheduleDate]);


  const isTitleFieldMandatory = Boolean(type === 'youtube' || type === 'pinterest' || type === 'reddit');

  const processScheduledDate = (scheduleDate: any): Date => {
    if (!scheduleDate) {
      return new Date();
    }
    const { year, month, day, hour = 0, minute = 0, second = 0 } = scheduleDate;
    return new Date(year, month - 1, day, hour, minute, second);
  };


  const getIsAlreadyShared = () => {
    let result = false;
    if (clip?.social_posts && clip?.social_posts?.length > 0) {
      clip?.social_posts.forEach((post) => {
        post.post.platforms.forEach((platform) => {
          if (platform.toLocaleLowerCase() === type.toLocaleLowerCase()) {
            result = true;
          }
        });
      });
    }

    return result;
  };

  const handleButtonClick = (date: Date, time: string) => {
    const [hours, minutes] = time.split(':').map(Number);

    date.setHours(hours, minutes, 0, 0);

    const convertedDate = convertDateStringToObject(date.toString());

    renderScheduleDate(convertedDate);
    setScheduleDate(convertedDate);
  };



  const handleDateChange = (newDate: any) => {
    setScheduleDate(newDate);
  };

  const handleHeaderLeftClick = () => {
    if (!allowDirectShare) {
      localStorage.setItem('cta_source', 'go-pro-social-services');
      handleOpenPricingModal();
      return;
    }
    if (isConnected) {
      onTabClick(type);
    } else {
      redirectToSocialExternalUrl();
    }
  };

  const handleClickConnect = async () => {
    if (allowDirectShare) {
      redirectToSocialExternalUrl();
    } else {
      localStorage.setItem('cta_source', 'go-pro-social-services');
      handleOpenPricingModal();
    }
  };

  const handleClickDisconnect = () => {
    redirectToSocialExternalUrl();
  };

  const clickPostNow = () => {
    if (allowDirectShare) {
      const options: any = getOptionsObject();

      if (scheduleDate) {
        options.publishAt = convertToISO(scheduleDate);
      }

      const isValid = validateFields();
      if (!isValid) return;

      handlePostNow(selectedDescription, options, type);
    } else {
      localStorage.setItem('cta_source', 'go-pro-social-services');
      handleOpenPricingModal();
    }
  };

  const clickSchedule = () => {
    if (allowDirectShare) {
      return;
    } else {
      localStorage.setItem('cta_source', 'go-pro-free-schedule');
      handleOpenPricingModal();
    }
  };

  const getOptionsObject = () => {
    if (type === 'youtube') {
      return { title: selectedTitle, visibility: selectedYoutubePrivacy, shorts: selectedYoutubePostType === 'shorts' };
    } else if (type === 'instagram') {
      return { reels: selectedInstagramPostType === 'reels', stories: selectedInstagramPostType === 'stories' };
    } else if (type === 'facebook') {
      return { reels: selectedFacebookPostType === 'reels' };
    } else if (type === 'pinterest') {
      return { title: selectedTitle, thumbNail: clip?.mobile_thumbnail_url || clip?.thumbnail_url };
    } else if (type === 'reddit') {
      return { title: selectedTitle, subreddit: selectedSubreddit };
    } else {
      return {};
    }
  };

  const validateFields = () => {
    if (!selectedDescription) {
      messageApi.error('Description is required.', 5);
      return false;
    }
    if (isTitleFieldMandatory) {
      if (!selectedTitle) {
        messageApi.error('Title is required.', 5);
        return false;
      } else {
        return true;
      }
    }
    if (type === 'reddit' && !selectedSubreddit) {
      messageApi.error('Subreddit is required.', 5);
      return false;
    }
    return true;
  };

  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  }

  const handleOpenPricingModal = () => {
    setIsPricingModalOpened(true);
  }

  return (
    <div className={`share-tab ${disabled && 'disabled'} ${isOpened && 'active'}`}>
      {contextHolder}
      <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />
      <div className="tab-header">
        <div onClick={handleHeaderLeftClick} className="header-left">
          <IconCollapse />
          {icon}
          <span>{name}</span>
          {getIsAlreadyShared() && <span className="share-tab__already-shared">(Already shared)</span>}
          {!allowDirectShare && <div className='pro-tag'>PRO</div>}
        </div>

        {isConnected ? (
          <div onClick={handleClickDisconnect} className="header-right">
            <IconDisconnect />
            <span>Disconnect</span>
          </div>
        ) : (
          <div onClick={handleClickConnect} className="header-right">
            <IconConnect />
            <span>Connect</span>
          </div>
        )}
      </div>
      <Collapse isOpened={isOpened}>
        <div className="tab-content">
          {isTitleFieldMandatory && (
            <div className="textarea-wrapper">
              {showPasteButtons && (
                <button
                  className="paste-button"
                  onClick={() => {
                    if (clip?.headline) {
                      setSelectedTitle(clip.headline);
                      messageApi.success('Title pasted successfully.', 3); // Success message
                    } else {
                      messageApi.error('No headline available to paste.', 5); // Error message
                    }
                  }}
                >
                  Paste Title
                </button>
              )}
              <TextArea
                className="share-textarea"
                value={selectedTitle}
                onChange={(e) => setSelectedTitle(e.target.value)}
                placeholder="Title (mandatory field)"
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </div>
          )}


          <div className="textarea-wrapper">
            {showPasteButtons &&
              (
                <div className='paste-buttons-container'>

                  <button
                    className="paste-button"
                    onClick={() => {
                      const description = clip?.description || '';
                      if (description) {
                        setSelectedDescription((prevDescription) => {
                          // Ensure the description is added only if it's not already included
                          return prevDescription.includes(description)
                            ? prevDescription
                            : `${prevDescription} ${description}`.trim();
                        });
                        messageApi.success('Description pasted successfully.', 3); // Success message
                      } else {
                        messageApi.error('No description available to paste.', 5); // Error message
                      }
                    }}
                  >
                    Paste Description
                  </button>
                  <button
                    className="paste-button"
                    onClick={() => {
                      let hashtags = '';
                      if (clip?.tags) {
                        try {
                          const parsedTags = JSON.parse(clip.tags);
                          if (Array.isArray(parsedTags)) {
                            hashtags = parsedTags
                              .map(tag => tag.startsWith('##') ? `#${tag.slice(2)}` : tag)
                              .map(tag => tag.startsWith('#') ? tag : `#${tag}`)
                              .join(' ');
                          }
                        } catch (e) {
                          hashtags = clip.tags
                            .replace(/[['\]]/g, '')
                            .split(',')
                            .map(tag => tag.trim())
                            .map(tag => tag.startsWith('##') ? `#${tag.slice(2)}` : tag)
                            .map(tag => tag.startsWith('#') ? tag : `#${tag}`)
                            .join(' ');
                        }
                      }


                      if (hashtags) {
                        setSelectedDescription((prevDescription) => {
                          // Ensure the hashtags are added only if they're not already included
                          return prevDescription.includes(hashtags)
                            ? prevDescription
                            : `${prevDescription} ${hashtags}`.trim();
                        });
                        messageApi.success('Hashtags pasted successfully.', 3);
                      } else {
                        messageApi.error('No hashtags available to paste.', 5);
                      }
                    }}
                  >
                    Paste Hashtags
                  </button>
                </div>
              )}




            <TextArea
              className="share-textarea"
              value={selectedDescription}
              onChange={(e) => setSelectedDescription(e.target.value)}
              placeholder="Description (mandatory field)"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />

          </div>


          {type === 'reddit' && (

            <TextArea
              className="share-textarea"
              value={selectedSubreddit}
              onChange={(e) => setSelectedSubreddit(e.target.value)}
              placeholder="Subreddit (mandatory field)"
              autoSize={{ minRows: 2, maxRows: 3 }}
            />
          )}
          {type === 'youtube' &&
            <Radio.Group
              className='share-radio-group'
              buttonStyle='outline'
              value={selectedYoutubePrivacy}
              onChange={(e) => setSelectedYoutubePrivacy(e.target.value)}
            >
              <Radio value='public'>Public</Radio>
              <Radio value='private'>Private</Radio>
              <Radio value='unlisted'>Unlisted</Radio>
            </Radio.Group>
          }
          {type === 'youtube' &&
            <Radio.Group
              className='share-radio-group'
              buttonStyle='outline'
              value={selectedYoutubePostType}
              onChange={(e) => setSelectedYoutubePostType(e.target.value)}
            >
              <Radio value='video'>Video</Radio>
              <Radio value='shorts'>Shorts</Radio>
            </Radio.Group>
          }
          {type === 'instagram' &&
            <Radio.Group
              className='share-radio-group'
              buttonStyle='outline'
              value={selectedInstagramPostType}
              onChange={(e) => setSelectedInstagramPostType(e.target.value)}
            >
              <Radio value='post'>Post</Radio>
              <Radio value='reels'>Reels</Radio>
              <Radio value='stories'>Stories</Radio>
            </Radio.Group>
          }
          {type === 'facebook' &&
            <Radio.Group
              className='share-radio-group'
              buttonStyle='outline'
              value={selectedFacebookPostType}
              onChange={(e) => setSelectedFacebookPostType(e.target.value)}
            >
              <Radio value='post'>Post</Radio>
              <Radio value='reels'>Reels</Radio>
            </Radio.Group>
          }
          <div className="time-recommendations-section">
            <h3 className="time-recommendations-title">Time Recommendations</h3>
            <div className="time-recommendations">
              {suggestedTimes.map(({ date, time }, index) => (
                <button
                  key={index}
                  onClick={() => handleButtonClick(date, time)}
                  className={`time-recommendation-button ${scheduleDate?.toString() === date.toString() ? 'selected' : ''
                    }`}
                >
                  <span className="date">{date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}</span>
                  <span className="day">({date.toLocaleDateString('en-US', { weekday: 'short' })})</span>
                  <span className="time">{time}</span>
                </button>
              ))}
            </div>
          </div>

          <div className="action-buttons">
            <div onClick={clickSchedule} className="schedule-button">
              <IconSchedule />
              <DtPicker
                initValue={scheduleDate} 
                autoClose={false}
                inputClass="tiktok-date__input"
                onChange={(newDate: any) => handleDateChange(newDate)}
                withTime
              />


              <span>{scheduleDate ? renderScheduleDate(scheduleDate) : 'Schedule post'}</span>
            </div>
            <div onClick={clickPostNow} className={`post-button ${!allowDirectShare && 'disabled'}`}>
              {processScheduledDate(scheduleDate) > new Date() ? 'Schedule Post' : 'Post now'}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
