import React, { useState, useEffect, useContext } from 'react';
import './ThemeToggle.less';
import '@theme-toggles/react/css/Expand.css';
import { Expand } from '@theme-toggles/react';
import { showAlternativeUiByUser } from '../../utils';
import { UserContext } from '../../data/userContext';

const ThemeToggle: React.FC = () => {
  const userContext = useContext(UserContext);
  const [theme, setTheme] = useState<'light' | 'dark'>(
    () => (localStorage.getItem('theme') as 'light' | 'dark') || (showAlternativeUiByUser(userContext?.user) ? 'light' : 'light')
  );

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') as 'light' | 'dark';
    if (savedTheme) {
      document.documentElement.setAttribute('data-theme', savedTheme);
    } else {
      const defaultTheme = showAlternativeUiByUser(userContext?.user) ? 'light' : 'light';
      document.documentElement.setAttribute('data-theme', defaultTheme);
      localStorage.setItem('theme', defaultTheme);
    }
  }, [theme]);

  return (
    <Expand
      className="theme-toggle"
      style={{ color: 'var(--bg-brand-primary)' }}
      toggled={theme === 'dark'}
      toggle={toggleTheme}
      duration={400}
    />
  );
};

export default ThemeToggle;
