import { Col, Image, Row, Spin, Tooltip, Typography, message } from 'antd';
import useAxios from 'axios-hooks';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Header } from '../../components/header';
import { MainLayout } from '../../layouts';
import { Footer as AppFooter } from '../../components/footer';
import { useNavigate } from 'react-router';
import { UserContext } from '../../data/userContext';
import './ProjectPage.less';
import { IStream, IVideo } from '../../data/intefaces/stream.interface';
import { EStreamStatus } from '../../data/enums/stream-status.enum';
import { LoaderFacts } from '../../components/loader-facts';
import { ClipCard } from '../../components/upload-card/ClipCard';
import { ReactComponent as IconArrow } from '../../assets/breadcrumb_arrow.svg';
import { ReactComponent as IconVideoSource } from '../../assets/video_source.svg';
import { ReactComponent as IconWarning } from '../../assets/warning_yellow.svg';
import { ReactComponent as IconInfoCircle } from '../../assets/info-circle.svg';
import { ReactComponent as IconLightning } from '../../assets/lightning-icon.svg';
import { ReactComponent as IconLock } from '../../assets/lock_no_size.svg';
import { secondsToTime } from '../../utils/generic';
import { completeOnboardingStep, formatViews, getTimeUntilExpiration, showGoProButton } from '../../utils';
import { LoadingOutlined } from '@ant-design/icons';
import { makeStreamClips } from '../../services/streamer.service';
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";
import _ from 'lodash';
import { ShareModal } from '../../components/share-modal';
import Modal from 'react-modal';
import { OnboardingContext } from '../../data/onboardingContext';
import { PricingModal } from '../../components/pricing-modal/PricingModal';
import axios from 'axios';
import { IStreamer } from '../../data/intefaces/streamer.interface';

const THUMBNAIL_PLACEHOLDER = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACoCAMAAABt9SM9AAAAA1BMVEUAAACnej3aAAAAR0lEQVR4nO3BAQEAAACCIP+vbkhAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAO8GxYgAAb0jQ/cAAAAASUVORK5CYII=';

export interface IProjectPageProps {
  defaultTabIndex?: number
};

type ISource = 'twitch' | 'upload' | 'transcribe';

const POLLING_INTERVAL = 10000;

const ProjectPage = (props: IProjectPageProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const userContext = useContext(UserContext);
  const onboardingContext = useContext(OnboardingContext);

  const source: ISource = searchParams.get('source') as ISource;
  const projectId = searchParams.get('id');
  const publicToken = searchParams.get('token');
  const tiktokToken = localStorage.getItem('tiktokToken');
  const isTwitchClipsProject = source === 'twitch';
  const isUploadProject = source === 'upload';
  const isTranscribeProject = source === 'transcribe';

  // Determine the type based on source
  const projectType = isTwitchClipsProject ? 'stream' : 'upload';

  // Ref to store the interval ID for cleanup
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  // Single useAxios hook for the new endpoint
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [{ data: projectData, loading: internalLoading, error: projectError }, refetchProject] = useAxios(
    {
      url: '/streamer/project',
      method: 'GET',
      params: {
        type: projectType,
        id: projectId,
      },
    },
    { manual: true }
  );
  
  useEffect(() => {
    if (!internalLoading) {
      setIsInitialLoading(false);
    }
  }, [internalLoading]);
  
  const projectLoading = isInitialLoading || internalLoading;
  

  // Existing public project fetching logic
  const [publicStream, setPublicStream] = useState<null | (IVideo & IStream)>(null);
  const [isProjectBecamePrivate, setIsProjectBecamePrivate] = useState(false);

  // Combined current project
  const currentStream: IVideo & IStream = useDeepCompareMemo(() => {
    if (publicStream) return publicStream as IVideo & IStream;
    return projectData as IVideo & IStream;
  }, [publicStream, projectData]);

  const [loadingMakeStreams, setLoadingMakeStreams] = useState<boolean>(false); // Twitch related
  const [clipForDirectShare, setClipForDirectShare] = useState<any>(null);
  const [videoUrlForDirectShare, setVideoUrlForDirectShare] = useState<any>(null);
  const [showSocialAnalyticsModal, setShowSocialAnalyticsModal] = useState(false);
  const [isLastScheduledPost, setIsLastScheduledPost] = useState(false);
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);
  const [allowProFeaturesToFreeUser, setAllowProFeaturesToFreeUser] = useState(false);

  const [user, setUser] = useState<IStreamer | null | undefined>(userContext?.user);

  const usedStreams = userContext?.user?.used_streams;
  const totalStreams = userContext?.user?.total_streams;
  const clipIdFromRedirect = searchParams.get('clipId');
  const videoUrlFromRedirect = searchParams.get('videoUrl');
  const hasSubscription = !showGoProButton(user);
  const userHasShareKey = hasSubscription ? userContext?.user?.social_profile_key : tiktokToken;

  // Extract resolution
  const handleExtractResolutionWidth = (resolution: string | null) => {
    if (!resolution) return 0;

    const numbers = resolution.replace(/[()]/g, '').split(', ');
    const number = parseInt(numbers[0], 10);

    return number;
  };

  const handleExtractResolutionHeight = (resolution: string | null) => {
    if (!resolution) return 0;

    const numbers = resolution.replace(/[()]/g, '').split(', ');
    const number = parseInt(numbers[1], 10);

    return number;
  };

  const projectResolutionWidth = Number(handleExtractResolutionWidth(currentStream?.resolution));
  const projectResolutionHeight = Number(handleExtractResolutionHeight(currentStream?.resolution));
  const isLowResolutionProject = Boolean(projectResolutionWidth && projectResolutionWidth <= 640);
  const isUniqueResolutionProject = Boolean(projectResolutionWidth && projectResolutionHeight && (projectResolutionWidth / projectResolutionHeight === 2));
  const isProjectExpired = currentStream?.expiration_date && Date.now() >= currentStream?.expiration_date;

  const isApiCreatedStream = Boolean(currentStream?.is_spikes_api);

  useEffect(() => {
    (
      async () => {
        if (publicToken) {
          try {
            const response = await axios.get(`/external/public-project?token=${publicToken}`);

            if (response.data) {
              setPublicStream(response.data);
              setIsProjectBecamePrivate(false);
            }
          } catch (error) {
            setIsProjectBecamePrivate(true);
          }

        }
      }
    )();
  }, []);

  useEffect(() => {
    if (publicStream && publicStream.clips.length > 0 && userContext?.user && !allowProFeaturesToFreeUser) {
      const ownerUser: IStreamer = publicStream.streamer as IStreamer;

      if (ownerUser && ownerUser?.permissions && ownerUser.permissions.hd_enabled && !userContext.user.permissions?.hd_enabled) {
        const copyUser = _.cloneDeep(userContext.user);
        copyUser.permissions = { ...ownerUser.permissions };
        if (ownerUser.subscriptions && ownerUser.subscriptions.length > 0) {
          copyUser.subscriptions = [...ownerUser.subscriptions];
        }

        setUser(copyUser);
        setAllowProFeaturesToFreeUser(true);
      }
    }
  }, [publicStream]);

  // Initial fetch of the specific project
  useEffect(() => {
    if (projectId && projectType) {
      refetchProject();
    }
  }, [projectId, projectType, refetchProject]);

  // Polling every 10 seconds for the specific project
  useEffect(() => {
    if (projectId && projectType) {
      intervalRef.current = setInterval(() => {
        refetchProject();
      }, POLLING_INTERVAL);
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [projectId, projectType, refetchProject]);

  // Polling every 10 seconds for the public project if applicable
  useEffect(() => {
    if (publicToken) {
      const publicInterval = setInterval(() => {
        axios.get(`/external/public-project?token=${publicToken}`).then((response) => {
          if (response.data) {
            setPublicStream(response.data);
          }
        }).catch((error) => {
          setIsProjectBecamePrivate(true);
        });
      }, POLLING_INTERVAL);

      return () => clearInterval(publicInterval);
    }
  }, [publicToken]);

  // Handle stream creation loading state
  useEffect(() => {
    if (currentStream && currentStream.clips.length > 0 && loadingMakeStreams) {
      setLoadingMakeStreams(false);
    }
  }, [currentStream, loadingMakeStreams]);

  // Handle direct share from URL params
  useEffect(() => {
    if (currentStream && clipIdFromRedirect && videoUrlFromRedirect) {
      const foundClip = currentStream.clips.find(clip => clip.id === clipIdFromRedirect);
      if (foundClip) {
        setClipForDirectShare(foundClip);
        setVideoUrlForDirectShare(videoUrlFromRedirect);
        searchParams.delete('clipId');
        searchParams.delete('videoUrl');
        searchParams.delete('compressedClip');
        setSearchParams(searchParams);
      }
    }
  }, [clipIdFromRedirect, videoUrlFromRedirect, projectId, currentStream, searchParams, setSearchParams]);


  const renderThumbnail = () => {
    if (isTwitchClipsProject) {
      const width = '434';
      const height = '325';

      const formattedTwitchThumbnail = currentStream?.thumbnail_url.replace('%{width}', width).replace('%{height}', height);
      return formattedTwitchThumbnail;
    } else {
      return currentStream?.thumbnail_url || THUMBNAIL_PLACEHOLDER;
    }
  };


  const roundEstimatedDuration = (durationSeconds: number, perMinutes: number) => {
    const rounded = Math.ceil(durationSeconds / 60 / perMinutes);
    return rounded;
  };

  const getPerMinutes = (durationSeconds: number) => {
    if (durationSeconds <= 899) {
      return 3;
    } else if (durationSeconds <= 3599) {
      return 4;
    } if (durationSeconds <= 8999) {
      return 6;
    } if (durationSeconds <= 14399) {
      return 8;
    } if (durationSeconds <= 1000000) {
      return 10;
    } else {
      return 10;
    }
  };

  const roundedDuration = roundEstimatedDuration(currentStream?.duration, getPerMinutes(currentStream?.duration));
  const estimatedClipsRange = isTwitchClipsProject ? [3, 20] : [roundedDuration, roundedDuration * 2]; // 1-2 clips per 3/4/6/8/10 minutes

  const renderUploadTitle = () => {
    return currentStream?.title;
  };

  const renderChannel = () => {
    return currentStream?.channel_name;
  };

  const renderDuration = () => {
    return secondsToTime(currentStream?.duration);
  };

  const renderViews = () => {
    return currentStream?.total_views ? formatViews(currentStream?.total_views) : currentStream?.total_views;
  };

  const handleHomeRedirect = () => {
    if (isTwitchClipsProject) {
      navigate('/spikes/streams');
    } else if (isUploadProject) {
      navigate('/spikes/videos');
    } else {
      navigate('/spikes/videos');
    }
  }

  const makeClipsOnClick = async () => {
    completeOnboardingStep('upload_video', userContext?.user);
    onboardingContext?.setRerender(onboardingContext?.rerender + 1)
    if (usedStreams && totalStreams && (usedStreams >= totalStreams)) {
      navigate('/subscriptions', { state: { message: 'Uh-oh! You\'ve hit the maximum stream limit.' } });
    } else {
      const id = currentStream.id;
      setLoadingMakeStreams(true);
      const user = await makeStreamClips(id);

      if (!user) {
        setLoadingMakeStreams(false);
        messageApi.warning('Stream is not ready yet! Please try again in a couple of minutes', 5);
      }
      if (user != null) {
        userContext?.setUser(user);
      }
    }
  };

  const handleCloseDirectShareModal = () => {
    setClipForDirectShare(null);
    setVideoUrlForDirectShare(null);
    localStorage.removeItem('tiktok-share-video-url');
    localStorage.removeItem('direct-share-clip');
  };

  const handleDirectShareClick = (clip: any, videoUrl: any) => {
    setClipForDirectShare(clip);
    setVideoUrlForDirectShare(videoUrl);
  };

  const handleSuccessDirectShare = (isScheduled?: boolean) => {
    handleCloseDirectShareModal();
    setShowSocialAnalyticsModal(true);
    messageApi.success(isScheduled ? 'Your post has been successfully scheduled.' : 'Your post has been successfully uploaded.', 5);
    if (isScheduled) {
      setIsLastScheduledPost(true);
    } else {
      setIsLastScheduledPost(false);
    }
  };

  const handleCloseSocialAnalyticsModal = () => {
    setShowSocialAnalyticsModal(false);
  };

  const handleRedirectToSocialAnalyticsPage = () => {
    navigate('/spikes/social');
  };

  const handleOpenPricingModal = () => {
    if (!hasSubscription) {
      setIsPricingModalOpened(true);
    }
  };

  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  };
  const handleDeleteClip = async (clipId: string) => {
    const response = await axios.put('/streamer/update-favorite-clip', {
      clip: clipId,
      is_removed_from_favorite: true
    }).catch((error) => {
      if (error.response) {
        console.log(error.response);
      }
    });
  }


  const displayContentDependingOnStatus = (status: string) => {
    if (status === EStreamStatus.PLATFORM_UPLOAD_FAILED_UNAVAILABLE) {
      return (
        <div style={{ maxWidth: 400, margin: '30px auto' }}>
          Sorry, this video has an age restriction, so we can&apos;t process it at the moment. If you&apos;d still like to upload it to Spikes Studio, you can download the video and upload it from your computer.
        </div>
      );
    }
    else if (status === EStreamStatus.FAILED || status === EStreamStatus.LOCKED || status === EStreamStatus.EMPTY_CHAT) {
      return (
        <div style={{ maxWidth: 400, margin: '30px auto' }}>
          Error occured.
        </div>
      );
    } else {
      return (
        <>
          {
            currentStream.clips.length > 0 ?
              (
                <Row gutter={[12, 24]}>
                  {currentStream.clips
                    .sort((a, b) => b.clip_order - a.clip_order)
                    .map((clip, i) => (clip.is_manual_edit === false && clip.status === 'in progress') ? null : (
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      <Col md={24} lg={12} xxl={8} key={clip.id}> <ClipCard user={user} publicStream={publicStream} isProjectExpired={isProjectExpired} handleDirectShareClick={handleDirectShareClick} key={clip.id} clipNumber={i + 1} streamTitle={currentStream.title} defaultOrientation='mobile' clip={clip} platformStreamId={currentStream.platform_stream_id} handleDeleteClip={handleDeleteClip} isTranscribePage={isTranscribeProject} isTwitchPage={isTwitchClipsProject} isApiCreatedStream={isApiCreatedStream} /></Col>
                    ))
                  }
                </Row>
              )
              :
              <>
                {/* {isTwitchCard ?
                  <>
                    {loadingMakeStreams &&
                      <div style={{ maxWidth: 400, margin: '30px auto' }}>
                        <LoaderFacts uniqueId={currentStream.id} setRefundButton={setShowCheckStatusModal} />
                      </div>
                    }
                  </>
                  :
                  <div style={{ maxWidth: 400, margin: '30px auto' }}>
                    <LoaderFacts uniqueId={currentStream.id} setRefundButton={setShowCheckStatusModal} />
                  </div>
                } */}

              </>
          }
        </>
      );
    }
  };


  return (
    <>
      <MainLayout
        header={isBrowser ? <Header title={''} /> : null}
        footer={<AppFooter shadow={false} />}
        marginTop='26px'
        withoutSidebar={!userContext?.user}
        className='no-overflow-x-mobile'
      >
        {contextHolder}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ShareModal isOpen={Boolean(clipForDirectShare && (userHasShareKey || !hasSubscription))} handleCloseModal={handleCloseDirectShareModal} videoUrl={videoUrlForDirectShare} handleSuccess={handleSuccessDirectShare} clip={clipForDirectShare} tiktokToken={tiktokToken} />
        <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />

        <Modal
          isOpen={showSocialAnalyticsModal}
          className="Modal"
          overlayClassName="Overlay"
          shouldCloseOnOverlayClick={true}
          onRequestClose={handleCloseSocialAnalyticsModal}
        >
          <div style={{ width: 430 }} className="glass-modal">
            <span onClick={handleCloseSocialAnalyticsModal} className="icon-close">X</span>
            <h1>{isLastScheduledPost ? 'Your post has been successfully scheduled.' : 'Your post has been successfully uploaded.'}</h1>
            <p>Monitor the status of your shared videos in our &apos;Social Analytics&apos; page.</p>

            <div className="buttons">
              <div onClick={handleCloseSocialAnalyticsModal} style={{ minWidth: 120 }} className="cancel-btn">Stay here</div>
              <div onClick={handleRedirectToSocialAnalyticsPage} className="confirm-btn">
                <span>Go to &apos;Social Analytics&apos; page</span>
              </div>
            </div>

          </div>
        </Modal>

        {isProjectBecamePrivate || projectError?.response?.data === 'Project not found'?
          <div className='project__private'>
            <div className='project__private-container'>
              <IconLock />
              <h1>Project is private</h1>
              <p>This project is private and can only be accessed by the owner.</p>
            </div>
          </div>
          :
          currentStream ?
            <div className='project project__wrapper'>
              <div className='project__hero'>
                <div className="project__hero-description">
                  <div className="project__hero-breadcrumb">
                    <span onClick={handleHomeRedirect}>Home</span>
                    <IconArrow />
                    <span>Project</span>
                  </div>
                  <h2 className='project__hero-title'>{renderUploadTitle()}</h2>
                  {renderChannel() &&
                    <div className='project__hero-channel'>
                      <IconVideoSource />
                      <span>{renderChannel()}</span>
                    </div>
                  }
                  <div className="project__hero-metadata">
                    {renderViews() ?
                      <>
                        <div className="project__hero-metadata-item">
                          <div className="project__hero-metadata-title">{renderViews()}</div>
                          <div className="project__hero-metadata-subtitle">viewers</div>
                        </div>
                        <div className="project__hero-metadata-divider" />
                      </>
                      :
                      <></>
                    }

                    <div className="project__hero-metadata-item">
                      <div className="project__hero-metadata-title">{renderDuration()}</div>
                      <div className="project__hero-metadata-subtitle">duration</div>
                    </div>
                    <div className="project__hero-metadata-divider" />

                    <div className="project__hero-metadata-item">
                      <div className="project__hero-metadata-title">{estimatedClipsRange[0]}-{estimatedClipsRange[1]}</div>
                      <div className="project__hero-metadata-subtitle">clips</div>
                    </div>
                  </div>
                  {isLowResolutionProject &&
                    <div className='low-resolution-label'>
                      <IconWarning />
                      <span>Please be aware that the very low video quality may impact the outcome of the clips.</span>
                      <Tooltip overlayClassName='tooltip face-tracking__tooltip' title='If this is a YouTube video, try downloading it and uploading from your computer. This way, you ensure that we at Spikes receive the original quality.'>
                        <IconInfoCircle className='face-tracking__info' />
                      </Tooltip>
                    </div>
                  }
                  {isUniqueResolutionProject &&
                    <div className='low-resolution-label'>
                      <IconWarning />
                      <span>The aspect ratio of this video is unique</span>
                      <Tooltip overlayClassName='tooltip face-tracking__tooltip' title='The aspect ratio of this video is unique. Be aware that certain automatic edits may not align correctly. Remember, you have the option to make adjustments using the editor.'>
                        <IconInfoCircle className='face-tracking__info' />
                      </Tooltip>
                    </div>
                  }
                  <div style={{ display: 'flex', alignItems: 'center', gap: '12px', marginTop: 20 }}>
                    {isProjectExpired ?
                      <div style={{ width: 'fit-content', marginTop: 0 }} className='low-resolution-label'>
                        <IconWarning />
                        <span>Project is expired</span>
                      </div>
                      :
                      <>
                        {currentStream.expiration_date &&
                          <div style={{ width: 'fit-content', marginTop: 0 }} className='low-resolution-label'>
                            <IconInfoCircle />
                            <span>Expires in: {getTimeUntilExpiration(currentStream.expiration_date)?.value} </span>
                          </div>
                        }
                      </>
                    }
                    {!hasSubscription &&
                      <Tooltip overlayClassName='tooltip' title='Upgrading to Pro extends your storage to 90 days.'>
                        <div onClick={() => (localStorage.setItem('cta_source', 'go-pro-extends-storage'), handleOpenPricingModal())}
                          className='upgrade-text'>Upgrade</div>
                      </Tooltip>
                    }
                  </div>

                </div>
                <div className="project__hero-image">
                  <div className="project__image-overlay" />
                  <Image height='100%' width='100%' style={{ objectFit: 'contain' }} src={renderThumbnail()} preview={false} />
                </div>
              </div>
              {isTwitchClipsProject && currentStream.clips.length === 0 &&
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                  <div className={`project__make-clips ${loadingMakeStreams || currentStream.clips_created && 'loading'}`} onClick={() => !currentStream.clips_created && !loadingMakeStreams ? makeClipsOnClick() : null}>
                    {loadingMakeStreams ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#000' }} spin />} /> : <IconLightning />}
                    <span>{loadingMakeStreams ? 'Making Clips' : 'Make Clips'}</span>
                  </div>
                </div>
              }
              <div className='project__clips-container'>
                {currentStream && displayContentDependingOnStatus(currentStream.status)}
              </div>
            </div>
            :
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin indicator={<LoadingOutlined style={{ fontSize: 56, color: 'var(--text-base)' }} spin />} /> </div>
        }


      </MainLayout>
    </>
  );
};

export default ProjectPage;
