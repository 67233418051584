import { Button, Layout, message, Input, Form } from 'antd';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { HeaderResponsive } from '../../components/header/header-responsive';
import { useContext, useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../themes/colors';
import { UserContext } from '../../data/userContext';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { useGoogleLogin } from '@react-oauth/google';
import { ReactComponent as IconTwitch } from '../../assets/twitch.svg';
import { ReactComponent as IconFacebook } from '../../assets/facebook_login_icon.svg';
import { ReactComponent as IconGoogle } from '../../assets/google.svg';
import handleTwitchLogin from '../../utils/twitchAuth';
import handleFacebookAuth from '../../utils/facebookAuth';
import { GenericModal } from '../../components/generic-modal';
import { postAudit } from '../../services/audit.service';
import LoginImage from '../../assets/login-hero.png';
import CryptoJS from 'crypto-js';

import './LoginPage.less';
import { getDifferenceInHours } from '../../utils';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const LoginPage = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();
  const hideLoginModals = localStorage.getItem('hideLoginModals');
  const [searchParams, setSearchParams] = useSearchParams();
  const googleSearchParam = searchParams.get('google');
  const refCode = searchParams.get('ref');
  const fromSearchParam = searchParams.get('from');
  const campaignId = searchParams.get('utm_id');
  const utm_campaign = searchParams.get('utm_campaign');
  const utm_source = searchParams.get('utm_source');
  const campaignUrl = localStorage.getItem('campaign_url');
  const onbordingFinished = userContext?.user?.referrer_source || userContext?.user?.category;

  const supervisorToken = searchParams.get('supervisor_token');
  const supervisorModeEnabled = supervisorToken && supervisorToken === process.env.REACT_APP_SUPERVISOR_TOKEN;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [resetStatus, setResetStatus] = useState(''); // New state for reset message


  const forgotPassword = () => {
    setIsForgotPassword(true); // Switches to the "Forgot Password" view
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('utm_source')) {
      localStorage.setItem('utm_source', window.location.search.slice(1));
    } else {
      localStorage.setItem('utm_source', 'none');
    }

    if (refCode) {
      localStorage.setItem('ref', refCode);
      searchParams.delete('ref');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (userContext?.user) {
      navigate('/spikes/videos');
    }
  }, [userContext]);

  useEffect(() => {
    if (fromSearchParam && fromSearchParam === 'api-home') {
      localStorage.setItem('login-from', fromSearchParam);
      searchParams.delete('from');
      setSearchParams(searchParams);
      if (userContext?.user) {
        navigate('/spikes/videos');
      }
    }
  }, [fromSearchParam]);

  useEffect(() => {
    if (googleSearchParam === 'true') {
      searchParams.delete('google');
      setSearchParams(searchParams);
      setTimeout(() => {
        handleGoogleLogin && handleGoogleLogin();
      }, 100);
    }
  }, []);

  const handleGoogleError = (error: any) => {
    messageApi.error('Google login error', 3);
    console.error('Google login error:', error);
    postAudit({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      user_action: `login error - google - ${JSON.stringify(error)}`,
      user_id: userContext?.user?.id
    });
  };

  const handleSuccessGoogleLogin = async (tokenResponse: any) => {
    if (tokenResponse) {
      if (tokenResponse.code) {
        const payload: any = {
          code: tokenResponse.code,
          referral_key: localStorage.getItem('ref') || '',
          is_mobile_login: isMobile,
          type: 'google',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        if (campaignUrl) payload.register_referral = campaignUrl;

        const response = await axios
          .post('/auth/social/twitch/login-or-register', payload)
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
            }
          });

        if (response?.status === 200 && response?.data?.user) {
          localStorage.removeItem('ref');
          localStorage.removeItem('campaign_url');
          localStorage.removeItem('login_method');
          userContext?.setUser(response.data.user);
          const user = response.data.user;

          if (!hideLoginModals) navigate('/spikes/welcome');
          else navigate('/spikes/videos');

          // Data Layer tracking when Google login is successful
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'sign_up',
            method: 'Google',
            event_name: 'fireEvent',
          });
        } else {
          messageApi.error('Failed to login. Please try again.', 5);
          console.error('Failed to login', tokenResponse);
          navigate('/');
        }
      }
    }
  };

  const handleGoogleLoginButton = () => {
    localStorage.setItem('login_method', 'google');
    handleLoginWithoutTokenRequest();
  };

  const handleGoogleLogin = userContext?.user ? null : useGoogleLogin({
    onSuccess: handleSuccessGoogleLogin,
    flow: 'auth-code',
    scope: 'openid email profile',
    redirect_uri: process.env.REACT_APP_GOOGLE_RETURN_URL,
    ux_mode: 'redirect',
    onError: handleGoogleError,
    onNonOAuthError: handleGoogleError,
  });

  const handleTwitchButton = async () => {
    // Push the dataLayer event first
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'sign_up',
      method: 'Twitch',
      event_name: 'fireEvent',
    });
    localStorage.setItem('login_method', 'twitch');
    handleLoginWithoutTokenRequest(true);
  };

  /* This new function caused issues with Twitch login */
  // const handleTwitchButton = async () => {
  //   try {
  //     await handleTwitchLogin();

  //     const response = await axios.get('/auth/user');
  //     if (response?.status === 200) {
  //       const user = response.data;
  //       userContext?.setUser(user);

  //       navigate('/spikes/videos');
  //     }
  //   } catch (error) {
  //     console.error('Twitch login error:', error);
  //   }
  // };


  const handleFacebookButton = async () => {
    try {
      localStorage.setItem('login_method', 'facebook');
      await handleFacebookAuth();

      const response = await axios.get('/auth/user');
      if (response?.status === 200) {
        const user = response.data;
        userContext?.setUser(user);

        navigate('/spikes/videos');
      }
    } catch (error) {
      console.error('Facebook login error:', error);
    }
  };

  const handleFacebookLogin = async (isFacebook?: boolean) => {
    try {
      const response = await axios.get('streamer/profile');
      if (response?.status === 200) {
        try {
          if (response.data && response.data.username) {
            userContext?.setUser(response.data);
          } else {
            handleTokeFacebookLogin(isFacebook);
          }
        } catch (error) {
          window.location.reload();
        }
      } else {
        handleTokeFacebookLogin(isFacebook);
      }
    } catch (error: any) {
      handleTokeFacebookLogin(isFacebook);

      if (error.response) {
        console.log('streamer/profile error:', error.response);
      }
    }
  };
  const handleLoginWithoutTokenRequest = async (isTwitch?: boolean) => {
    try {
      const response = await axios.get('streamer/profile');
      if (response?.status === 200) {
        try {
          if (response.data && response.data.username) {
            userContext?.setUser(response.data);
          } else {
            handleTokenLogin(isTwitch);
          }
        } catch (error) {
          window.location.reload();
        }
      } else {
        handleTokenLogin(isTwitch);
      }
    } catch (error: any) {
      handleTokenLogin(isTwitch);

      if (error.response) {
        console.log('streamer/profile error:', error.response);
      }
    }
  };
  /* 
    This is being triggered only when the Token is expired meaning
    that they won't see the google/twitch login pages if token
    is still valid (e.g. if 'streamer/profile' doesn't throw an error)
  */

  const handleTokeFacebookLogin = async (isFacebook?: boolean) => {
    if (campaignId || utm_campaign || utm_source) localStorage.setItem('campaign_url', window.location.href);

    await handleFacebookAuth();

  };
  const handleTokenLogin = async (isTwitch?: boolean) => {
    if (campaignId || utm_campaign || utm_source) localStorage.setItem('campaign_url', window.location.href);
    if (isTwitch) {
      await handleTwitchLogin();
    } else {
      handleGoogleLogin && handleGoogleLogin();
    }
  };

  const handleEmailLogin = async () => {
    try {
      const response = await axios.put('/auth/login', {
        email,
        password,
        ...(window.innerWidth > 1024 && { first_desktop_visit: true }),
      });

      if (response?.status === 200) {
        const user = response.data;
        userContext?.setUser(user);

        navigate('/spikes/videos');
      }
    } catch (error) {
      console.error('Login error:', error);
      messageApi.error('Login failed, please check your credentials and try again.', 3);
    }
  };


  const handlePasswordReset = async () => {
    try {
      const response = await axios.post('/auth/request-password', { email }).catch((error) => {
        if (error.response) {
          console.error(error?.response?.data);
        }
      });
      if (response?.status === 200) {
        setResetStatus('Password reset email sent. It can take up to 2 minutes.');
        messageApi.success('Password reset email sent.', 3);
      } else {
        setResetStatus('Failed to send password reset email. Please try again later.');
      }
    } catch (error) {
      console.log('error')
      console.error('Password reset error:', error);
    }
  };

  const triggerSignUpAnalytics = (user: any) => {
    const currentTimestamp = Date.now();
    const userCreatedAt = user?.createdAt;
    const userPlatform = user?.streaming_platform;
    const email = user?.email;
    const firstName = user?.firstname;
    const lastName = user?.lastname;
    const country = user?.country;
    const first_desktop_visit = user?.first_desktop_visit;
    const city = user?.city;
    if (userCreatedAt) {
      const providedDate = new Date(userCreatedAt);
      const timeDifference = currentTimestamp - providedDate.getTime();
      const secondsDifference = timeDifference / 1000;
      if (secondsDifference <= 20) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event_name: 'sign_up',
          method: userPlatform,
          event: 'fireEvent',
          country: country,
          city: city,
          first_name: CryptoJS.SHA256(firstName).toString(CryptoJS.enc.Hex),
          last_name: CryptoJS.SHA256(lastName).toString(CryptoJS.enc.Hex),
          email: CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex),
        });
      }
      if (window.innerWidth >= 1024 && first_desktop_visit) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event_name: 'deskptop_visit',
          event: 'fireEvent',
          first_name: CryptoJS.SHA256(firstName).toString(CryptoJS.enc.Hex),
          last_name: CryptoJS.SHA256(lastName).toString(CryptoJS.enc.Hex),
          email: CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex),
          country: country,
          city: city
        });
      }
    }

  };
  // const handleFacebookLogin = async (response: any) => {
  //   console.log('Facebook login response:', response);

  //   // Check if login failed
  //   if (response?.status === "unknown") {
  //     messageApi.error('Facebook login failed', 3);
  //     return;
  //   }

  //   // If login was successful, response contains accessToken and user info
  //   if (response.accessToken) {
  //     const { accessToken, userID, name, email } = response;

  //       if (accessToken) {
  //         if (accessToken) {
  //           const payload: any = {
  //             code: accessToken,
  //             referral_key: localStorage.getItem('ref') || '',
  //             is_mobile_login: isMobile,
  //             type: 'facebook',
  //             timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //           }
  //           if (campaignUrl) payload.register_referral = campaignUrl;

  //           const response = await axios
  //             .post('/auth/social/twitch/login-or-register', payload)
  //             .catch((error) => {
  //               if (error.response) {
  //                 console.log(error.response);
  //               }
  //             });

  //           if (response?.status === 200) {
  //             localStorage.removeItem('ref');
  //             localStorage.removeItem('campaign_url');
  //             userContext?.setUser(response.data.user);
  //             const user = response.data.user;

  //             if (!hideLoginModals) navigate('/spikes/welcome');
  //             else navigate('/spikes/videos');

  //             // Data Layer tracking when Google login is successful
  //             window.dataLayer = window.dataLayer || [];
  //             window.dataLayer.push({
  //               event: 'sign_up',
  //               method: 'Gacebook',
  //               event_name: 'fireEvent',
  //             });
  //           } else {
  //             console.error('No code provided by facebook login', accessToken);
  //           }
  //         }
  //       }
  //   }
  // };

  // // Facebook button click handler (triggering analytics or dataLayer event)
  // const handleFacebookClick = () => {
  //   // Push data to Google Tag Manager or any other analytics tool
  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //     event: 'sign_up',
  //     method: 'Facebook',
  //     event_name: 'fireEvent',
  //   });

  //   console.log('Facebook button clicked');
  // };
  return (
    <div className='login-page'>
      {contextHolder}
      <div className="login-page___image">
        <img src={LoginImage} alt='Login Image' />
      </div>
      <div className="login-page___content">
        <p className="login-page___subtitle">#AI Video Repurpose Tool</p>
        <p className="login-page___title">Get Your Clips Now</p>
        <p className="login-page___description">Try it for FREE. No credit card required</p>

        {!isForgotPassword ? (
          <div className="login-page___buttons">
            <Button
              id='login-with-google-button'
              onClick={handleGoogleLoginButton}
              style={{ background: '#fff', color: '#000' }}
              className="button"
            >
              <IconGoogle width={28} height={28} style={{ marginRight: 12, display: 'flex' }} />
              Sign in with Google
            </Button>
            <Button
              id='login-with-twitch-button'
              onClick={handleTwitchButton}
              style={{ background: '#fff', color: '#000' }}
              className="button"
            >
              <IconTwitch width={28} height={28} style={{ color: 'black', marginRight: 12 }} />
              Sign in with Twitch
            </Button>

            <Button
              id='login-with-facebook-button'
              onClick={handleFacebookButton}
              style={{ background: '#fff', color: '#000' }}
              className="button"
            >
              <IconFacebook width={32} height={32} style={{ color: 'black', marginRight: 8 }} />
              Sign in with Facebook
            </Button>

            <p className="login-page___separator">or continue with email</p>

            <Form
              name="login"
              onFinish={handleEmailLogin}
              autoComplete="off"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email address!'
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email address!'
                  }
                ]}
              >
                <Input
                  className='login-page___input'
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                name="password"
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your password!'
                  },
                  {
                    min: 8,
                    message: 'Password must be at least 8 characters!'
                  }
                ]}
              >
                <Input.Password
                  className='login-page___input'
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
              <p
                className="login-page___forgot-password"
                onClick={forgotPassword}
              >
                Forgot my password
              </p>
              <Form.Item>
                <Button
                  id='login-with-email-button'
                  style={{ background: '#fff', color: '#000' }}
                  className="login-page___email-buttons"
                  type="primary"
                  htmlType="submit"
                >
                  Sign in with Email
                </Button>
                <p
                  className="login-page___forgot-password"
                  onClick={() => navigate('/signup')}
                >
                  Not a member? Sign up!
                </p>
              </Form.Item>

            </Form>

          </div>
        ) : (
          <div className="forgot-password___view">
            <p className="login-page___reset-passowrd">Reset Your Password</p>
            <Form
              name="forgot-password"
              onFinish={handlePasswordReset}
              autoComplete="off"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email address!'
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email address!'
                  }
                ]}
              >
                <Input
                  className='login-page___input'
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <p className="reset-status-message">{resetStatus}</p>
              <Form.Item>
                <Button
                  id='reset-password-button'
                  style={{ background: '#fff', color: '#000' }}
                  className="login-page___reset-password-button"
                  type="primary"
                  htmlType="submit"
                >
                  Reset via Email
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );


};

export default LoginPage;
