import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const TiktokAuthorizationPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tiktokToken = searchParams.get('code');

  useEffect(() => {
    const savedUrl = localStorage.getItem('url-after-tiktok-auth');

    if (savedUrl && tiktokToken) {
      localStorage.setItem('tiktokToken', tiktokToken);
      navigate(`${savedUrl}&tiktokToken=${tiktokToken}`);
      localStorage.removeItem('url-after-tiktok-auth');
    } else {
      navigate('/');
    }
  }, [tiktokToken]);

  return null;
};

export default TiktokAuthorizationPage;
