import { Button, Layout, message, Input, Form } from 'antd';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { HeaderResponsive } from '../../components/header/header-responsive';
import { useContext, useEffect, useState } from 'react';
import { COLORS } from '../../themes/colors';
import { UserContext } from '../../data/userContext';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { useGoogleLogin } from '@react-oauth/google';
import { ReactComponent as IconTwitch } from '../../assets/twitch.svg';
import { ReactComponent as IconGoogle } from '../../assets/google.svg';
import handleTwitchLogin from '../../utils/twitchAuth';
import { GenericModal } from '../../components/generic-modal';
import { postAudit } from '../../services/audit.service';
import LoginImage from '../../assets/login-hero.png';
import CryptoJS from 'crypto-js';

import './VerifyEmailPage.less';
import { getDifferenceInHours } from '../../utils';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const VerifyEmail = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();
  const hideLoginModals = localStorage.getItem('hideLoginModals');
  const [searchParams, setSearchParams] = useSearchParams();
  const googleSearchParam = searchParams.get('google');
  const refCode = searchParams.get('ref');
  const fromSearchParam = searchParams.get('from');
  const campaignId = searchParams.get('utm_id');
  const utm_campaign = searchParams.get('utm_campaign');
  const utm_source = searchParams.get('utm_source');
  const campaignUrl = localStorage.getItem('campaign_url');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isVerified, setIsVerified] = useState(false);

  const [form] = Form.useForm();
  const [email, setEmail] = useState('');
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    if (refCode) {
      localStorage.setItem('ref', refCode);
      searchParams.delete('ref');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (userContext?.user) {
      navigate('/login?isEmail=true&loginOrRegosterSuccess=true');
    }
  }, [userContext]);

  useEffect(() => {
    if (fromSearchParam && fromSearchParam === 'api-home') {
      localStorage.setItem('login-from', fromSearchParam);
      searchParams.delete('from');
      setSearchParams(searchParams);
      if (userContext?.user) {
        navigate('/spikes/videos');
      }
    }
  }, [fromSearchParam]);

  useEffect(() => {
    // Extract the token from the URL
    const match = window.location.pathname.match(/\/verify-email\/([^&]+)/);
    const token = match ? match[1] : null; 

    const verifyEmailToken = async () => {
      try {
        const response = await axios.put('/auth/verify-email-token', { token });
        if (response) {
          setEmail(response.data.user.email)
          form.setFieldsValue({ email: response.data.user.email });
          setIsVerified(true);
        }
      } catch (error: any) { // Use 'any' type for general error handling
        if (error.response) {
          console.error(error.response.data);
        } else {
          console.error('An unexpected error occurred:', error);
        }
      }
    };

    if (token) {
      verifyEmailToken();
    }
  }, []);

  const handleSignUp = async () => {

    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false); // Reset to false after submission
    }, 3000);

    try {
      
      const match = window.location.pathname.match(/\/verify-email\/([^&]+)/);
      const token = match ? match[1] : null; 

      // Gather form data
      const formData = {
        email,
        firstname,
        lastname,
        username: `${firstname} ${lastname}`,
        password,
        register_referral: window.location.href,
        token,
        ...(window.innerWidth > 1024 && { first_desktop_visit: true })
      };



      // Make the API request
      const response = await axios.put('/auth/register', formData);

      // Handle the response
      if (response.status === 200 || response.status === 201) {
        messageApi.success('Sign-up successful!');
        userContext?.setUser(response.data);
        triggerSignUpAnalytics(response.data);
      } else {
        messageApi.error('Sign-up failed. Please try again.');
      }
    } catch (error: any) {
      if (error.response) {
        messageApi.error(`Sign-up error: ${error.response.data.message}`);
      } else {
        messageApi.error('An unexpected error occurred during sign-up.');
      }
      console.error('Sign-up error:', error);
    }
  };

  const handlePasswordBlur = () => {
    form.validateFields(['confirmPassword']);
  };

  const handleConfirmPasswordBlur = () => {
    form.validateFields(['confirmPassword']);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    form.setFieldsValue({ password: e.target.value });
    // Removed form.validateFields from here
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    form.setFieldsValue({ confirmPassword: e.target.value });
    // Removed form.validateFields from here
  };

  const triggerSignUpAnalytics = (user: any) => {
    console.log('triggerSignUpAnalytics start');
    const currentTimestamp = Date.now();
    const userCreatedAt = user?.createdAt;
    const userPlatform = user?.streaming_platform;
    const email = user?.email;
    const firstName = user?.firstname;
    const lastName = user?.lastname;
    const country = user?.country;
    const region = user?.region;
    const first_desktop_visit = user?.first_desktop_visit;
    const city = user?.city;
    if (userCreatedAt) {
      const providedDate = new Date(userCreatedAt);
      const timeDifference = currentTimestamp - providedDate.getTime();
      const secondsDifference = timeDifference / 1000;
      if (secondsDifference <= 20) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event_name: 'sign_up',
          method: userPlatform,
          event: 'fireEvent',
          country:country,
          city:city,
          region: region,
          first_name: CryptoJS.SHA256(firstName).toString(CryptoJS.enc.Hex),
          last_name: CryptoJS.SHA256(lastName).toString(CryptoJS.enc.Hex),
          email: CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex),
        });
      }
      if (window.innerWidth >= 1024 && first_desktop_visit  ) {
        window.dataLayer = window.dataLayer || [];
        console.log('triggerSignUpAnalytics desktop visit');
        window.dataLayer.push({
          event_name: 'deskptop_visit',
          event: 'fireEvent',
          first_name: CryptoJS.SHA256(firstName).toString(CryptoJS.enc.Hex),
          last_name: CryptoJS.SHA256(lastName).toString(CryptoJS.enc.Hex),
          email: CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex),
          country:country,
          city: city,
          region: region
        });
      }
    }

    console.log('triggerSignUpAnalytics end');
  };

  return (
    <div className='verify-email-page'>
      {contextHolder}
      <div className="verify-email-page___image">
        <img src={LoginImage} alt='Login Image' />
      </div>
      <div className="verify-email-page___content">
        <p className="verify-email-page___subtitle">#AI Video Repurpose Tool</p>
        <p className="verify-email-page___title">Get Your Clips Now</p>
        <p className="verify-email-page___description">Try it for FREE. No credit card required </p>

        <div className="sign-up-form">
          <Form form={form} layout="vertical" onFinish={handleSignUp}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please enter your email!' }]}
            >
              <Input
                className="sign-up-form___input"
                placeholder="Email"
                value={email}
                disabled
              />
            </Form.Item>
            <Form.Item
              name="firstname"
              rules={[
                { required: true, message: 'Please enter your first name!' },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: 'No numbers or special characters'
                }
              ]}
            >
              <Input
                className="sign-up-form___input"
                placeholder="First Name"
                value={firstname}
                onChange={(e) => setfirstname(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="lastname"
              rules={[
                { required: true, message: 'Please enter your last name!' },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: 'No numbers or special characters'
                }
              ]}
            >
              <Input
                className="sign-up-form___input"
                placeholder="Last Name"
                value={lastname}
                onChange={(e) => setlastname(e.target.value)}
              />
            </Form.Item>
            {/* <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please enter your username!' }]}
            >
              <Input
                className="sign-up-form___input"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Item> */}
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please enter your password!' },
                { min: 8, message: 'Password must be at least 8 characters!' },
              ]}
            >
              <Input.Password
                className="sign-up-form___input"
                placeholder="Use at least 8 characters"
                value={password}
                onChange={handlePasswordChange}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password
                className="sign-up-form___input"
                placeholder="Repeat your password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                onBlur={handleConfirmPasswordBlur}  // Add validation on blur
              />
            </Form.Item>

            <Form.Item>
              <Button
                id="sign-up-button"
                type="primary"
                htmlType="submit"
                className="verify-email-page___signup_button"
                disabled={isSubmitting} // Disable the button based on state
              >
               Create Account
              </Button>
            </Form.Item>
          </Form>
        </div>

      </div>
    </div>
  );
};

export default VerifyEmail;
